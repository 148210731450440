import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        certificate: state.certificate
    }
}

class EditCertificate extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)        
        if (this.props.match && typeof this.props.match.params.certificateid == 'undefined'){
            this.state = {     
                certificate: {
                    name:"", 
                    description:"",
                    expirymonths:0
                }
            }      
        }else{
            this.state = {     
                certificate: Object.assign({},this.props.certificate)
            } 
        }
    }

    componentDidMount() {  
        this._isMounted = true;         
        var that = this;  
        if (that.props.match && typeof that.props.match.params.certificateid !== 'undefined'){       
            NetworkService.getCertificate(that.props.match.params.certificateid, that.props.session, function (certificate) {             
                if (that._isMounted) {                                     
                    that.setState({certificate: Object.assign({}, certificate)})            
                }
            }, function (err) {
                console.log(err);
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _saveCertificate(e){       
        e.preventDefault();                       
        var that =this;
        let certificate = Object.assign({},this.state.certificate);         
       
        if(certificate.certificateid!=null || certificate.certificateid!==undefined){
            NetworkService.updateCertificate(certificate, that.props.session, function (certificate) {            
                that.props.push('/certificate');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createCertificate(certificate, that.props.session, function (certificate) {                     
                that.props.push('/certificate');                       
            }, function (err) {
                console.log(err);
            });
        }        
    }

    _cancel(){
        if(this.state.certificate.certificateid){
            this.props.push('/certificate/detail/'+this.state.certificate.certificateid);
        } else {
            this.props.push('/certificate');
        }
    } 

    _handleChange(type, val){
        let certificate = Object.assign({},this.state.certificate);         
        certificate[type] = val;
        this.setState({certificate})        
    }

    render() {         
        const title = i18n.t("certificate_title_edit");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">         
                <form onSubmit={e => this._saveCertificate(e)}>                                   
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">                                
                            <div className="form-group">
                                <label>{i18n.t("certificate_common_name")}</label>
                                <input className="form-control" type="text" value={this.state.certificate.name || ""} onChange={e => this._handleChange("name", e.target.value)} required/>
                            </div>                           
                            <div className="form-group">
                                <label>{i18n.t("certificate_common_description")}</label>
                                <textarea className="form-control" type="text" value={this.state.certificate.description || ""} onChange={e => this._handleChange("description", e.target.value)} rows="4" required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("certificate_common_expirymonths")}</label>
                                <input className="form-control" type="text" value={this.state.certificate.expirymonths || 0} onChange={e => this._handleChange("expirymonths", e.target.value)} required/>
                            </div>
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>   
                </form>                            
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditCertificate);
