const deposit = (state = [], action) => {
    switch (action.type) {
        case 'SET_DEPOSIT':
            return ({             
                depositid: action.deposit.depositid,               
                amount: action.deposit.amount,
                mixpercent: action.deposit.mixpercent,                
                location: action.deposit.location,
                articleid: action.deposit.articleid,
                userid: action.deposit.userid,                
                registrationdate: action.deposit.registrationdate,
                expirydate: action.deposit.expirydate,
                cubicmeter: action.deposit.cubicmeter,
                numberofarticle: action.deposit.numberofarticle,
                claimid: action.deposit.claimid,
                deliveryid: action.deposit.deliveryid
            })
        default:
            return state
    }
}

export default deposit