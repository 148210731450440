import { saveAs } from "file-saver";
import $ from "jquery";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import EditDelivery from "../../components/delivery/EditDelivery";
import Modal from "../../components/Modal";
import ModalEdit from "../../components/ModalEdit";
import helper from "../../services/helper";
import i18n from "../../services/i18n";
import NetworkService from "../../services/NetworkService";
import AdvancedSearch from "../AdvancedSearch";
import Navbar from "../Navbar";

import SearchStock from "./SearchStock";

const mapStateToProps = function (state) {
  return {
    session: state.session,
    // location: state.router.location
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//       setSupplier: (supplier) => dispatch({
//           type: 'SET_STOCK',
//           stock: stock,
//       }),
//   };
// };

class Stock extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      certificates: [],
      stock: [],
      selectedTab: "",
      selectedAGTab: 0,
      selectedClaimTab: 0,
      search: {},
      deposit: {},
      withdrawal: {},
      textSearchSite: "",
      textSearchArticleNumber: "",
      sites: [],
      isLoading: false,
    };
    this._closeSearch = this._closeSearch.bind(this);
    this._search = this._search.bind(this);
    this._closeDeposit = this._closeDeposit.bind(this);
    this._closeWithdrawal = this._closeWithdrawal.bind(this);
    this._closeDelivery = this._closeDelivery.bind(this);
    this._saveDeposit = this._saveDeposit.bind(this);
    this._saveWithdrawal = this._saveWithdrawal.bind(this);
    this._saveDelivery = this._saveDelivery.bind(this);
    this._createAndDownloadPdf = this._createAndDownloadPdf.bind(this);
    this._handleTextSearchSite = this._handleSearchSite.bind(this);
    this._handleTextSearchArticleNumber =
      this._handleSearchArticleNumber.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let params = queryString.parse(this.props.location.search);
    this.setState({ search: params }, () => {this._getSites()});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    //Check if query parameters has changed if it has; search for stock
    if (this._isMounted) {
      let newparams = queryString.parse(this.props.location.search);
      let oldparams = queryString.parse(prevProps.location.search);
      if (JSON.stringify(newparams) !== JSON.stringify(oldparams)) {
        this.setState({ search: newparams, selectedTab: 0 }, () =>
          this._getStock()
        );
      }
    }
    //$('[data-toggle="popover"]').popover();
  }

  _getSites() {
    var that = this;
    NetworkService.getSites(that.props.session,(data) => {
      if (that._isMounted) {
        that.setState({ sites: data });
      }
    });
  }

  _getStock() {
    var that = this;
    this.setState({ isLoading: true });
    let fromdate = moment().subtract(3, "months").format("YYYY-MM-DD");
    let todate = moment().format("YYYY-MM-DD");
    let query = this.state.search;
    if (!query) {
      query = {};
      query.todate = todate;
      query.fromdate = fromdate;
    }

    NetworkService.getStock(that.props.session, query).then((data) => {
      //TODO: rewrite when rewriting stocks
      for (var i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].articlegroups.length; j++) {
          for (let k = 0; k < data[i].articlegroups[j].claims.length; k++) {
            data[i].articlegroups[j].claims[k].tabledata = [];
            for (
              let l = 0;
              l < data[i].articlegroups[j].claims[k].stock.deposits.length;
              l++
            ) {
              let tabledata = {};
              let ddata = data[i].articlegroups[j].claims[k].stock.deposits[l];
              tabledata.amount = ddata.amount;
              tabledata.date = moment(ddata.registrationdate).format(
                "YYYY-MM-DD"
              );
              tabledata.type = "deposit";
              tabledata.article = ddata.article;
              tabledata.supplier = ddata.supplier;
              tabledata.certificateid = ddata.certificateid;
              tabledata.reference = ddata.reference;
              tabledata.withdrawalid = null;
              data[i].articlegroups[j].claims[k].tabledata.push(tabledata);
            }

            for (
              let l = 0;
              l < data[i].articlegroups[j].claims[k].stock.withdrawals.length;
              l++
            ) {
              let tabledata = {};
              let wdata =
                data[i].articlegroups[j].claims[k].stock.withdrawals[l];
              tabledata.amount = wdata.amount;
              tabledata.date = moment(wdata.registrationdate).format(
                "YYYY-MM-DD"
              );
              tabledata.type = "withdrawal";
              tabledata.article = wdata.article;
              tabledata.supplier = wdata.supplier;
              tabledata.certificateid = wdata.certificateid;
              tabledata.reference = wdata.reference;
              tabledata.withdrawalid = wdata.withdrawalid;
              data[i].articlegroups[j].claims[k].tabledata.push(tabledata);
            }
            data[i].articlegroups[j].claims[k].tabledata.sort((a, b) =>
              a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
          }
        }
      }

      if (that._isMounted) {
        that.setState({ stock: data, isLoading: false });
      }
    });
  }

  _helperRenderTabsWithDeposits(claimTabs, AGtabs, siteTabs) {
    let result;
    //In the case of claimTabs
    if (claimTabs) {
      if (claimTabs.stock.deposits.length > 0 || claimTabs.stock.inbalance > 0) {
        return true;
      } else return false;
    }
    //In the case of AGtabs
    else if (AGtabs) {
      AGtabs.claims.every((item) => {
        if (item.stock.deposits.length > 0 || item.stock.inbalance > 0) {
          result = true;
          //break
          return false;
        } else return true;
      });
      return result;
    }
    //In the case of siteTabs
    else if (siteTabs) {
      siteTabs.articlegroups.forEach((item) => {
        item.claims.every((itemTwo) => {
          if (itemTwo.stock.deposits.length > 0 || itemTwo.stock.inbalance > 0) {
            result = true;
            //break
            return false;
          } else return true;
        });
      });
      return result;
    } else return false;
  }

  _hasSearch() {
    return (
      this.state.search &&
      (this.state.search.all ||
        this.state.search.fromdate ||
        this.state.search.todate)
    );
  }

  _filterObjectWithText(obj, searchSite) {
    const { textSearchSite, textSearchArticleNumber } = this.state;
    let lowercasedFilter;
    if (searchSite) {
      lowercasedFilter = textSearchSite.toLowerCase();
    } else {
      //WB1021-27
      lowercasedFilter = textSearchArticleNumber.toLowerCase();
    }
    if (obj) {
      var filteredData = obj.filter((item) => {
        let result = Object.keys(item).some((key) => {
          if (item[key]) {
            return item[key]
              .toString()
              .toLowerCase()
              .includes(lowercasedFilter);
          }
        });
        return result;
      });
    }

    return filteredData;
  }

  _renderSiteTabs() {
    //Render certificates as tabs
    /*let filteredData = this._filterObjectWithText(this.state.stock, true);

    let rows = [];
    if (filteredData && filteredData.length > 0) {
      rows = filteredData.map((item, i) => {
        let hasDeposits = this._helperRenderTabsWithDeposits(
          false,
          false,
          item
        );

        if (hasDeposits) {*/
        let rows = this.state.sites.map((item, i) => {
          return (
            <li key={i} className="nav-item">
              <span
                className={
                  "nav-link w-tabs" +
                  (this.state.selectedTab === i ? " active" : "")
                }
                onClick={(e) => {
                  let searchObj = JSON.parse(JSON.stringify(this.state.search));
                  searchObj.sites = item.siteid;
                  this.setState({ selectedTab: i, search: searchObj }, ()=>{ this._getStock() });
                }}
              >
                {item.name}
              </span>
            </li>
          );
        //}
      });
    //}
    return rows;
  }

  _renderSiteTabData() {
      if(this.state.stock.length > 0){
        let item = this.state.stock[0];
        if(!this.state.isLoading){
          return (
            <div key={"tab-data"}>
              <ul className="nav nav-pills nav-justified sub-tab-row articles-background">
                {this._renderArticleGroupTabs(item)}
                {/*this._renderStockTable(item)*/}
              </ul>
              {this._renderArticleGroupTabData(item)}
            </div>
          );
        } else {
          return <div key="site-tab-spinner" style={{marginLeft:'auto', marginRight:'auto', width:15, marginTop:15}}><i className="fa fa-spin fa-spinner"></i></div>;
        };
      }
  }

  _renderArticleGroupTabs(site) {
    //Render certificates as tabs

    /*    let filteredData = this._filterObjectWithText(site.articlegroups, false);

    let rows = [];
    if (filteredData && filteredData.length > 0) {
      rows = filteredData.map((item, i) => { */

    let rows = [];
    rows = site.articlegroups.map((item, i) => {
      let hasDeposits = this._helperRenderTabsWithDeposits(false, item, false);
      if (hasDeposits) {
        return (
          <li key={i} className="nav-item">
            <span
              className={
                "nav-link w-tabs articlegroup-tabs " +
                (this.state.selectedAGTab === i ? " active" : "")
              }
              onClick={(e) => {
                this.setState({ selectedAGTab: i });
              }}
            >
              {item.name}
            </span>
          </li>
        );
      }
    });

    return rows;
  }

  _renderArticleGroupTabData(site) {
    let data = [];
    data = site.articlegroups.map((item, i) => {
      if (this.state.selectedAGTab === i) {
        return (
          <div key={i}>
            <ul className="nav nav-pills nav-justified sub-tab-row claim-background">
              {this._renderClaimTabs(item)}
              {/*this._renderStockTable(item)*/}
            </ul>
            {this._renderClaimTabData(item)}
          </div>
        );
      } else {
        return null;
      }
    });
    return data;
  }

  _renderClaimTabs(articlegroup) {
    let rows = [];

    rows = articlegroup.claims.map((item, i) => {
      let hasDeposits = this._helperRenderTabsWithDeposits(item, false, false);
      if (hasDeposits) {
        return (
          <li key={i} className="nav-item">
            <span
              className={
                "nav-link w-tabs claim-tabs" +
                (this.state.selectedClaimTab === i ? " active" : "")
              }
              onClick={(e) => {
                this.setState({ selectedClaimTab: i });
              }}
            >
              {item.name}
            </span>
          </li>
        );
      }
    });

    return rows;
  }

  _renderClaimTabData(articlegroup) {
    let data = [];
    data = articlegroup.claims.map((item, i) => {
      if (this.state.selectedClaimTab === i) {
        return (
          <div className="fade-in" key={i}>
            {this._renderStockTable(item)}
          </div>
        );
      } else {
        return null;
      }
    });
    return data;
  }

  _renderStockTable(stock) {
    return (
      <table className="table table-striped mt-4 table-responsive-sm">
        <thead>
          <tr>
            <th>{i18n.t("stock_common_date")}</th>
            <th>{i18n.t("stock_common_reference")}</th>
            <th>{i18n.t("stock_common_deposit")}</th>
            <th>{i18n.t("stock_common_withdrawal")}</th>
            <th>{i18n.t("stock_common_total")}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{this._renderStockRows(stock)}</tbody>
      </table>
    );
  }

  _createAndDownloadPdf(withdrawalid) {
    var that = this;
    NetworkService.getPdf(withdrawalid, that.props.session).then(
      (blob) => {
        saveAs(blob, "withdrawal.pdf");
      },
      function (err) {
        console.log("Error in downloading pdf", err);
      }
    );
  }

  _renderStockRows(assignmentgroup) {
    let data = [];
    let total = assignmentgroup.stock.inbalance;
    data = assignmentgroup.tabledata.map((item, i) => {
      if (item.type === "deposit") {
        total = total + parseFloat(item.amount);
      } else {
        total = total - parseFloat(item.amount);
      }
      return (
        <tr key={i}>
          <td>{item.date}</td>
          <td>{item.reference}</td>
          <td>{item.type === "deposit" ? item.amount : ""}</td>
          <td>{item.type === "withdrawal" ? item.amount : ""}</td>
          <td>{total.toFixed(5)}</td>
          <td
            style={{ cursor: "pointer" }}
            onMouseEnter={(e) => {
              this._showPopover(
                "information" + item.certificateid + "_" + i,
                item
              );
            }}
            onMouseLeave={(e) => {
              this._hidePopover("information" + item.certificateid + "_" + i);
            }}
          >
            <i
              data-popover
              id={"information" + item.certificateid + "_" + i}
              className="fa fa-info-circle"
              data-trigger="manual"
            ></i>
          </td>
          <td>
            {item.type === "withdrawal" ? (
              <i
                className="fa fa-file-pdf-o"
                onClick={(e) => {
                  this._createAndDownloadPdf(item.withdrawalid);
                }}
              ></i>
            ) : null}
          </td>
        </tr>
      );
    });
    data.unshift(
      <tr key={"stock_table_0"}>
        <td>{i18n.t("stock_common_before")}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>
          <b>{assignmentgroup.stock.inbalance}</b>
        </td>
        <td></td>
        <td></td>
      </tr>
    );
    data.push(
      <tr key={"stock_table_-1"}>
        <td>{i18n.t("stock_common_after")}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>
          <b>{total.toFixed(5)}</b>
        </td>
        <td></td>
        <td></td>
      </tr>
    );
    return data;
  }

  _showPopover(id, item) {
    $("#" + id).popover({
      content: this._buildPopoverTemplate(item),
      html: true,
    });
    //$('[data-popover]').not('#' + id).popover('hide');
    $("#" + id).popover("show");
  }

  _hidePopover(id) {
    $("#" + id).popover("hide");
  }

  _buildPopoverTemplate(item) {
    var htmlstring = `
        <div class="form-group">
            <b>${i18n.t("stock_common_article")}</b>
            <p>${item.article ? item.article.articledescription : "-"}</p>
            <b>${i18n.t("article_common_number")}</b>
            <p>${item.article ? item.article.articlenumber : "-"}</p>
            <b>${i18n.t("stock_common_supplier")}</b>
            <p>${item.supplier ? item.supplier.name : "-"}</p>
        </div>`;
    return htmlstring;
  }

  _search() {
    //Set search object as query parameters
    this.setState({ searchIsOpen: false });
    let query = Object.assign({}, this.state.search);
    Object.keys(query).forEach(
      (key) => (query[key] === null || query[key] === "") && delete query[key]
    );

    let params = queryString.stringify(query);
    this.props.push("/stock?" + params);
  }

  _closeSearch() {
    this.setState({ searchIsOpen: false });
  }

  _closeDeposit() {
    this.setState({ depositIsOpen: false });
  }

  _closeWithdrawal() {
    this.setState({ withdrawalIsOpen: false });
  }
  _closeDelivery() {
    this.setState({ deliveryIsOpen: false });
  }

  _saveDeposit() {
    this.setState({ depositIsOpen: false });
    this.props.push(0);
  }

  _saveWithdrawal() {
    this.setState({ withdrawalIsOpen: false });
    this.props.push(0);
  }

  _saveDelivery() {
    this.setState({ deliveryIsOpen: false });
    this.props.push(0);
  }

  _refresh() {
    this.props.push(0);
  }

  _addOrder(item) {
    this.props.push("/order/add");
  }

  _getReport() {
    let query = Object.assign({}, this.state.search);
    Object.keys(query).forEach(
      (key) => (query[key] === null || query[key] === "") && delete query[key]
    );
    let that = this;
    NetworkService.getStockReport(that.props.session, query)
      .then((data) => {
        saveAs(
          data,
          "report-" +
            moment().format("YYYY-MM-DD") +
            "-" +
            moment().format("HH:mm:ss") +
            ".xlsx"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _handleSearchSite(event) {
    this.setState({ textSearchSite: event.target.value });
  }

  _handleSearchArticleNumber(event) {
    this.setState({ textSearchArticleNumber: event.target.value });
  }

  _renderSites() {
    return (
      <>
        <ul className="nav nav-pills nav-justified companies-background">
          {this._renderSiteTabs()}
        </ul>
        {this._renderSiteTabData()}
      </>
    );
  }

  render() {
    const title = i18n.t("common_stock");
    //var hasDepositAccess =helper.checkAccess(this.props.session.scope, "DEPOSIT", 1, 1)
    //var hasWithdrawalAccess =helper.checkAccess(this.props.session.scope, "WITHDRAWAL", 1, 1)
    var hasDeliveryAccess = helper.checkAccess(
      this.props.session.scope,
      "DELIVERY",
      1,
      1
    );
    var hasOrderAccess = helper.checkAccess(
      this.props.session.scope,
      "ORDER",
      1,
      1
    );
    return (
      <div id="page-content-wrapper">
        <Navbar title={title} />
        <div className="container mt-5">
          <div className="d-flex justify-content-center">     {/* [OV] Corrected 'class' to 'className' */}
            {" "}
            <AdvancedSearch
              _handleSearchOne={this._handleSearchSite.bind(this)}
             /*  _handleSearchArticleNumber={this._handleSearchArticleNumber.bind(
                this
              )} */
              label={i18n.t("common_supplier")}
            ></AdvancedSearch>
          </div>

          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-md-3 ">
              <button
                className="btn btn-outline-secondary toolbar-buttons col-md-12"
                onClick={(e) => {
                  this.setState({ searchIsOpen: true });
                }}
              >
                {" "}
                <i className="fa fa-search"></i> Filter
              </button>
            </div>

            <div className="col-md-3 ">
              <button
                className="btn btn-outline-secondary toolbar-buttons col-md-12"
                onClick={(e) => {
                  this._getReport();
                }}
              >
                {" "}
                <i className="fa fa-download"></i> {i18n.t("common_get_report")}
              </button>
            </div>
            {/* hasDepositAccess ?
                            <div className="col-md-4 ">
                                <button className="btn btn-outline-secondary col-md-12" onClick={e => { this.setState({ depositIsOpen: true }) }}> <i className="fa fa-plus"></i> {i18n.t("deposit_button_add")}</button>
                            </div> 
                            : null
                        */}
            {hasDeliveryAccess ? (
              <div className="col-md-3 ">
                <button
                  className="btn btn-outline-secondary toolbar-buttons col-md-12"
                  onClick={(e) => {
                    this.setState({ deliveryIsOpen: true });
                  }}
                >
                  {" "}
                  <i className="fa fa-plus"></i> {i18n.t("delivery_button_add")}
                </button>
              </div>
            ) : null}
            {/* hasWithdrawalAccess ?
                            <div className="col-md-4 ">
                                <button className="btn btn-outline-secondary col-md-12"  onClick={e => { this.setState({ withdrawalIsOpen: true }) }}> <i className="fa fa-plus"></i> {i18n.t("withdrawal_button_add")}</button>
                            </div> : null
                        */}
            {hasOrderAccess ? (
              <div className="col-md-3 ">
                <button
                  className="btn btn-outline-secondary toolbar-buttons col-md-12"
                  onClick={(e) => this._addOrder()}
                >
                  {" "}
                  <i className="fa fa-plus"></i> {i18n.t("order_button_add")}
                </button>
              </div>
            ) : null}
          </div>
          <div className="row" style={{ marginBottom: 15, paddingLeft: 15 }}>
            {this._hasSearch() ? (
              <b>
                {this.state.search.all === "true"
                  ? i18n.t("stock_search_all")
                  : i18n.t("stock_search", {
                      from: this.state.search.fromdate,
                      to: this.state.search.todate,
                    })}
              </b>
            ) : (
              <b>{i18n.t("stock_default_search")}</b>
            )}
          </div>

          <div
            className="row"
            style={{ marginBottom: 15, paddingLeft: 15, cursor: "pointer" }}
          >
            <span
              onClick={(e) => {
                this.props.push("/stock");
              }}
            >
              <i className="fa fa-times"></i> {i18n.t("common_reset_search")}
            </span>
          </div>

          {this._renderSites()}
        </div>
        <Modal
          show={this.state.searchIsOpen}
          title="Filter"
          onClose={this._closeSearch}
          onOk={this._search}
        >
          {<SearchStock that={this} />}
        </Modal>

        {/*<ModalEdit show={this.state.depositIsOpen}
                    title={i18n.t("common_deposit")}
                    onClose={this._closeDeposit}>
                    {
                        <AddDeposit that={this} onClose={this._closeDeposit} onSave={this._saveDeposit}/>
                    }
                </ModalEdit>*/}

        {/*<ModalEdit show={this.state.withdrawalIsOpen}
                    title={i18n.t("common_withdrawal")}
                    onClose={this._closeWithdrawal}>                                        
                    {
                        <AddWithdrawal that={this} onClose={this._closeWithdrawal} onSave={this._saveWithdrawal}/>
                    }
                </ModalEdit>*/}
        <ModalEdit
          show={this.state.deliveryIsOpen}
          title={i18n.t("common_delivery")}
          onClose={this._closeDelivery}
        >
          {
            <EditDelivery
              that={this}
              onClose={this._closeDelivery}
              onSave={this._saveDelivery}
            />
          }
        </ModalEdit>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Stock);   // mapDispatchToProps
