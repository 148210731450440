import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import NetworkService from '../../services/NetworkService';
import "react-datepicker/dist/react-datepicker.css";
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
    }
}

class AddClaim extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        if(this.props.item){
            this.state = {
                claim: Object.assign({},this.props.item)
            }
        }else{
            this.state = {
                claim:{
                    name:"",
                    code:"",
                    certificateid:"",
                    transfer:false
                }
            }
        }
    }

    _handleChange(type, val){
        if(type === "transfer"){
            val= !this.state.claim.transfer;
            val = val ? 1: 0
        }
        let claim = Object.assign({},this.state.claim);
        claim[type] = val;
        this.setState({claim})
    }

    _saveClaim(e){
        e.preventDefault();
        var that =this;
        let claim = Object.assign({},that.state.claim);

        if(claim.claimid!=null || claim.claimid!==undefined){
            NetworkService.updateClaim(claim, that.props.session, function (claim) {
                that.props.onSave();
            }, function (err) {
                console.log(err);
            });

        }else{
            claim.certificateid = this.props.that.state.certificate.certificateid;
            NetworkService.createClaim(claim, that.props.session, function (claim) {
                that.props.onSave();
            }, function (err) {
                console.log(err);
            });
        }
    }

    render() {
        return (
            <div id="page-content-modal-wrapper">
            <div className="panel panel-default">
                <form onSubmit={e => this._saveClaim(e)}>
                    <div className="panel-body">
                        <fieldset className="mt-4">
                            <div className="form-group">
                                <label>{i18n.t("claim_common_name")}</label>
                                <input required className="form-control" type="text" value={this.state.claim.name || ""} onChange={e => this._handleChange("name", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("claim_common_code")}</label>
                                <input required className="form-control" type="text" value={this.state.claim.code || ""} onChange={e => this._handleChange("code", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("claim_common_transfer")}</label><br/>
                                <input type="checkbox" checked={this.state.claim.transfer} onChange={e => this._handleChange("transfer", e.target.value)} />
                            </div>
                        </fieldset>
                    </div>
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-danger" type="button"  onClick={this.props.onClose}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(AddClaim);
