import moment from "moment";
import React from 'react';
import Autosuggest from 'react-autosuggest';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import helper from '../../services/helper';
import i18n from '../../services/i18n';
import NetworkService from '../../services/NetworkService';
import Decimal from "decimal.js";

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        order: state.order
    }
}

let amountTimeout;
let uniqueTimeout;

const getSuggestionValue = suggestion => suggestion.articlenumber + " - " + suggestion.articledescription;

const renderSuggestion = suggestion => (
    <div className="autocomplete-list">
        {suggestion.articlenumber + " - " + suggestion.articledescription}
    </div>
);

class EditOrder extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)  
        console.log("EditOrder props", props);      
            this.state = {  
                articleselection:[],
                value: '',
                suggestions:[],   
                order: (props.match && typeof this.props.match.params.orderid == 'undefined')?{
                    ordernumber:"", 
                    invoicenumber:"",
                    clientnumber:"",                    
                    registrationdate: moment().toDate(),
                    orderrow:[]
                }:Object.assign({},this.props),
                orderrow:{                    
                    articleid:"",
                    claimid:"",
                    amount:"",
                    warning:false,
                    mixpercent:"",
                    cubicmeter:"",
                    numberofarticle:""
                },
                method: "cubicmeter",
                volume:"",
				articles:[],
				claims:[],
                sites:[],
                availableCredits:0,
                codeTaken: false,
            }
    }

    componentDidMount() {  
        this._isMounted = true;         
        var that = this;          
        if (that.props.match && typeof that.props.match.params.orderid !== 'undefined'){       
            NetworkService.getOrder(that.props.match.params.orderid, that.props.session, function (order) {             
                if (that._isMounted) {                                                        
                    that.setState({order: Object.assign({}, order)})            
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleArticlesList(that.props.session, function (article) {      
            if (that._isMounted) {                   
                that.setState({articles:article,articleselection:article})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleClaimsList(that.props.session, function (claim) {      
            if (that._isMounted) {                   
                that.setState({claims:claim})            
            }
        }, function (err) {
            console.log(err);
		});
		
		NetworkService.getSimpleSitesList(that.props.session, function (site) {
            helper.sortArray(site, "name");
            if (that._isMounted) {                   
                that.setState({sites:site})            
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.orderrow.articleid !== this.state.orderrow.articleid || prevState.orderrow.siteid !== this.state.orderrow.siteid || prevState.orderrow.claimid !== this.state.orderrow.claimid){
            this._updateAvailableAmount();
        }
    }

    _saveOrder(e){       
        e.preventDefault();                       
        var that =this;       
        let order = Object.assign({},this.state.order);         
        order.registrationdate = moment(order.registrationdate).format('YYYY-MM-DD');    
        if(order.orderid!=null || order.orderid!==undefined){            
            NetworkService.updateOrder(order, that.props.session, function (order) {            
                that.props.push('/order');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            console.log("order going to create--", order);
            NetworkService.createOrder(order, that.props.session, function (order) {                     
                // [OV] Changd to 'props.push' (class component in React 18)
                that.props.push('/order');                       
            }, function (err) {
                console.log(err);
            });
        }        
    }

    _cancel(){
        if(this.state.order.orderid){
            this.props.push('/order/detail/'+this.state.order.orderid);
        } else {
            this.props.push('/order');
        }
    } 

    _handleChange(type, val){
        let order = Object.assign({},this.state.order);         
        order[type] = val;
        this.setState({order})        
    }

    _handleOrderRowChange(type, val){

        if(type==="articleid"){
            var artwarn = document.getElementById("artwarn");
            if(artwarn){
                if(artwarn.classList.contains('err-visible')){                
                    artwarn.classList.remove('err-visible');                
                }
            }
        }        

        if(type==="claimid"){
            var claimelem = document.getElementById("claimelem");
            if(claimelem){
                if(claimelem.classList.contains('error')){                
                    claimelem.classList.remove('error');                
                }
            }
        }

        if(type==="amount"){
            var amntelem = document.getElementById("amntelem");
            if(amntelem){
                if(amntelem.classList.contains('error')){                
                    amntelem.classList.remove('error');                
                }
            }
        }

        let orderrow = Object.assign({},this.state.orderrow);         
        orderrow[type] = val;
        this.setState({orderrow})        
    }

    _createArticleItems() {        
        let items = [];
        items.push(<option key="artllistinit" value="">{i18n.t("common_select_article")}</option>);
        if(this.state.articles && this.state.articles.length>0){
            for (let i = 0; i < this.state.articles.length; i++) {
                items.push(<option key={i} value={this.state.articles[i].articleid}>{this.state.articles[i].articlenumber + " - " + this.state.articles[i].articledescription}</option>);
            }
        }
        return items;
    }

    _createClaimItems(){
        let items = [];
        items.push(<option key="clmlistinit" value="">{i18n.t("common_select_claim")}</option>);
        if(this.state.claims && this.state.claims.length>0){
            for (let i = 0; i < this.state.claims.length; i++) {
                items.push(<option key={i} value={this.state.claims[i].claimid}>{this.state.claims[i].name}</option>);
            }
        }
        return items;
	}
	
	_createSiteItems(){
        let items = [];
        items.push(<option key="sitelistinit" value="">{i18n.t("common_select_site")}</option>);
        if(this.state.sites && this.state.sites.length>0){
            for (let i = 0; i < this.state.sites.length; i++) {
                items.push(<option key={i} value={this.state.sites[i].siteid}>{this.state.sites[i].name}</option>);
            }
        }
        return items;
    }

    _addOrderRow(){          
        let order = Object.assign({},this.state.order);                             
        let orderrow = Object.assign({},this.state.orderrow);             
        var artwarn = document.getElementById("artwarn");
        if(!this.state.orderrow.articleid){ 
            artwarn.classList.add("err-visible");   
            return;
        }else{            
            artwarn.classList.remove("err-visible"); 
        }

        var siteelem = document.getElementById("siteelem");
        if(!this.state.order.siteid){ 
            siteelem.classList.add("error");   
            return;
        }else{            
            siteelem.classList.remove("error"); 
        }

        var claimelem =  document.getElementById("claimelem");
        if(claimelem){
            if(!this.state.orderrow.claimid){
                claimelem.classList.add("error");  
                return;
            }else{            
                claimelem.classList.remove("error"); 
            }
        }
        
        var amntelem =  document.getElementById("amntelem");
        if(amntelem){
            if(!this.state.orderrow.amount){
                amntelem.classList.add("error");  
                return;
            }else{            
                amntelem.classList.remove("error"); 
            }
        }
        
        var cubicelem = document.getElementById("cubicelem");
        if(cubicelem){
            if(!this.state.orderrow.cubicmeter){
                cubicelem.classList.add("error");  
                return;
            }else{            
                cubicelem.classList.remove("error"); 
            }
        }

        var numelem = document.getElementById("numelem");
        if(numelem){
            if(!this.state.orderrow.numberofarticle){
                numelem.classList.add("error");  
                return;
            }else{            
                numelem.classList.remove("error"); 
            }
        }

        var mxprelem = document.getElementById("mxprelem");
        if(mxprelem){
            if(!this.state.orderrow.mixpercent){
                mxprelem.classList.add("error");  
                return;
            }else{            
                mxprelem.classList.remove("error"); 
            }
        }

        NetworkService.getAvailableAmount(this.state.orderrow.articleid, this.state.orderrow.claimid, this.state.order.siteid, this.props.session).then(total => {           
            if(total.sum < this.state.orderrow.amount){
                orderrow.warning = true;
            }           
            order.orderrow.push(orderrow);
            this.setState({           
                order: order            
            }); 

        }, function (err) {
            console.log(err);
        });
    }  

    _removeOrderRow(type, item){      
        var arr = this.state.order.orderrow || [];
        for( var i = 0; i < arr.length; i++){ 
            if ( arr[i] === item) {
              arr.splice(i, 1); 
            }
        }
      
        let order = Object.assign({},this.state.order);                
        order[type] = arr;
        this.setState({
            order: order           
        });     
    }

    _renderOrderRows(){        
        let rows = [];   
        var hasAccess =helper.checkAccess(this.props.session.scope, "ORDERROW", 1, 1)                    
        if (this.state.order.orderrow && this.state.order.orderrow.length > 0) {
        rows =this.state.order.orderrow.map((item, i) => {            
            let article = Object.assign({}, this.state.articles.find( article => article.articleid === parseInt(item.articleid)));                 
            let claim = Object.assign({}, this.state.claims.find( claim => claim.claimid === parseInt(item.claimid))); 
            return (
                <tr key={i} className={item.warning ? "table-danger" : ""}>
                    <td>{article.articlenumber + " - " + article.articledescription}</td>                        
                    <td>{claim.name}</td>                            
                    <td>{item.cubicmeter}</td> 
                    <td>{item.mixpercent}</td>
                    <td>
                        {item.warning ? <span data-toggle="tooltip" title={i18n.t("orderrow_warning")}> <i className="fa fa-exclamation-triangle" style={{color: 'red'}}></i></span> : null}
                    </td>
                    <td>  
                    {hasAccess ?
                        <button type="button" className="close" onClick={e => this._removeOrderRow("orderrow", item)} >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        :null
                    }
                    </td>                              
                </tr>
            )})}
            else{
                return <tr><td colSpan="12">{i18n.t("orderrow_message_nodata")}</td></tr>
            }
            
        return rows;
    }

    _handleDateChange = date => {
        let order = Object.assign({},this.state.order);        
        order.registrationdate = date;        
        this.setState({
            order: order           
        });
    };

    _handleMethodChange(type, val){       
        var that= this;
        let orderrow = Object.assign({},that.state.orderrow);
        orderrow.mixpercent=null;
        orderrow.amount=null;
        orderrow.cubicmeter=null;
        orderrow.numberofarticle=null;
        orderrow.articleid=null;
        orderrow.claimid=null;
        that.setState({           
            orderrow: orderrow,                        
            method: val                      
        });
    }

    _handleCubicChange(type, val){  
        var that = this;          
        let orderrow = Object.assign({},that.state.orderrow);             
        if(val<0){        
            val =0;            
        }
        
        var cubicelem = document.getElementById("cubicelem");
        if(cubicelem){
            if(cubicelem.classList.contains('error')){                
                cubicelem.classList.remove('error');                
            }
        }

        orderrow[type] = val;   
        that.setState({
            orderrow: orderrow           
        }, function(){             
            if(that.state.orderrow.cubicmeter && that.state.orderrow.mixpercent){
                let orderrow = Object.assign({},that.state.orderrow);            
                var cal_amt = new Decimal(orderrow.mixpercent * orderrow.cubicmeter);
                cal_amt = cal_amt.dividedBy(100);
                orderrow.amount = cal_amt.toFixed(5);
                that.setState({orderrow:orderrow})     
            }                         
        }); 
    }

    _handleMixPercentChange(type, val){       
        let orderrow = Object.assign({},this.state.orderrow);   
        if(val > 100){
            val = 100;                                     
        }  
        orderrow[type] = val;
        var that = this;
        this.setState({
            orderrow: orderrow           
        }, function(){   
            let orderrow = Object.assign({},that.state.orderrow);            
          
            if(orderrow.cubicmeter){
                var cal_amt = new Decimal(val * this.state.orderrow.cubicmeter);
                cal_amt = cal_amt.dividedBy(100);
                orderrow.amount = cal_amt.toFixed(5);
            }            
            if(this.state.volume && orderrow.numberofarticle){
                var cal_amount = new Decimal(val * this.state.volume * orderrow.numberofarticle);
                cal_amount = cal_amount.dividedBy(100);
                orderrow.amount = cal_amount.toFixed(5);
            } 
            this.setState({orderrow:orderrow})                             
        }); 
    }

    _handleArticleChange(type, val){
        
        if(val){			
			let articleid = val;			
            var artwarn = document.getElementById("artwarn");
            if(artwarn.classList.contains('err-visible')){                
                artwarn.classList.remove('err-visible');                
            }
            var that=this;    
            let orderrow = Object.assign({},that.state.orderrow);  
          
			orderrow[type] = articleid;
            NetworkService.getArticle(articleid, that.props.session, function (article) {                        
                var vol = (article.width * article.height * article.depth)/1000000000;  
                if(that.state.method==="article"){
                    if(that.state.orderrow.numberofarticle){
                        orderrow.cubicmeter = vol * that.state.orderrow.numberofarticle;   
                    }
                }              

                that.setState({
                    orderrow:orderrow,
                    volume: vol                                     
                }, function(){
                    let orderrow = Object.assign({},that.state.orderrow);
                    if(orderrow.mixpercent && that.state.orderrow.numberofarticle && that.state.volume){
                        var cal_amount = new Decimal(orderrow.mixpercent * that.state.volume * that.state.orderrow.numberofarticle);
                        cal_amount = cal_amount.dividedBy(100);
                        orderrow.amount = cal_amount.toFixed(5);
                        that.setState({           
                            orderrow: orderrow,                                           
                        });
                    }
                });              
            }, function (err) {
                console.log(err);
            });
        } 
    }

    _handleNumOfArticleChange(type, val){
        var that=this;   
        let orderrow = Object.assign({},that.state.orderrow);  
        if(val<0){        
            val =0;            
        } 
        
        var numelem = document.getElementById("numelem");
        if(numelem){
            if(numelem.classList.contains('error')){                
                numelem.classList.remove('error');                
            }
        }
        
        orderrow[type] = val;    
        that.setState({ 
            orderrow: orderrow                                             
        }, function(){
            let orderrow = Object.assign({},that.state.orderrow);
            if(orderrow.mixpercent && that.state.orderrow.numberofarticle && that.state.volume){
                var cal_amount = new Decimal(orderrow.mixpercent * that.state.volume * that.state.orderrow.numberofarticle);
                cal_amount = cal_amount.dividedBy(100);
                orderrow.amount = cal_amount.toFixed(5); 
                that.setState({           
                    orderrow: orderrow,                                           
                });
            }

            if(that.state.volume && that.state.orderrow.numberofarticle){
                var cal_cubicmeter = new Decimal(that.state.volume * that.state.orderrow.numberofarticle);              
                orderrow.cubicmeter = cal_cubicmeter.toFixed(5); 
                that.setState({           
                    orderrow: orderrow,                                           
                });
            }

        });  
    }

    _updateAvailableAmount(){
        if(this.state.orderrow.articleid && this.state.orderrow.claimid && this.state.order.siteid){
            if(amountTimeout){
                clearInterval(amountTimeout);
            }
            //Timeout to avoid spamming server
            amountTimeout = setTimeout(()=>{
                NetworkService.getAvailableAmount(this.state.orderrow.articleid, this.state.orderrow.claimid, this.state.order.siteid, this.props.session).then(total => {           
                    this.setState({ availableCredits: total.sum });
                }, function (err) {
                    console.log(err);
                });
            },500);
        } else {
            this.setState({availableCredits: 0});
        }
    }

    //Check if ordernumber is unique in combination with siteid
    _validateNumber(type, value){
        let ordernumber = "";
        let siteid = "";

        if(type === "siteid" && value && this.state.order.ordernumber){
            ordernumber = this.state.order.ordernumber;
            siteid = value;
        }

        if(type === "ordernumber" && value && this.state.order.siteid){
            ordernumber = value;
            siteid = this.state.order.siteid;
        }

        var that = this;

        if(uniqueTimeout){
            clearInterval(uniqueTimeout);
        }
        //Timeout to avoid spamming server
        uniqueTimeout = setTimeout(()=>{
            if(ordernumber && siteid){
                NetworkService.getOrderNumberAvailability(ordernumber, siteid, this.props.session).then(value=>{
                    var ordnoel = document.getElementById("ordnoel");
                    if(!value.unique){
                        that.setState({codeTaken: true});
                        ordnoel.classList.add("error");
                        
                    } else {
                        that.setState({codeTaken: false});
                        ordnoel.classList.remove("error");
                    }
                }).catch(err=>{
                    console.log(err);
                })
            }
        },500);
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;


        this.state.articleselection.sort(function(artA, artB) {
            var a = artA.articledescription;
            var b = artB.articledescription;

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        let sugg = inputLength === 0 ? [] : this.state.articleselection.filter(art =>
            (art.articlenumber + " - " + art.articledescription).toLowerCase().includes(inputValue)
        );



        return sugg.slice(0, 30);
      };

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (ev,val) => {
        this._handleArticleChange("articleid",val.suggestion.articleid);
    };

    render() {
        const title = i18n.t("order_title_edit");
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: i18n.t("autocomplete_placeholder"),
            value,
            onChange: this.onChange
          };
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">         
                <form onSubmit={e => this._saveOrder(e)}>                                   
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">
                            <p>* {i18n.t("order_edit_reference_explanation")}</p>
                            <div className="form-group">
                                <label>{i18n.t("order_common_ordernumber")} *</label> {this.state.codeTaken?<label className="code-not-unique-warning">{i18n.t("order_ordernumber_taken")}</label>:null}
                                <input id="ordnoel" className="form-control" type="text" value={this.state.order.ordernumber || ""} onChange={e => {this._handleChange("ordernumber", e.target.value);this._validateNumber("ordernumber",e.target.value)}} disabled={this.state.order.orderid} required/>
                            </div>  
                            <div className="form-group">
                                <label>{i18n.t("order_common_invoicenumber")} *</label>
                                <input className="form-control" type="text" value={this.state.order.invoicenumber || ""} onChange={e => this._handleChange("invoicenumber", e.target.value)} required/>
                            </div>  
                            <div className="form-group">
                                <label>{i18n.t("order_common_clientnumber")}</label>
                                <input className="form-control" type="text" value={this.state.order.clientnumber || ""} onChange={e => this._handleChange("clientnumber", e.target.value)}/>
                            </div>                                              
                            <div className="form-group">
                                <label>{i18n.t("order_common_registrationdate")}</label>
                                <div>
                                    <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.order.registrationdate ? moment(this.state.order.registrationdate).toDate() : null} value={moment(this.state.order.registrationdate).toDate()}  onChange={this._handleDateChange} required/>
                                </div>                                
                            </div>
							<div className="form-group">
                                <label>{i18n.t("common_site")}</label>                               
                                    <select name="select"  id ="siteelem" className="form-control" value={this.state.order.siteid || ""} onChange={e => {this._handleChange("siteid", e.target.value);this._validateNumber("siteid",e.target.value)}} required >
                                    {this._createSiteItems()}
                                </select>                               
                            </div>
                        </fieldset>
                        <fieldset className="col-md-12 mt-2">                             
                            <label><strong>{i18n.t("common_orderrows")}</strong></label>                                       
                            <div className="modal-footer pb-0"></div> 
                        </fieldset> 
                        <fieldset className="col-md-6">  
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_calcreditby")}</label>    
                                <select className="form-control" value={this.state.method}  onChange={e => this._handleMethodChange("method", e.target.value)}>                                    
                                    <option value="cubicmeter">{i18n.t("deposit_common_cubicmeter")}</option>
                                    <option value="article">{i18n.t("deposit_select_article")}</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("common_article")}</label>                               
                                
                                    
                                    <p>{this.state.articleselection.length} {i18n.t("deposit_common_articles")}</p>
                                    <p id="artwarn" className="artwarn">{i18n.t("deposit_articles_no_selection")}</p>
                                    {this.state.articleselection.length > 25?
                                    <Autosuggest
                                        theme={{input:"form-control"}}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                    />
                                    :
                                    <select name="select" id ="artlelem" className="form-control" value={this.state.orderrow.articleid || ""} onChange={e => this._handleArticleChange("articleid", e.target.value)} >
                                    {this._createArticleItems()}
                                </select>}                           
                                {this.state.orderrow.articleid && this.state.volume <=0 && this.state.method ==="article" && <label className="warning badge-warning mt-2 ">{i18n.t("deposit_common_article_alert")}</label>}
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("common_claim")}</label>                               
                                    <select name="select"  id ="claimelem" className="form-control" value={this.state.orderrow.claimid || ""} onChange={e => this._handleOrderRowChange("claimid", e.target.value)} >
                                    {this._createClaimItems()}
                                </select>                               
                            </div>                                                      
                            {this.state.method ==="article" && this.state.volume >0 &&
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_noofarticle")}</label>
                                    <input className="form-control" id="numelem" type="number" value={this.state.orderrow.numberofarticle || ""}  onChange={e => this._handleNumOfArticleChange("numberofarticle", e.target.value)} />
                                </div>
                            } 
                            { this.state.method ==="article" ? 
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_cubicmeter")}</label>
                                    <label className="form-control">{this.state.orderrow.cubicmeter || ""} </label>
                                </div> 
                                :
                                <div className="form-group">
                                    <label>{i18n.t("deposit_common_cubicmeter")}</label>
                                    <input className="form-control" id="cubicelem" type="number" value={this.state.orderrow.cubicmeter || ""} onChange={e => this._handleCubicChange("cubicmeter", e.target.value)} />
                                </div>
                            }                           
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_mixpercent")}</label>
                                <input className="form-control" id="mxprelem" type="number" min="70" max="100" value={this.state.orderrow.mixpercent || ""} onChange={e => this._handleMixPercentChange("mixpercent", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_amount")}{" ("+this.state.availableCredits.toFixed(5)+" "+i18n.t("credit_common_available")+")"}</label>
                                <p>{this.state.orderrow.amount || ""}</p>
                            {/*<input className="form-control" type="number" id ="amntelem" step="any" value={this.state.orderrow.amount || ""} onChange={e => this._handleOrderRowChange("amount", e.target.value)}/>*/}
                            </div>                            
                        </fieldset> 
                        <fieldset className="col-md-12 ">
                            <button className="btn btn-primary" type="button" onClick={e => this._addOrderRow()}>{i18n.t("orderrow_button_add")}</button>                       
                        </fieldset>
                        <fieldset className="col-md-12 ">
                        <table className="table table-striped mt-4 table-responsive-sm">                        
                            <thead>
                                <tr>
                                    <th>{i18n.t("common_article")}</th> 
                                    <th>{i18n.t("common_claim")}</th>                           
                                    <th>{i18n.t("deposit_common_cubicmeter")}</th>  
                                    <th>{i18n.t("deposit_common_mixpercent")}</th>  
                                    <th></th>                                                      
                                    <th></th> 
                                </tr>
                            </thead>
                            <tbody>                   
                            {
                                this._renderOrderRows()                            
                            }                    
                            </tbody>                
                        </table> 
                        </fieldset>
                        
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" disabled={this.state.order.orderrows && this.state.order.orderrows.length <= 0} type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>   
                </form>                            
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditOrder);
