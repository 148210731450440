import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setArticleGroup: (articlegroup) => dispatch({
            type: 'SET_ARTICLEGROUP',
            articlegroup: articlegroup,
        }),
    };
};

class ArticleGroup extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {           
            articlegroup: {},
            isOpen: false,
			deletearticlegroupid:"",
			articleGroupLoading:false,
        }  
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteArticleGroup = this._deleteArticleGroup.bind(this);
    }

    componentDidMount() {    
        this._isMounted = true;       
		var that = this;
		this.setState({articleGroupLoading:true},function(){
			NetworkService.getArticleGroups(that.props.session, function (articlegroup) {  
				if (that._isMounted) {                                      
					that.setState({articlegroups:articlegroup, articleGroupLoading:false});
				}
			}, function (err) {
				console.log(err);
				that.setState({articleGroupLoading:false});
			});
		});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _deleteArticleGroup(){               
        var that =this;               
        NetworkService.deleteArticleGroup(that.state.deletearticlegroupid, that.props.session, function (articlegroup) {                         
            that.props.push(0)     
        }, function (err) {
            console.log(err);
        });       
    }      
    
    _renderArticleGroupRows(){        
        let rows = [];
        var hasAccess  = !(this.props.session.scope.role === "Standard"); // helper.checkAccess(this.props.session.scope, "ARTICLEGROUP", 1, 1)     
        if (this.state.articlegroups && this.state.articlegroups.length > 0) {
        rows =this.state.articlegroups.map((item, i) => {                                                     
            return (
                <tr key={i} className={hasAccess ?"tr-clickable":""} onClick={e => hasAccess ? this._addArticleGroup(item): ""}>     
                    <td>{item.certificate.name}</td>
                    <td>{item.name}</td>                 
                    <td>{item.description}</td>  
                    <td>{item.typeofwood}</td>
                    <td style={{textAlign: "right"}}>{item.articles?item.articles:"-"}</td>
                    {/* [OV] Removed Delete button
                    <td>  
                    {hasAccess ?          
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.articlegroupid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>  */}
                </tr>
            )})} else if(this.state.articleGroupLoading){
				return <tr><td colSpan="5" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else{
                return <tr><td colSpan="5">{i18n.t("articlegroup_message_nodata")}</td></tr>
            }

        return rows;
    }
    
    _addArticleGroup(item){       
        if(item!==undefined){            
            this.props.setArticleGroup({
                articlegroupid: item.articlegroupid,               
                name: item.name,              
                description: item.description,
                typeofwood: item.typeofwood,
                certificateid: item.certificateid,
            });
            this.props.push(`/articlegroup/detail/${item.articlegroupid}`);
        }else{            
            this.props.push('/articlegroup/add');
        }
    }    

    toggleModal(e, articlegroupid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletearticlegroupid: articlegroupid
        });
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletearticlegroupid: null
        });        
    }     
   
    render() {  
        const title = i18n.t("common_articlegroups");      
        var hasAccess =helper.checkAccess(this.props.session.scope, "ARTICLEGROUP", 1, 1)                
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/>            
            <div className="container mt-5">
                <div className="row justify-content-center">               
                
                { hasAccess ?
                <div className="col-md-4 ">
                    <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addArticleGroup()}> <i className="fa fa-plus"></i> {i18n.t("articlegroup_button_add")}</button>
                </div>       
                : null
                }                              
            </div>
            <table className="table table-striped mt-4 table-responsive-sm">                        
                <thead>
                    <tr>
                        <th>{i18n.t("common_certificate")}</th>
                        <th>{i18n.t("articlegroup_common_name")}</th>
                        <th>{i18n.t("articlegroup_common_description")}</th> 
                        <th>{i18n.t("articlegroup_common_typeofwood")}</th>
                        <th>Articles</th>
                    </tr>
                </thead>
                <tbody>{this._renderArticleGroupRows()}</tbody>                
                </table>          
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteArticleGroup}>                    
                    {i18n.t("articlegroup_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleGroup);
