import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import Modal from '../../components/Modal';
import helper from '../../services/helper';
import i18n from '../../services/i18n';
import moment from "moment";
import TableHeader from '../TableHeader';
import ListPagination from '../ListPagination';
//import { withRouter } from 'react-router-dom';
import { withRouter } from '../../services/withRouter';  // [OV] Replaced
import queryString from 'query-string';
import { Collapse } from 'reactstrap';
import DatePicker from "react-datepicker";

const mapStateToProps = function (state) {
    return {
        session: state.session,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOrder: (order) => dispatch({
            type: 'SET_ORDER',
            order: order,
        }),
    };
};

let searchQuery;

class Order extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props)

        searchQuery = this.props.location.search;

        this.state = {
            order: {},
            claims: [],
            isOpen: false,
            deleteorderid: "",
            orderLoading: false,
            search: {
                freeText: queryString.parse(searchQuery).search || '',
                articleNumber: queryString.parse(searchQuery).articlenumber || '',
                clientNumber: queryString.parse(searchQuery).clientnumber || '',
                registrationDate: queryString.parse(searchQuery).registrationdate || '',
                claimName: queryString.parse(searchQuery).claimname || '',
                status: queryString.parse(searchQuery).status || '',
            },
            showAdvancedSearch: false
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteOrder = this._deleteOrder.bind(this);
        this._searchChange = this._searchChange.bind(this);
        this._searchSubmit = this._searchSubmit.bind(this);
        this._searchKeyDown = this._searchKeyDown.bind(this);
        this._toggleAdvancedSearch = this._toggleAdvancedSearch.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        this._fetchOrders();
        this._fetchClaims();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        if (searchQuery !== this.props.location.search) {
            searchQuery = this.props.location.search;
            this._fetchOrders();
        }
    }

    toggleModal(e, orderid) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isOpen: !this.state.isOpen,
            deleteorderid: orderid
        });
    }

    onClose() {
        this.setState({
            isOpen: !this.state.isOpen,
            deleteorderid: null
        });
    }

    _deleteOrder() {
        var that = this;
        NetworkService.deleteOrder(this.state.deleteorderid, this.props.session, function (order) {
            that.props.history.go(0);
        }, function (err) {
            console.log(err);
        });
    }

    _renderOrderCount() {
        const { orders } = this.state;

        if (orders) {
            return <b>{i18n.t("common_search_results_count")} {orders.totalCount}</b>
        } else {
            return <b>{i18n.t("common_search_results_count")} 0</b>;
        }
    }

    _renderOrderRows() {
        let rows = [];
        var hasAccess = helper.checkAccess(this.props.session.scope, "ORDER", 1, 1)
        if (this.state.orders && this.state.orders.items.length > 0) {
            rows = this.state.orders.items.map((item, i) => {
                return (
                    <tr key={i} className={hasAccess ? "tr-clickable" : ""} onClick={e => hasAccess ? this._addOrder(item) : ""}>
                        <td>{item.ordernumber}</td>
                        <td>{item.invoicenumber}</td>
                        <td>{item.clientnumber}</td>
                        <td>{item.site.name}</td>
                        <td>{item.claim}</td>
                        <td>{item.status}</td>
                        <td>{moment(item.registrationdate).format('YYYY-MM-DD')}</td>
                        {/* [OV] Removed Delete button
                        <td>
                            {hasAccess ?
                                <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.orderid)}>{i18n.t("common_button_delete")}</button>
                                : null
                            }
                        </td> */}
                    </tr>
                )
            })
        } else if (this.state.orderLoading) {
            return <tr><td colSpan="7" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
        } else {
            return <tr><td colSpan="7">{i18n.t("order_message_nodata")}</td></tr>
        }

        return rows;
    }

    _renderOrderHeaders() {
        let headers = [
            { header: i18n.t("order_common_ordernumber"), column: 'ordernumber', order: true },
            { header: i18n.t("order_common_invoicenumber"), column: 'invoicenumber', order: true },
            { header: i18n.t("order_common_clientnumber"), column: 'site', order: true },
            { header: i18n.t("order_common_site"), order: false },
            { header: i18n.t("order_common_claim"), order: false },
            { header: i18n.t("order_common_status"), column: 'status', order: true },
            { header: i18n.t("order_common_registrationdate"), column: 'registrationdate', order: true },
            // { header: '', order: false }
        ];

        return headers.map((r, i) => {
            return (
                <TableHeader 
                    key={i} headerdata={r} 
                    location={this.props.location} 
                    push={(dest) => this.props.push(dest)} // [OV] For class components to redirect
                />
            );
        });
    }

    _fetchClaims() {
        var that = this;

        NetworkService.getSimpleClaimsList(that.props.session, function (claim) {
            if (that._isMounted) {
                that.setState({ claims: claim })
            }
        }, function (err) {
            console.log(err);
        });
    }

    _fetchOrders() {
        var that = this;
        this.setState({ orderLoading: true }, function () {
            NetworkService.getOrders(searchQuery, that.props.session, function (order) {
                if (that._isMounted) {
                    that.setState({ orders: order, orderLoading: false })
                }
            }, function (err) {
                that.setState({ orderLoading: false })
                console.log(err);
            });
        })
    }

    _addOrder(item) {
        if (item !== undefined) {
            this.props.setOrder({
                orderid: item.orderid,
                ordernumber: item.ordernumber,
                invoicenumber: item.invoicenumber,
                clientnumber: item.clientnumber,
                status: item.status,
                registrationdate: item.registrationdate
            });
            this.props.push(`/order/detail/${item.orderid}`);
        } else {
            this.props.push('/order/add');
        }
    }

    _importOrders() {
        console.log("Push to importorderfiles");
        this.props.push('/importFile');
    }

    _createClaimItems() {
        let items = [];
        items.push(<option key="clmlistinit" value="">{i18n.t("common_select_claim")}</option>);
        if (this.state.claims && this.state.claims.length > 0) {
            for (let i = 0; i < this.state.claims.length; i++) {
                items.push(<option key={i} value={this.state.claims[i].name}>{this.state.claims[i].name}</option>);
            }
        }
        return items;
    }

    _searchChange(event) {

        let search = this.state.search;

        switch (event.target.id) {
            case 'order-search-freetext':
                search.freeText = event.target.value;
                break;
            case 'order-search-articlenumber':
                search.articleNumber = event.target.value;
                break;
            case 'order-search-clientnumber':
                search.clientNumber = event.target.value;
                break;
            case 'order-search-registrationdate':
                // Handled in _searchDateChange
                break;
            case 'order-search-claimname':
                search.claimName = event.target.value;
                break;
            case 'order-search-status':
                search.status = event.target.value;
                break;
            default:
                throw new Error('No matching element id found');
        }

        this.setState({ search: search });
    }

    _searchDateChange = date => {
        // Do not mutate state directly. Use setState()
        let search = this.state.search;
        if (moment(date).isValid()) {
            search.registrationDate = moment(date).format('YYYY-MM-DD');
        } else {
            search.registrationDate = '';
        }

        this.setState({ search: search });
    }

    _searchSubmit() {
        let query = queryString.parse(searchQuery);
        query.search = this.state.search.freeText;
        query.articlenumber = this.state.search.articleNumber;
        query.clientnumber = this.state.search.clientNumber;
        query.registrationdate = this.state.search.registrationDate;
        query.claimname = this.state.search.claimName;
        query.status = this.state.search.status;
        query.page = 1;

        // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
        this.props.push(this.props.location.pathname + "?" + queryString.stringify(query));
    }

    _searchKeyDown(event) {
        if (event.key === 'Enter') {
            this._searchSubmit();
        }
    }

    _hasAdvancedSearch() {
        return this.state.search.articleNumber || this.state.search.clientNumber ||
            this.state.search.registrationDate || this.state.search.claimName ||
            this.state.search.status;
    }

    _toggleAdvancedSearch() {
        this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch });
    }

    render() {
        const title = i18n.t("common_orders");
        var hasAccess = helper.checkAccess(this.props.session.scope, "ORDER", 1, 1)
        return (
            <div id="page-content-wrapper">
                <Navbar title={title} />
                <div className="container mt-5">
                    <div className="row justify-content-center">

                        {hasAccess ?
                            <div className="col-md-2 ">
                                <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addOrder()}> <i className="fa fa-plus"></i> {i18n.t("order_button_add")}</button>
                            </div>
                            : null
                        }

                        {/* Button to import orders from excel file */
                        hasAccess ?
                            <div className="col-md-2 ">
                                <button 
                                    className="btn btn-outline-secondary col-md-12" 
                                    onClick={e => this._importOrders()}> 
                                    <i className="fa fa-upload"></i>&nbsp; 
                                    {i18n.t("common_import")}
                                </button>
                            </div>
                            : null
                        }

                        <div className="col-md-4">
                            <input type="text" id="order-search-freetext" className="form-control" value={this.state.search.freeText} onChange={this._searchChange} onKeyDown={this._searchKeyDown} placeholder={i18n.t("common_search_freetext")} />
                        </div>

                        <div className="col-md-2">
                            <button className="btn btn-outline-secondary" onClick={this._searchSubmit} type="submit">
                                {i18n.t("common_search")}
                            </button>
                        </div>

                        <div className="col-md-2">
                            <button className="btn btn-link" onClick={this._toggleAdvancedSearch}>Avancerat sök</button>
                            {this._hasAdvancedSearch() ?
                                <span data-toggle="tooltip" title={i18n.t("advancedsearch_warning")}><i className="fa fa-exclamation-triangle"></i></span>
                                :
                                null
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                        </div>
                        <div className="col-md-4">
                            <Collapse isOpen={this.state.showAdvancedSearch}>
                                <div className="form-group" style={{ marginTop: '1em' }}>
                                    <label>{i18n.t("common_search_articlenumber")}</label>
                                    <input type="text" id="order-search-articlenumber" value={this.state.search.articleNumber} onChange={this._searchChange} onKeyDown={this._searchKeyDown} className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>{i18n.t("common_search_clientnumber")}</label>
                                    <input type="text" id="order-search-clientnumber" value={this.state.search.clientNumber} onChange={this._searchChange} onKeyDown={this._searchKeyDown} className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label>{i18n.t("common_search_registrationdate")}</label>
                                    <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" id="order-search-registrationdate" selected={this.state.search.registrationDate ? moment(this.state.search.registrationDate).toDate() : null} value={this.state.search.registrationDate ? moment(this.state.search.registrationDate).toDate() : null} onChange={this._searchDateChange} onKeyDown={this._searchKeyDown} />
                                </div>

                                <div className="form-group">
                                    <label>{i18n.t("common_search_status")}</label>
                                    <select name="select" id="order-search-status" className="form-control" value={this.state.search.status} onChange={this._searchChange} >
                                        <option value=""></option>
                                        <option value="completed">completed</option>
                                        <option value="forpicking">forpicking</option>
                                        <option value="draft">draft</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>{i18n.t("common_search_claimname")}</label>
                                    <select name="select" id="order-search-claimname" className="form-control" value={this.state.search.claimName} onChange={this._searchChange} >
                                        {this._createClaimItems()}
                                    </select>
                                </div>
                            </Collapse>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            {
                                this._renderOrderCount()
                            }
                        </div>
                    </div>

                    <table className="table table-striped mt-4 table-responsive-sm">
                        <thead>
                            <tr>
                                {
                                    this._renderOrderHeaders()
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this._renderOrderRows()
                            }
                        </tbody>
                    </table>
                    <ListPagination
                        push={(dest) => this.props.push(dest)}  // [OV] For class components to redirect
                        location={this.props.location}
                        itemCount={this.state.orders ? this.state.orders.totalCount : 0}
                    />
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteOrder}>
                    {i18n.t("order_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Order));
