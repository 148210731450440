const session = (state = {}, action) => {    
    switch (action.type) {
        case 'SET_SESSION':
            return ({
                id: action.session.id,
                userid: action.session.userid,
                guid: action.session.guid,
                scope: action.session.scope,
                role: action.session.role
            });
        default:
            return state
    }
}

export default session