import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        unitname: state.unitname
    }
}

class EditUnitName extends React.Component {
    _isMounted = false;  
    constructor(props) {
        super(props)        
        if (this.props.match && typeof this.props.match.params.unitnameid == 'undefined'){
            this.state = {     
                unitname:{   
                    unitid:"",
                    unitnameid:"",
                    unitname:"",
                }
            }      
        }else{          
            this.state = {     
                unitname: Object.assign({},this.props.unitname)
            } 
        }         
    }
    componentDidMount() {   
        this._isMounted = true;              
        var that = this;                
        if (that.props.match && typeof that.props.match.params.unitnameid !== 'undefined'){       
            NetworkService.getUnitNameById(that.props.match.params.unitnameid, that.props.session, function (unitname) {             
                if (that._isMounted) {                                     
                    that.setState({unitname: Object.assign({}, unitname)})            
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleUnitsList(that.props.session, function (unit) {
            if (that._isMounted) {                   
                that.setState({units:unit})            
            }
        }, function (err) {
            console.log(err);
        });
    }   

    componentWillUnmount() {
        this._isMounted = false;
    }
    _saveUnitName(e){   
        e.preventDefault();                       
        var that =this;
        let unitname = Object.assign({},this.state.unitname);         
        if(unitname.unitnameid!=null && unitname.unitnameid!==undefined && unitname.unitnameid!==""){
            NetworkService.updateUnitName(unitname, that.props.session, function (unitname) {            
                that.props.push('/article/unitname');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            unitname.unitnameid = null;
            NetworkService.saveUnitName(unitname, that.props.session, function (unitname) {              
                that.props.push('/article/unitname');                       
            }, function (err) {
                console.log(err);
            });
        }
    } 

    _cancel(){      
        // if(this.state.unitname.unitnameid){
        //     this.props.push('/unitname/edit/'+this.state.unitname.unitnameid);
        // } else
        {
            this.props.push('/article/unitname'); 
        }       
    }

    _handleChange(type, val){      
        let unitname = Object.assign({},this.state.unitname);                 
        unitname[type] = val;    
        this.setState({
            unitname: unitname           
        });                  
    }

    _createUnitItems() {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_unit")}</option>);
        if(this.state.units && this.state.units.length>0){
            for (let i = 0; i < this.state.units.length; i++) {
                items.push(<option key={i} value={this.state.units[i].unitid}>{this.state.units[i].unit}</option>);
            }
        }
        return items;
    }

    render() {
        console.log("this.state.unitname: ", this.state.unitname);
        const title = i18n.t("article_common_unit_name");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">  
                <form onSubmit={e => this._saveUnitName(e)}>                                          
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">    
                            <div className="form-group">
                                <label>ID</label>
                                <input disabled className="form-control" type="text" value={this.state.unitname.unitnameid || ""} onChange={e => this._handleChange("unitnameid", e.target.value)} />
                            </div>                         
                            <div className="form-group">
                                <label>{i18n.t("article_common_unit_name")}</label>
                                <input className="form-control" type="text" value={this.state.unitname.unitname || ""} onChange={e => this._handleChange("unitname", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("article_common_unit")}</label>
                                <select
                                    name="select" 
                                    className="form-control" 
                                    value={this.state.unitname.unitid || ""} 
                                    onChange={
                                        e => this._handleChange("unitid", e.target.value)
                                    }>
                                        {this._createUnitItems()}
                                    </select>
                            </div>
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>                                
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditUnitName);
