import React from "react";
import { connect } from "react-redux";
import i18n from "../services/i18n";
import { Collapse } from "reactstrap";

const mapStateToProps = function (state) {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class AdvancedSearchStock extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedSearch: false,
    };
  /*   this._handleSearchSite = this._handleSearchSite.bind(this);
    this._handleArticleNumber = this._handleArticleNumber.bind(this); */

    this._toggleCollapse = this._toggleCollapse.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  _toggleCollapse() {
    this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch });
  }

  

  _renderSearchInput() {
    return (
      <div className="d-flex justify-content-center">    {/* [OV] Corrected 'class' to 'className' */}
        <Collapse isOpen={this.state.showAdvancedSearch}>
          <div className="form-group mt-4">
            <label>{this.props.label}</label>
            <div className="col-md-9 input-group ">
              <input
                type="text"
                className="form-control"
                placeholder={i18n.t("common_search")}
                onChange={this.props._handleSearchOne}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="d-flex">    {/* [OV] Corrected 'class' to 'className' */}
          <button className="btn btn-link" onClick={this._toggleCollapse}>
            {i18n.t("common_search_advanced")}
          </button>

        </div>
        
        {this._renderSearchInput()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearchStock);
