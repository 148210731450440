import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NetworkService from '../services/NetworkService';
import helper from '../services/helper'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from "react-router-dom";

function AuthenticatedComponent(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const match = useParams();
    const session = useSelector((state) => state.session);
    const component = {
        ...props.component,
        props: { // [OV] All components get these extra props
            location: location,             // Where are we?
            push: (dest, obj) => navigate(dest, obj), // Tool for class components to go elsewhere
            match: { params: match }        // [OV] Deliver 'match' as props (read path)
        }
    };

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => { checkAuth() }, [session]);

    const checkAuth = () => {
        if (!session.guid) {
            if (helper.getCookie("Session")) {
                NetworkService.getSession(helper.getCookie("Session")).then(async (response) => {
                    let result = await NetworkService.getUser(response.userid, response);
                    response.email = result.username;
                    response.admin = result.admin;
                    dispatch({
                        type: 'SET_SESSION',
                        session: response,
                    })
                }).catch(err => {
                    console.log("error in AuthMiddleware-->", err);
                    helper.deleteCookie('Session');
                    navigate('/login')
                });
            } else {
                navigate('/login')
            }
        }
    }

    return (
        <>
            {session.guid
                && session.scope
                && helper.checkAccess(session.scope, props.resource, props.read, props.write)
                ? component
                : null
            }
        </>
    )
}

export default connect()(AuthenticatedComponent);
