const site = (state = [], action) => {
    switch (action.type) {
        case 'SET_SITE':
            return ({  
                siteid: action.site.siteid,  
                name: action.site.name,                
                partnerid: action.site.partnerid,                        
                userid:action.site.userid,
                street:action.site.street,
                postcode:action.site.postcode,
                city:action.site.city,
                country:action.site.country                
            })
        default:
            return state
    }
}

export default site