import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import i18n from '../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

class SidebarMenu extends React.Component {
    
    render() {
        if (this.props.session.guid) {
            return (
                    <div className="border-right" id="sidebar-wrapper">
                        <div className="sidebar-heading">
                        <Link className="sidebar-logo-link" to="/"><img className="sidebar-logo" src={require("../images/woody_bygghandel_white.png")} alt="woody logo"/></Link>
                        </div>
                        <div className="list-group list-group-flush">
                            <Link to="/" className="list-group-item list-group-item-action"> {i18n.t("common_dashboard").toUpperCase()} </Link>
                            <Link to="/user" className="list-group-item list-group-item-action"> {i18n.t("common_user").toUpperCase()} </Link>
                            <Link to="/blocklist" className="list-group-item list-group-item-action"> {i18n.t("common_blocklist").toUpperCase()} </Link>
                            <Link to="/partner" className="list-group-item list-group-item-action"> {i18n.t("common_partner").toUpperCase()} </Link>     
                            <Link to="/site" className="list-group-item list-group-item-action"> {i18n.t("common_sites").toUpperCase()} </Link>
                            <Link to="/article" className="list-group-item list-group-item-action"> {i18n.t("common_articles").toUpperCase()} </Link>
                            <Link to="/articlegroup" className="list-group-item list-group-item-action"> {i18n.t("common_articlegroups").toUpperCase()} </Link>
                            <Link to="/supplier" className="list-group-item list-group-item-action"> {i18n.t("common_suppliers").toUpperCase()} </Link>
                            {/*<Link to="/supplierverification" className="list-group-item list-group-item-action"> {i18n.t("common_supplierverification").toUpperCase()} </Link>
                            <Link to="/deposit" className="list-group-item list-group-item-action"> {i18n.t("common_deposit").toUpperCase()} </Link>
                            <Link to="/withdrawal" className="list-group-item list-group-item-action"> {i18n.t("common_withdrawal").toUpperCase()} </Link> */}
                            <Link to="/certificate" className="list-group-item list-group-item-action"> {i18n.t("common_certificate").toUpperCase()} </Link>
                            <Link to="/delivery" className="list-group-item list-group-item-action"> {i18n.t("common_delivery").toUpperCase()} </Link>
                            <Link to="/order" className="list-group-item list-group-item-action"> {i18n.t("common_order").toUpperCase()} </Link>
                            <Link to="/stock" className="list-group-item list-group-item-action"> {i18n.t("common_stock").toUpperCase()} </Link>
                            <Link to="/importFile" className="list-group-item list-group-item-action"> {i18n.t("common_import").toUpperCase()} </Link>
                        </div>
                    </div>
            );
        }else{
            return null
        }
    }
};

export default connect(mapStateToProps)(SidebarMenu);
