import React from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "../../App.css";

  //For partners, render custom supplier code
  function _renderPartnerSupplierCode(supplier, editCallback) {
        //Ugly clone in order to avoid mutation
        let selectedSupplier = JSON.parse(JSON.stringify(supplier));
        return (
            <>
                <p>{supplier.partnersuppliercode} <a href="#" onClick={(e)=>{e.stopPropagation();editCallback(selectedSupplier)}}><i className="fa fa-edit"/></a></p> 
            </>
        );
}

function Table({ columns = [], data = [], hasAccess, onClickRow, defaultSortBy = "", isPartnerUser = false, editCallback = ()=>{} }) {
  
  const _defaultSortBy = React.useMemo(() => {
    if (defaultSortBy === "") {
      return [];
    } else {
      return [{
        id: defaultSortBy,
        desc: false
      }]
    }
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,

    // for pagination
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: _defaultSortBy,
        pageIndex: 0,
        pageSize: 10
      },
    },
    useSortBy,
    usePagination
    );
  
  return (
    <>
      <table
        {...getTableProps()}
        className="table table-striped mt-4 table-responsive-sm"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {/* TODO: Add a sort direction indicator */}
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          style={{ marginLeft: 10 }}
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        />
                      ) : (
                        <i
                          style={{ marginLeft: 10 }}
                          className="fa fa-chevron-up"
                          aria-hidden="true"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  if (hasAccess) {
                    onClickRow(row.original);
                  }
                }}
                className="tr-clickable"
              >
                {row.cells.map((cell) => {
                  if(cell.column.id === "code" && isPartnerUser){
                    return <td key={cell.row.id+"_"+cell.column.id} {...cell.getCellProps()}>{cell.render("Cell")}{_renderPartnerSupplierCode(cell.row.original, editCallback)}</td>;
                  }
                  return <td key={cell.row.id+"_"+cell.column.id} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Pagination size="sm">
          <PaginationItem disabled={!canPreviousPage}>
            <PaginationLink onClick={() => gotoPage(0)}>First</PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!canPreviousPage}>
            <PaginationLink onClick={() => previousPage()} previous />
          </PaginationItem>
          {Array(pageCount)
            .fill(0)
            .map((_, i) => (
              <PaginationItem active={i === pageIndex} key={'PI'+i}>
                <PaginationLink onClick={() => gotoPage(i)} key={i}>
                  {i+1}
                </PaginationLink>
              </PaginationItem>
            ))}
          <PaginationItem disabled={!canNextPage}>
            <PaginationLink onClick={() => nextPage()} next />
          </PaginationItem>
          <PaginationItem disabled={!canNextPage}>
            <PaginationLink onClick={() => gotoPage(pageCount - 1)}>Last</PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
}

export default Table;
