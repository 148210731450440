const delivery = (state = [], action) => {
    switch (action.type) {
        case 'SET_DELIVERY':
            return ({  
                deliveryid: action.delivery.deliveryid,                        
                deliverynumber:action.delivery.deliverynumber,
                supplierid:action.delivery.supplierid,
                siteid:action.delivery.siteid,   
                reference:action.delivery.reference,
                registrationdate:action.delivery.registrationdate,               
            })
        default:
            return state
    }
}

export default delivery