import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import Navbar from "../../components/Navbar";
import NetworkService from "../../services/NetworkService";
import moment from "moment";
import helper from "../../services/helper";
import i18n from "../../services/i18n";
import EditDelivery from "./EditDelivery";
import ModalEdit from "../../components/ModalEdit";
import TableHeader from "../TableHeader";
import ListPagination from "../ListPagination";
//import { withRouter } from "react-router-dom";
import { withRouter } from '../../services/withRouter'; // [OV] Replaced (due to React 18)
import queryString from "query-string";
import { Collapse } from "reactstrap";

const mapStateToProps = function (state) {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelivery: (delivery) =>
      dispatch({
        type: "SET_DELIVERY",
        delivery: delivery,
      }),
  };
};

let searchQuery;

class Delivery extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    searchQuery = this.props.location.search;

    this.state = {
      delivery: {},
      deliveries: this.props.deliveries,
      deliveryLoading: false,
      search: {
        freeText: queryString.parse(searchQuery).search || "",
        articleNumber: queryString.parse(searchQuery).articlenumber || "",
        claimName: queryString.parse(searchQuery).claimname || "",
      },
      showAdvancedSearch: false,
    };

    this._closeDelivery = this._closeDelivery.bind(this);
    this._saveDelivery = this._saveDelivery.bind(this);
    this._searchChange = this._searchChange.bind(this);
    this._searchSubmit = this._searchSubmit.bind(this);
    this._searchKeyDown = this._searchKeyDown.bind(this);
    this._toggleAdvancedSearch = this._toggleAdvancedSearch.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchDeliveries();
    this._fetchClaims();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    if (searchQuery !== this.props.location.search) {
      searchQuery = this.props.location.search;
      this._fetchDeliveries();
    }
  }

  _fetchClaims() {
    var that = this;

    NetworkService.getSimpleClaimsList(
      that.props.session,
      function (claim) {
        if (that._isMounted) {
          that.setState({ claims: claim });
        }
      },
      function (err) {
        console.log(err);
      }
    );
  }

  _fetchDeliveries() {
    var that = this;

    that.setState({ deliveryLoading: true }, function () {
      NetworkService.getDeliveries(
        searchQuery,
        that.props.session,
        function (delivery) {
          if (that._isMounted) {
            that.setState({ deliveries: delivery, deliveryLoading: false });
          }
        },
        function (err) {
          console.log(err);
          that.setState({ deliveryLoading: false });
        }
      );
    });
  }

  _addDelivery(item) {
    if (item !== undefined) {
      this.props.setDelivery({
        deliveryid: item.deliveryid,
        supplierid: item.supplierid,
        siteid: item.siteid,
        reference: item.reference,
        registrationdate: item.registrationdate,
      });
      this.props.push(`/delivery/detail/${item.deliveryid}`);
    } else {
      this.props.push("/delivery/add");
    }
  }

  _renderDeliveryCount() {
    const { deliveries } = this.state;

    if (deliveries) {
      return (
        <b>
          {i18n.t("common_search_results_count")} {deliveries.totalCount}
        </b>
      );
    } else {
      return <b>{i18n.t("common_search_results_count")} 0</b>;
    }
  }

  _renderDeliveryRows() {
    let rows = [];
    var hasAccess = helper.checkAccess(
      this.props.session.scope,
      "DELIVERY",
      1,
      1
    );

    const { deliveries } = this.state;

    if (deliveries && deliveries.items.length > 0) {
      rows = deliveries.items.map((item, i) => {
        return (
          <tr
            key={i}
            className={hasAccess ? "tr-clickable" : ""}
            onClick={(e) => (hasAccess ? this._addDelivery(item) : "")}
          >
            <td>{item.deliverynumber}</td>
            <td>{item.supplier.name}</td>
            <td>{item.site.name}</td>
            <td>{item.claim}</td>
            <td>{item.reference}</td>
            <td>{moment(item.registrationdate).format("YYYY-MM-DD")}</td>
          </tr>
        );
      });
    } else if (this.state.deliveryLoading) {
      return (
        <tr>
          <td colSpan="6" className="loading-td">
            <i className="fa fa-spin fa-spinner"></i>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colSpan="6">{i18n.t("delivery_message_nodata")}</td>
        </tr>
      );
    }

    return rows;
  }

  _renderDeliveryHeaders() {
    let headers = [
      {
        header: i18n.t("delivery_common_deliverynumber"),
        column: "deliverynumber",
        order: true,
      },
      {
        header: i18n.t("delivery_common_supplier"),
        column: "supplier",
        order: true,
      },
      { header: i18n.t("delivery_common_site"), column: "site", order: true },
      { header: i18n.t("delivery_common_claim"), order: false },
      {
        header: i18n.t("delivery_common_reference"),
        column: "reference",
        order: true,
      },
      {
        header: i18n.t("delivery_common_registrationdate"),
        column: "registrationdate",
        order: true,
      },
    ];

    return headers.map((r, i) => {
      return <TableHeader key={i} headerdata={r} location={this.props.location} push={(dest) => this.props.push(dest)} />;
    });
  }

  _closeDelivery() {
    this.setState({ deliveryIsOpen: false });
  }

  _saveDelivery() {
    this.setState({ deliveryIsOpen: false });
    this.props.push(0);
  }

  _createClaimItems() {
    let items = [];
    items.push(
      <option key="clmlistinit" value="">
        {i18n.t("common_select_claim")}
      </option>
    );
    if (this.state.claims && this.state.claims.length > 0) {
      for (let i = 0; i < this.state.claims.length; i++) {
        items.push(
          <option key={i} value={this.state.claims[i].name}>
            {this.state.claims[i].name}
          </option>
        );
      }
    }
    return items;
  }

  _searchChange(event) {
    let search = this.state.search;

    switch (event.target.id) {
      case "order-search-freetext":
        search.freeText = event.target.value;
        break;
      case "order-search-articlenumber":
        search.articleNumber = event.target.value;
        break;
      case "order-search-claimname":
        search.claimName = event.target.value;
        break;
      default:
        throw new Error("No matching element id found");
    }

    this.setState({ search: search });
  }

  _searchSubmit() {
    let query = queryString.parse(searchQuery);
    query.search = this.state.search.freeText;
    query.articlenumber = this.state.search.articleNumber;
    query.claimname = this.state.search.claimName;
    query.page = 1;

    // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
    this.props.push(this.props.location.pathname + "?" + queryString.stringify(query));
  }

  _searchKeyDown(event) {
    if (event.key === "Enter") {
      this._searchSubmit();
    }
  }

  _toggleAdvancedSearch() {
    this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch });
  }

  render() {
    const title = i18n.t("common_deliveries");
    var hasAccess = helper.checkAccess(
      this.props.session.scope,
      "DELIVERY",
      1,
      1
    );
    return (
      <div id="page-content-wrapper">
        <Navbar title={title} />
        <div className="container mt-5">
          <div className="row justify-content-center">
            {hasAccess ? (
              <div className="col-md-4 ">
                <button
                  className="btn btn-outline-secondary col-md-12"
                  onClick={(e) => {
                    this.setState({ deliveryIsOpen: true });
                  }}
                >
                  {" "}
                  <i className="fa fa-plus"></i> {i18n.t("delivery_button_add")}
                </button>
              </div>
            ) : null}

            <div className="col-md-4">
              <input
                type="text"
                id="order-search-freetext"
                className="form-control"
                value={this.state.search.freeText}
                onChange={this._searchChange}
                onKeyDown={this._searchKeyDown}
                placeholder={i18n.t("common_search_freetext")}
              />
            </div>

            <div className="col-md-2">
              <button
                className="btn btn-outline-secondary"
                onClick={this._searchSubmit}
                type="submit"
              >
                {i18n.t("common_search")}
              </button>
            </div>

            <div className="col-md-2">
              <button
                className="btn btn-link"
                onClick={this._toggleAdvancedSearch}
              >
                {i18n.t("common_search_advanced")}
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <Collapse isOpen={this.state.showAdvancedSearch}>
                <div className="form-group" style={{ marginTop: "1em" }}>
                  <label>{i18n.t("common_search_articlenumber")}</label>
                  <input
                    type="text"
                    id="order-search-articlenumber"
                    value={this.state.search.articleNumber}
                    onChange={this._searchChange}
                    onKeyDown={this._searchKeyDown}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>{i18n.t("common_search_claimname")}</label>
                  <select
                    name="select"
                    id="order-search-claimname"
                    className="form-control"
                    value={this.state.search.claimName}
                    onChange={this._searchChange}
                  >
                    {this._createClaimItems()}
                  </select>
                </div>
              </Collapse>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-4">{this._renderDeliveryCount()}</div>
          </div>

          <table className="table table-striped mt-4 table-responsive-sm">
            <thead>
              <tr>{this._renderDeliveryHeaders()}</tr>
            </thead>
            <tbody>{this._renderDeliveryRows()}</tbody>
          </table>
          <ListPagination
            location={this.props.location}
            itemCount={
              this.state.deliveries ? this.state.deliveries.totalCount : 0
            }
            push={(dest) => this.props.push(dest)}
          />
        </div>
        <ModalEdit
          show={this.state.deliveryIsOpen}
          title={i18n.t("common_delivery")}
          onClose={this._closeDelivery}
        >
          {
            <EditDelivery
              that={this}
              onClose={this._closeDelivery}
              onSave={this._saveDelivery}
            />
          }
        </ModalEdit>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Delivery));
