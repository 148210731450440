import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../services/i18n';

class ModalEdit extends React.Component {
  render() {   
    if(!this.props.show) {
      return null;
    }
   
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.6)',
      padding: 50,
      zIndex: 1000
    };
    
    const modalStyle = {     
       maxWidth: '950px',
       top: 0,
       bottom: 0,
       left: 0,
       right: 0,   
    };

    return (
      <div className="backdrop" style={backdropStyle}>
        <div className="modal-dialog modal-dialog-lg modal-dialog-scrollable" style={modalStyle}>
          <div className="modal-content">            
            <div className="modal-header">
              <h4 className="modal-title">{this.props.title?this.props.title:i18n.t("common_confirm")}</h4>
              <button type="button" className="close" onClick={this.props.onClose}>&times;</button>
            </div>            
            <div className="modal-body">
                {this.props.children}
            </div>                     
          </div>
        </div>
      </div>     
    );
  }
}

ModalEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default ModalEdit;