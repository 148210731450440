import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import i18n from '../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

class SidebarMenuBasic extends React.Component {  
    render() {
        if (this.props.session.guid) {
            return (               
                    <div className="border-right" id="sidebar-wrapper">
                        <div className="sidebar-heading"> 
                            <Link className="sidebar-logo-link" to="/"><img className="sidebar-logo" src={require("../images/woody_bygghandel_white.png")} alt="woody logo"/></Link>
                        </div>
                        <div className="list-group list-group-flush">
                            <Link to="/" className="list-group-item list-group-item-action"> {i18n.t("common_dashboard").toUpperCase()} </Link>                              
                            {/*<Link to="/deposit" className="list-group-item list-group-item-action"> {i18n.t("common_deposit").toUpperCase()} </Link>
                            <Link to="/withdrawal" className="list-group-item list-group-item-action"> {i18n.t("common_withdrawal").toUpperCase()} </Link>*/}
                            <Link to="/order" className="list-group-item list-group-item-action"> {i18n.t("common_order").toUpperCase()} </Link>
                        </div>
                    </div> 
            );
        }else{
            return null
        }
    }
};

export default connect(mapStateToProps)(SidebarMenuBasic);

