import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        user: state.user
    }
}

class UserDetails extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)                
        this.state = {     
            user: Object.assign({},this.props.user)               
        }                   
    }
    
    componentDidMount() {  
        this._isMounted = true;          
        var that = this;  
        var promises = [];                           
        if (that.props.match && typeof that.props.match.params.userid !== 'undefined'){       
            NetworkService.getUser(that.props.match.params.userid, that.props.session).then(user => { 
                if(user.partnerid){               
                    promises.push(NetworkService.getPartner(user.partnerid, that.props.session));                                                    
                }
                Promise.all(promises).then(values=>{
                    if(user.partnerid && (user.partnerid===values[0].partnerid)){
                        user.partner = values[0];  
                    }

                    if (that._isMounted) {                    
                        that.setState({
                            user: Object.assign({}, user)         
                        })
                    }
                }).catch(reason => {
                    console.log(reason);
                });
            }, function (err) {
                console.log(err);
            });
        }      
    }    

    componentWillUnmount() {
        this._isMounted = false;
    }

    _editUser(item){          
        if(item!==undefined){                                   
            this.props.push(`/user/edit/${item.userid}`);
        }
    }

    render() {              
        const title = <p><Link className="link" to='/user'> {i18n.t('common_users')}</Link> {this.state.user.givenname +' '+this.state.user.surname}</p>;
        var hasAccess =helper.checkAccess(this.props.session.scope, "USER", 1, 1)         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="container mt-5">   
                    <div className="row justify-content-center">
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editUser(this.state.user)}> <i className="fa fa-edit"></i> {i18n.t("user_button_edit")}</button>
                        </div>       
                        : null
                        }                              
                    </div> 

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="card mt-5">
                                <div className="card-header">
                                    <i className="fa fa-info-circle"></i>
                                    <span>  <strong>{i18n.t("common_information")}</strong></span>   
                                </div>
                                <div className="card-body">
                                    <label><strong>{i18n.t("user_common_givenname")}</strong></label>
                                    <p>{this.state.user.givenname || ""}</p>
                                    <label><strong>{i18n.t("user_common_surname")}</strong></label>
                                    <p>{this.state.user.surname || ""}</p>
                                    <label><strong>{i18n.t("user_common_role")}</strong></label>
                                    <p>{this.state.user.role ?  this.state.user.role.name:  ""}</p>
                                    <label><strong>{i18n.t("user_common_partner")}</strong></label>
                                    <p>{this.state.user.partner ?  this.state.user.partner.description:  ""}</p>
                                    <label><strong>{i18n.t("user_common_site")}</strong></label>
                                    {this.state.user.site && this.state.user.site.map(item => (
                                        <div key={item.siteid}>{item.name}</div>
                                    ))}
                                </div>                    
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="card mt-5">
                                <div className="card-header">
                                    <i className="fa fa-address-book"></i>
                                    <span>  <strong>{i18n.t("common_address")}</strong></span>   
                                </div>
                                <div className="card-body">
                                    <label><strong>{i18n.t("user_common_telephone")}</strong></label>
                                    <p>{this.state.user.telephone || ""}</p>
                                    <label><strong>{i18n.t("user_common_email")}</strong></label>
                                    <p>{this.state.user.email || ""}</p>                               
                                </div>                    
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps)(UserDetails);
