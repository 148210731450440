import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'

const mapStateToProps = function (state) {
    return {
        session: state.session,
        articlegroup: state.articlegroup
    }
}

class ArticleGroupDetails extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)       
        this.state = {     
            articlegroup: Object.assign({},this.props.articlegroup)
        }            
    }

    componentDidMount() {     
        this._isMounted = true;         
        var that = this; 
        if (that.props.match && typeof that.props.match.params.articlegroupid !== 'undefined'){       
            NetworkService.getArticleGroup(that.props.match.params.articlegroupid, that.props.session, function (articlegroup) {             
                if (that._isMounted) {    
                    console.log("componentDidMount: ", articlegroup)                                 
                    that.setState({articlegroup: Object.assign({}, articlegroup)})            
                }
            }, function (err) {
                console.log(err);
            });
        }   
    }

    componentWillUnmount() {
        this._isMounted = false;
    }  

    _editArticleGroup(item){          
        if(item!==undefined){                        
            this.props.push(`/articlegroup/edit/${item.articlegroupid}`);
        }
    } 

    render() {  
       
        const title = <p><Link className="link" to='/articlegroup'> {i18n.t('common_articlegroups')}</Link> {this.state.articlegroup.name}</p>;  
        var hasAccess =helper.checkAccess(this.props.session.scope, "ARTICLEGROUP", 1, 1)          
        console.log("render: ", this.state.articlegroup.certificate?.name)
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/>    
            <div className="container mt-5">   
                <div className="row justify-content-center">
                    { hasAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editArticleGroup(this.state.articlegroup)}> <i className="fa fa-edit"></i> {i18n.t("articlegroup_button_edit")}</button>
                    </div>       
                    : null
                    }                              
                </div>                      
                <div>
                    <div className="card mt-5">
                        <div className="card-header">
                            <i className="fa fa-info-circle"></i>
                            <span>  <strong>{i18n.t("common_information")}</strong></span>   
                        </div>
                        <div className="card-body">
                            <label><strong>{i18n.t("articlegroup_common_name")}</strong></label>
                            <p>{this.state.articlegroup.name || ""}</p>
                            <label><strong>{i18n.t("articlegroup_common_description")}</strong></label>
                            <p>{this.state.articlegroup.description || ""}</p>
                            <label><strong>{i18n.t("articlegroup_common_typeofwood")}</strong></label>
                            <p>{this.state.articlegroup.typeofwood || ""}</p>
                            <label><strong>{i18n.t("common_certificate")}</strong></label>
                            <p>{this.state.articlegroup.certificate?.name || ""}</p>
                        </div>                    
                    </div>
                </div>                                    
            </div>         
        </div>
        );
    }
};

export default connect(mapStateToProps)(ArticleGroupDetails);
