import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import moment from "moment";
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDeposit: (deposit) => dispatch({
            type: 'SET_DEPOSIT',
            deposit: deposit,
        }),
    };
};

class Deposit extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
        this.state = {            
            deposit: {},
            isOpen: false,
			deletedepositid:"",
			depositLoading:false,
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteDeposit = this._deleteDeposit.bind(this);         
    }

    componentDidMount() {
        this._isMounted = true;           
		var that = this;
		this.setState({depositLoading:true},function(){
			NetworkService.getDeposits(that.props.session, function (deposit) {  
				if (that._isMounted) {                   
					that.setState({deposits:deposit, depositLoading:false});
				}         
			}, function (err) {
				console.log(err);
				that.setState({depositLoading:false});
			});
		})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, depositid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletedepositid: depositid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletedepositid: null
        });        
    } 

    _deleteDeposit(){               
        var that =this;                       
        NetworkService.deleteDeposit(that.state.deletedepositid, that.props.session, function (deposit) {                         
            that.props.history.go(0);     
        }, function (err) {
            console.log(err);
        });       
    }
    
    _renderDepositRows(){        
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "DEPOSIT", 1, 1)     
        if (this.state.deposits && this.state.deposits.length > 0) {
        rows =this.state.deposits.map((item, i) => {                                                     
            return (
                <tr key={i} onClick={e => hasAccess ? this._addDeposit(item) : ""}>                    
                    <td>{item.amount}</td>                        
                    <td>{item.mixpercent}</td>        
                    <td>{item.reference}</td>    
                    <td>{item.location}</td>        
                    <td>{item.site.name}</td>        
                    <td>{item.supplier.name}</td>    
                    <td>{item.article.articlenumber}</td>  
                    <td>{item.user.givenname+' '+item.user.surname}</td>  
                    <td>{item.certificate.name}</td>  
                    <td>{moment(item.registrationdate).format('YYYY-MM-DD')}</td>    
                    <td>{(item.expirydate!==undefined || item.expirydate!==null) ? moment(item.expirydate).format('YYYY-MM-DD') : ""}</td>      
                    <td> 
                    {hasAccess ?           
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.depositid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>                  
                </tr>
            )})} else if(this.state.depositLoading){
				return <tr><td colSpan="12" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="12">{i18n.t("deposit_message_nodata")}</td></tr>
            }
            
        return rows;
    }
    _addDeposit(item){            
        if(item!==undefined){
            this.props.setDeposit({
                depositid: item.depositid,               
                amount: item.amount,
                mixpercent: item.mixpercent,
                reference: item.reference,
                location: item.location,
                siteid: item.siteid,
                supplierid: item.supplierid,
                articleid: item.articleid,
                userid: item.userid,
                certificateid: item.certificateid,
                registrationdate: item.registrationdate,
                expirydate: item.expirydate
            });
            this.props.push(`/deposit/edit/${item.depositid}`);
        }else{     
            this.props.push('/deposit/add');
        }
    }

    render() {   
        const title = i18n.t("common_deposits");          
        var hasAccess =helper.checkAccess(this.props.session.scope, "DEPOSIT", 1, 1)               
        return (
            <div id="page-content-wrapper">        
                <Navbar title={title}/>          
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addDeposit()}> <i className="fa fa-plus"></i> {i18n.t("deposit_button_add")}</button>
                        </div> 
                        : null
                        }                                    
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">                        
                        <thead>
                        <tr>
                            <th>{i18n.t("deposit_common_amount")}</th> 
                            <th>{i18n.t("deposit_common_mixpercent")}</th>
                            <th>{i18n.t("deposit_common_reference")}</th>    
                            <th>{i18n.t("deposit_common_location")}</th>
                            <th>{i18n.t("deposit_common_site")}</th>
                            <th>{i18n.t("deposit_common_supplier")}</th>
                            <th>{i18n.t("deposit_common_article")}</th>                                
                            <th>{i18n.t("deposit_common_user")}</th>
                            <th>{i18n.t("deposit_common_certificate")}</th>
                            <th>{i18n.t("deposit_common_registrationdate")}</th>
                            <th>{i18n.t("deposit_common_expirydate")}</th>   
                            <th></th>                         
                        </tr>
                        </thead>
                        <tbody>                   
                        {
                            this._renderDepositRows()                            
                        }                    
                        </tbody>                
                    </table>          
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteDeposit}>                    
                    {i18n.t("deposit_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
