import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../Navbar';
import NetworkService from '../../services/NetworkService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        supplierverification: state.supplierverification
    }
}

class EditSupplierVerification extends React.Component { 
    _isMounted = false;  
    constructor(props) {
        super(props)

        if (typeof this.props.match.params.supplierverificationid == 'undefined'){
            this.state = {     
                supplierverification:{                      
                    supplierid:"",
                    userid:"",                  
                    verificationdate: moment().toDate()
                }
            }      
        }else{
            this.state = {     
                supplierverification: Object.assign({},this.props.supplierverification)
            } 
        }  


    }
    
    componentDidMount() {   
        this._isMounted = true;      
        var that = this;         
        NetworkService.getSimpleUsersList(that.props.session, function (user) {    
            if (that._isMounted) {            
                that.setState({users:user})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleSuppliersList(that.props.session, function (supplier) { 
            if (that._isMounted) {                
                that.setState({suppliers:supplier})            
            }
        }, function (err) {
            console.log(err);
        });

        if (typeof that.props.match.params.supplierverificationid !== 'undefined'){       
            NetworkService.getSupplierVerification(that.props.match.params.supplierverificationid, that.props.session, function (supplierverification) {             
                if (that._isMounted) {                                     
                    that.setState({supplierverification: Object.assign({}, supplierverification)})            
                }
            }, function (err) {
                console.log(err);
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){                
        this.props.push('/supplierverification');
    } 

    _handleChange(type, val){        
        let supplierverification = Object.assign({},this.state.supplierverification);        
        supplierverification[type] = val;
        this.setState({
            supplierverification: supplierverification           
        });                     
    }
    
     _handleDateChange = date => {
        let supplierverification = Object.assign({},this.state.supplierverification);        
        supplierverification.verificationdate = date;        
        this.setState({
            supplierverification: supplierverification           
        });
    };

    createUserItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    createSupplierItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_supplier")}</option>);
        if(this.state.suppliers && this.state.suppliers.length>0){
            for (let i = 0; i < this.state.suppliers.length; i++) {
                items.push(<option key={i} value={this.state.suppliers[i].supplierid}>{this.state.suppliers[i].name}</option>);
            }
        }

        return items;
    }

    _saveSupplierVerification(e){       
        e.preventDefault();           
        var that =this;        
        let supplierverification = Object.assign({},this.state.supplierverification);        
        supplierverification.verificationdate = moment(supplierverification.verificationdate).format('YYYY-MM-DD');              
        
        if(supplierverification.supplierverificationid!=null || supplierverification.supplierverificationid!==undefined){
            NetworkService.updateSupplierVerification(supplierverification, that.props.session, function (supplierverification) {            
                that.props.push('/supplierverification');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createSupplierVerification(supplierverification, that.props.session, function (supplierverification) {                         
                that.props.push('/supplierverification');                       
            }, function (err) {
                console.log(err);
            });
        } 
    } 

    render() {         
        const title = i18n.t("supplierverification_title_edit");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">      
                <form onSubmit={e => this._saveSupplierVerification(e)}>                                      
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5"> 
                            
                            <div className="form-group">
                                <label>{i18n.t("supplierverification_common_user")}</label>                               
                                 <select name="select" className="form-control" value={this.state.supplierverification.userid} onChange={e => this._handleChange("userid", e.target.value)} required>
                                    {this.createUserItems()}
                                </select>                               
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplierverification_common_supplier")}</label>                               
                                 <select name="select" className="form-control" value={this.state.supplierverification.supplierid} onChange={e => this._handleChange("supplierid", e.target.value)} required>
                                    {this.createSupplierItems()}
                                </select>                               
                            </div>                           
                            <div className="form-group">
                                <label>{i18n.t("supplierverification_common_verificationdate")}</label>
                                <div>
                                    <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.supplierverification.verificationdate ? moment(this.state.supplierverification.verificationdate).toDate() : null} value={moment(this.state.supplierverification.verificationdate).toDate()}  onChange={this._handleDateChange} required/>
                                </div>
                                
                            </div>                          
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>                                
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditSupplierVerification);
