import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import DatePicker from "react-datepicker";
import moment from 'moment';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

class Stock extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.props.that.state.search.all = this.props.that.state.search.all === 'true';
    }

    componentDidMount() {
        this._isMounted = true;
    }

    _handleFromDateChange = date => {
        let search = Object.assign({}, this.props.that.state.search);
        if (date) {
            search.fromdate = moment(date).format("YYYY-MM-DD");
            search.all = null;
        } else {
            search.fromdate = null;
        }
        this.props.that.setState({
            search: search
        });
        this.setState({ search })

    };

    _handleToDateChange = date => {
        let search = Object.assign({}, this.props.that.state.search);
        if (date) {
            search.todate = moment(date).format("YYYY-MM-DD");
            search.all = false;
        } else {
            search.todate = null;   // [OV] Corrected from fromdate
        }
        this.props.that.setState({
            search: search
        });
        this.setState({ search })
    };

    _handleAllChange = event => {
        let search = Object.assign({}, this.props.that.state.search);
        search.all = event.target.checked;

        if (search.all) {
            search.fromdate = null;
            search.todate = null;
        }

        this.props.that.setState({
            search: search
        });
        this.setState({ search })
    };

    render() {
        return (
            <div>
                <div className="form-group">
                    <label>{i18n.t("stock_search_fromdate")}</label>
                    <div>
                        <DatePicker 
                            dateFormat="yyyy-MM-dd" 
                            className={"form-control"}
                            selected={
                                this.props.that.state.search.fromdate 
                                ? moment(this.props.that.state.search.fromdate).toDate() 
                                : null
                            } 
                            value={
                                this.props.that.state.search.fromdate 
                                ? moment(this.props.that.state.search.fromdate).toDate() 
                                : null
                            } 
                            onChange={this._handleFromDateChange} 
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>{i18n.t("stock_search_todate")}</label>
                    <div>
                        <DatePicker 
                            className={"form-control"}
                            dateFormat="yyyy-MM-dd" 
                            selected={
                                this.props.that.state.search.todate 
                                ? moment(this.props.that.state.search.todate).toDate() 
                                : null
                            } 
                            value={
                                this.props.that.state.search.todate 
                                ? moment(this.props.that.state.search.todate).toDate() 
                                : null
                            } 
                            onChange={this._handleToDateChange} />
                    </div>
                </div>

                <div className="form-group">
                    <input 
                        style={{ marginRight: 10 }} 
                        type="checkbox" 
                        checked={this.props.that.state.search.all} 
                        onChange={this._handleAllChange} 
                    />
                    <label>{i18n.t("stock_search_label_all")}</label>
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
