const articlegroup = (state = [], action) => {
    switch (action.type) {
        case 'SET_ARTICLEGROUP':
            return ({  
                articlegroupid: action.articlegroup.articlegroupid,          
                description: action.articlegroup.description,                        
                name: action.articlegroup.name, 
                typeofwood: action.articlegroup.typeofwood                 
            })
        default:
            return state
    }
}

export default articlegroup