import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import Navbar from "../Navbar";
import NetworkService from "../../services/NetworkService";
import Modal from "../Modal";
import helper from "../../services/helper";
import i18n from "../../services/i18n";
import moment from "moment";
import queryString from "query-string";
import ListPagination from "../ListPagination";
import AdvancedSearchArticle from "./AdvancedSearchArticle";

const mapStateToProps = function (state) {
  return {
    session: state.session,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUnitName: (unitname) =>
      dispatch({
        type: "SET_UNITNAME",
        unitname: unitname,
      }),
  };
};

let searchQuery;

class UnitName extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    searchQuery = this.props.location.search;

    this.state = {
        unitnames: [],
        deleteunitnameid: null,

    //   articles: { items: [], totalCount: 0 },
      isOpen: false,
    //   deletearticleid: "",
      unitnameLoading: false,
    //   search: {
    //     freeText: queryString.parse(searchQuery).search || "",
    //     supplier: queryString.parse(searchQuery).supplier || "",
    //     claimName: queryString.parse(searchQuery).claimname || "",
    //     partnerid: queryString.parse(searchQuery).partnerid || "",
    //   },
    //   editArticleNumber: false,
    //   selectedArticle: { partnerarticlenumber: "" },
    //   claims: [],
    //   partners: [],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this._deleteUnitName = this._deleteUnitName.bind(this);
    this._searchChange = this._searchChange.bind(this);
    this._searchSubmit = this._searchSubmit.bind(this);
    this._searchKeyDown = this._searchKeyDown.bind(this);
    // this._saveArticleNumber = this._saveArticleNumber.bind(this);
    // this._articleNumberChange = this._articleNumberChange.bind(this);
    this._fetchUnitNames = this._fetchUnitNames.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchUnitNames();
  }

  componentDidUpdate() {
    if (searchQuery !== this.props.location.search) {
      searchQuery = this.props.location.search;
      this._fetchUnitNames();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleModal(e, unitnameid) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen,
      deleteunitnameid: unitnameid,
    });
  }

  onClose() {
    this.setState({
      isOpen: !this.state.isOpen,
      deleteunitnameid: null,
    });
  }

  _fetchUnitNames() {
    var that = this;

    that.setState({ unitnameLoading: true }, function () {
      NetworkService.getUnitNames(
        that.props.session,
        function (unitnames) {
          if (that._isMounted) {
            that.setState({ unitnames: unitnames, unitnameLoading: false });
          }
        },
        function (err) {
          console.log(err,searchQuery);
          that.setState({ unitnameLoading: false });
        }
      );
    });
  }

  _deleteUnitName() {
    var that = this;
    NetworkService.deleteUnitName(
      that.state.deleteunitnameid,
      that.props.session,
      function (unitname) {
        that.props.push(0);
      },
      function (err) {
        console.log(err);
      }
    );
  }

  _renderUnitNameRows() {
    // [OV] 'hasAccess' meaning user access. Required: "1,1" = read, write, "1,0" = read.
    var hasAccess = helper.checkAccess(this.props.session.scope, "ARTICLE", 1, 1);
    let rows = [];
    if (
      this.state.unitnames &&
    //   this.state.articles.items &&
      this.state.unitnames.length > 0
    ) {
      rows = this.state.unitnames.map((unitname, i) => {
        let classes = "";

        if (hasAccess) {
          // Rows are clickable if user has write access
          classes += " tr-clickable";
        }

        if (unitname.archived) {
          classes += " table-warning";
        }

        return (
          <tr
            key={i}
            className={classes}
            onClick={
              // Clicking on a row will open the article details
              // Only works if user has write access
              (e) => (hasAccess ? this._addUnitName(unitname) : "")
            }
          >
            <td>{unitname.unitname}</td> 
            <td>{unitname.unit}</td>
            <td>
              {hasAccess ? (
                <>
                  <button
                    className="pull-right btn btn-info"
                    onClick={(e) => (hasAccess ? this._addUnitName(unitname) : "")}
                  >
                  {i18n.t("common_button_edit")}
                  </button>
                  <button
                    className="pull-right btn btn-danger"
                    onClick={(e) => this.toggleModal(e, unitname.unitnameid)}
                  >
                    {i18n.t("common_button_delete")}
                  </button>
                </>
              ) : null}
            </td>
          </tr>
        );
      });
    } else if (this.state.unitnameLoading) {
      return (
        <tr>
          <td colSpan="8" className="loading-td">
            <i className="fa fa-spin fa-spinner"></i>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colSpan="8">{i18n.t("article_message_nodata")}</td>
        </tr>
      );
    }

    return rows;
  }

  // [OV] 'handle unit names' as admin
  _handleUnitNames() {
    this.props.push("/article/unitname");
  }

  _addUnitName(item) {
    if (item !== undefined) {
      this.props.setUnitName({
        unitnameid: item.unitnameid,
        unitname: item.unitname,
        unitid: item.unitid,
      });
      // [OV] 'push' from props to this class component
      this.props.push(`/article/unitname/edit/${item.unitnameid}`);
    } else {
      this.props.push("/article/unitname/edit");
    }
  }

  _searchChange(event) {
    let search = this.state.search;
    switch (event.target.id) {
      case "order-search-freetext":
        search.freeText = event.target.value;
        break;
      case "order-search-supplier":
        search.supplier = event.target.value;
        break;
      case "order-search-claimname":
        search.claimName = event.target.value;
        break;
      case "article-search-partner":
        search.partnerid = event.target.value;
        break;
      default:
        throw new Error("No matching element id found");
    }

    this.setState({ search: search });
  }

  _searchSubmit() {
    let query = queryString.parse(searchQuery);
    query.search = this.state.search.freeText;
    query.supplier = this.state.search.supplier;
    query.claimname = this.state.search.claimName;
    query.partnerid = this.state.search.partnerid;

    query.page = 1;

    // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
    this.props.push(this.props.location.pathname + "?" + queryString.stringify(query));
  }

  _searchKeyDown(event) {
    if (event.key === "Enter") {
      this._searchSubmit();
    }
  }

  //For partners, render custom article number
  // [2023-05-31 Not used anymore]
//   _renderPartnerArticleNumber(article) {
//     if (
//       this.props.session &&
//       this.props.session.scope &&
//       this.props.session.scope.partner
//     ) {
//       //Ugly clone in order to avoid mutation
//       let selectedArticle = JSON.parse(JSON.stringify(article));
//       return (
//         <>
//           <p>
//             {article.partnerarticlenumber}{" "}
//             <a
//               href="#"
//               onClick={() => {
//                 this.setState({
//                   selectedArticle: selectedArticle,
//                   editArticleNumber: true,
//                 });
//               }}
//             >
//               <i className="fa fa-edit" />
//             </a>
//           </p>
//         </>
//       );
//     } else {
//       return null;
//     }
//   }

  //Modal for creating custom article number
//   _renderArticleNumberModal() {
//     return (
//       <input
//         type="text"
//         id="order-search-freetext"
//         className="form-control"
//         value={this.state.selectedArticle.partnerarticlenumber}
//         onChange={this._articleNumberChange}
//         placeholder={i18n.t("article_common_number")}
//       />
//     );
//   }

  //Save partner custom article number
//   _saveArticleNumber() {
//     let article = this.state.selectedArticle;
//     let partnerArticleNumber = {
//       articleid: article.articleid,
//       partnerid: this.props.session.scope.partner,
//       articlenumber: article.partnerarticlenumber,
//     };
//     NetworkService.createPartnerArticleNumber(
//       partnerArticleNumber,
//       this.props.session,
//       (value) => {
//         this.setState({ selectedArticle: {}, editArticleNumber: false });
//         this._fetchArticles();
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   }

//   _articleNumberChange(event) {
//     let selectedArticle = this.state.selectedArticle;
//     selectedArticle.partnerarticlenumber = event.target.value;
//     this.setState({ selectedArticle: selectedArticle });
//   }

  render() {
    const title = i18n.t("article_common_unit_name");
    // [OV] 'hasAccess' meaning write access required to manipulate articles
    var hasAccess = helper.checkAccess(this.props.session.scope, "ARTICLE", 1, 1);

    return (
      <div id="page-content-wrapper">
        <Navbar title={title} />
        <div className="container mt-5">
          <div className="col">
            {// Only show 'add article' button if user has write access or is logged in as a partner
              hasAccess || (this.props.session &&
                this.props.session.scope &&
                this.props.session.scope.partner
              ) ? (
                <div className="col-md-12 ">
                  <button
                    className="btn btn-outline-secondary col-md-4"
                    onClick={
                      // Add unit name as admin
                      (e) => this._addUnitName()
                    }
                  >
                    {" "}
                    <i className="fa fa-plus"></i> {i18n.t("unit_button_add")}
                  </button>
                  
                </div>
              ) : ""
            }

            
          </div>          
          <table className="table table-striped mt-4 table-responsive-sm">
            <thead>
              <tr>
                <th>{i18n.t("article_common_unit_name")}</th>
                <th>{i18n.t("article_common_unit")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this._renderUnitNameRows()}</tbody>
          </table>

        </div>
        <Modal
            show={this.state.isOpen}
            onClose={this.onClose}
            onOk={this._deleteUnitName}
          >
          {i18n.t("unit_name_message_delete")}
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitName);
