import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import NetworkService from '../../services/NetworkService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        supplierverification: state.supplierverification
    }
}

class AddSupplierVerification extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        if (this.props.item) {
            this.state = {
                supplierverification: Object.assign({}, this.props.item)
            }
        } else {
            this.state = {
                supplierverification: {
                    supplierid: "",
                    userid: "",
                    verificationdate: moment().toDate(),
                    expirydate: moment().toDate()
                }
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var that = this;
        NetworkService.getSimpleUsersList(that.props.session, function (user) {
            if (that._isMounted) {
                that.setState({ users: user })
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleSuppliersList(that.props.session, function (supplier) {
            if (that._isMounted) {
                that.setState({ suppliers: supplier })
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _handleChange(type, val) {
        let supplierverification = Object.assign({}, this.state.supplierverification);
        supplierverification[type] = val;
        this.setState({
            supplierverification: supplierverification
        });
    }

    _handleDateChange = date => {
        let supplierverification = Object.assign({}, this.state.supplierverification);
        supplierverification.verificationdate = date;
        this.setState({
            supplierverification: supplierverification
        });
    };

    _handleExpiryDateChange = date => {
        let supplierverification = Object.assign({}, this.state.supplierverification);
        supplierverification.expirydate = date;
        this.setState({
            supplierverification: supplierverification
        });
    };

    createUserItems() {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if (this.state.users && this.state.users.length > 0) {
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname + ' ' + this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    _saveSupplierVerification(e) {
        e.preventDefault();
        var that = this;
        let supplierverification = Object.assign({}, this.state.supplierverification);
        supplierverification.verificationdate = moment(supplierverification.verificationdate).format('YYYY-MM-DD');
        supplierverification.expirydate = moment(supplierverification.expirydate).format('YYYY-MM-DD');
        if (supplierverification.supplierverificationid != null || supplierverification.supplierverificationid !== undefined) {
            NetworkService.updateSupplierVerification(supplierverification, that.props.session, function (supplierverification) {
                that.props.onSave();
            }, function (err) {
                console.log(err);
            });

        } else {
            supplierverification.supplierid = this.props.that.state.supplier.supplierid;
            NetworkService.createSupplierVerification(supplierverification, that.props.session, function (supplierverification) {
                that.props.onSave();
            }, function (err) {
                console.log(err);
            });
        }
    }

    render() {
        return (
            <div id="page-content-modal-wrapper">
                <div className="panel panel-default">
                    <form onSubmit={e => this._saveSupplierVerification(e)}>
                        <div className="panel-body">
                            <fieldset className="col-md-6 mt-4">

                                <div className="form-group">
                                    <label>{i18n.t("supplierverification_common_user")}</label>
                                    <select name="select" className="form-control" value={this.state.supplierverification.userid} onChange={e => this._handleChange("userid", e.target.value)} required>
                                        {this.createUserItems()}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("supplierverification_common_verificationdate")}</label>
                                    <div>
                                        <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.supplierverification.verificationdate ? moment(this.state.supplierverification.verificationdate).toDate() : null} value={moment(this.state.supplierverification.verificationdate).toDate()} onChange={this._handleDateChange} required />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("supplierverification_common_expirydate")}</label>
                                    <div>
                                        <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.supplierverification.expirydate ? moment(this.state.supplierverification.expirydate).toDate() : null} value={moment(this.state.supplierverification.expirydate).toDate()} onChange={this._handleExpiryDateChange} required />
                                    </div>
                                </div>                                
                            </fieldset>
                        </div>
                        <div className="modal-footer mt-5">
                            <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                            <button className="btn btn-danger" type="button" onClick={this.props.onClose}>{i18n.t("common_button_cancel")}</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps)(AddSupplierVerification);
