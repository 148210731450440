import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
// import { ConnectedRouter } from 'connected-react-router';        // [OV] Eliminated, doesn't work with React 18
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from './components/login';
import DashBoard from './components/dashboard';
import User from './components/user';
import Partner from './components/partner';
import Article from './components/article';
import UnitName from './components/article/UnitName'; 
import EditUnitName from './components/article/EditUnitName';
import ArticleGroup from './components/articlegroup';
import Site from './components/site';
import Supplier from './components/supplier';
import SupplierVerification from './components/supplierverification';
import SidebarMenu from './components/SidebarMenu';
import SidebarMenuStandard from './components/SidebarMenuStandard';
import SidebarMenuBasic from './components/SidebarMenuBasic';
import Deposit from './components/deposit';
import Withdrawal from './components/withdrawal';
import Certificate from './components/certificate';
import EditArticleGroup from './components/articlegroup/EditArticleGroup'
import EditPartner from './components/partner/EditPartner'
import EditSite from './components/site/EditSite'
import EditUser from './components/user/EditUser'
import EditArticle from './components/article/EditArticle'
import EditSupplier from './components/supplier/EditSupplier'
import EditSupplierVerification from './components/supplierverification/EditSupplierVerification'
import EditCertificate from './components/certificate/EditCertificate'
import EditWithdrawal from './components/withdrawal/EditWithdrawal'
import EditDeposit from './components/deposit/EditDeposit'
import ArticleGroupDetails from './components/articlegroup/ArticleGroupDetails'
import ArticleDetails from './components/article/ArticleDetails'
import SiteDetails from './components/site/SiteDetails'
import CertificateDetails from './components/certificate/CertificateDetails'
import SupplierDetails from './components/supplier/SupplierDetails'
import PartnerDetails from './components/partner/PartnerDetails'
import UserDetails from './components/user/UserDetails'
import OrderDetails from './components/order/OrderDetails'
import Stock from './components/stock'
import ImportFile from './components/importFile'
import Order from './components/order'
import ImportOrderFile from './components/order/ImportOrderFile';   // [OV]
import EditOrder from './components/order/EditOrder'
import { connect, Provider } from 'react-redux'
import AuthenticatedComponent from './middleware/AuthMiddleware';

import Delivery from './components/delivery';
import DeliveryDetails from './components/delivery/DeliveryDetails'
import Blocklist from './components/blocklist';

import './App.css';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        user: state.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSession: (session) => dispatch({
            type: 'SET_SESSION',
            session: session,
        }),
        setUser: (user) => dispatch({
            type: 'SET_USER',
            user: user,
        }),

    };
};

class App extends Component {

    render() {
        return (
            <div className="d-flex" id="wrapper">
                <Provider store={this.props.store}>
                    {/* [OV] Switched from 'ConnectedRouter' to 'Router' to 
                    eliminiate 'connected-react-router' that doesn't 
                    work with React 18 */}
                    <BrowserRouter location={this.props.history.location} navigator={this.props.history}>
                        {
                            (this.props.session.scope !== undefined)
                                && this.props.session.scope.role === "Administrator"
                                ? <SidebarMenu />
                                : ((this.props.session.scope !== undefined)
                                    && this.props.session.scope.role === "Standard"
                                        ? <SidebarMenuStandard />
                                        : <SidebarMenuBasic />)
                        }
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={<AuthenticatedComponent component={<DashBoard />} resource="PARTNER" read="0" write="0" />} />
                            <Route path="/article" element={<AuthenticatedComponent component={<Article />} resource="ARTICLE" read="1" write="0" />} />
                            <Route path="/article/unitname" element={<AuthenticatedComponent component={<UnitName />} resource="ARTICLE" read="1" write="0" />} />
                            <Route path="/article/unitname/edit/:unitnameid?" element={<AuthenticatedComponent component={<EditUnitName />} resource="ARTICLE" read="1" write="1" />} />
                            <Route path="/partner" element={<AuthenticatedComponent component={<Partner />} resource="PARTNER" read="1" write="0" />} />
                            <Route path="/blocklist" element={<AuthenticatedComponent component={<Blocklist />} resource="BLOCKLIST" read="1" write="0" />} />
                            <Route path="/user" element={<AuthenticatedComponent component={<User />} resource="USER" read="1" write="0" />} />
                            <Route path="/articlegroup" element={<AuthenticatedComponent component={<ArticleGroup />} resource="ARTICLEGROUP" read="1" write="0" />} />
                            <Route path="/site" element={<AuthenticatedComponent component={<Site />} resource="SITE" read="1" write="0" />} />
                            <Route path="/supplier" element={<AuthenticatedComponent component={<Supplier />} resource="SUPPLIER" read="1" write="0" />} />
                            <Route path="/supplierverification" element={<AuthenticatedComponent component={<SupplierVerification />} resource="SUPPLIERVERIFICATION" read="1" write="0" />} />
                            <Route path="/deposit" element={<AuthenticatedComponent component={<Deposit />} resource="DEPOSIT" read="1" write="0" />} />
                            <Route path="/withdrawal" element={<AuthenticatedComponent component={<Withdrawal />} resource="WITHDRAWAL" read="1" write="0" />} />
                            <Route path="/certificate" element={<AuthenticatedComponent component={<Certificate />} resource="CERTIFICATE" read="1" write="0" />} />
                            <Route path="/order" element={<AuthenticatedComponent component={<Order />} resource="ORDER" read="1" write="0" />} />
                            <Route path="/delivery" element={<AuthenticatedComponent component={<Delivery />} resource="DELIVERY" read="1" write="0" />} />
                            <Route path="/articlegroup/add" element={<AuthenticatedComponent component={<EditArticleGroup />} resource="ARTICLEGROUP" read="1" write="1" />} />
                            <Route path="/partner/add" element={<AuthenticatedComponent component={<EditPartner />} resource="PARTNER" read="1" write="1" />} />
                            <Route path="/site/add" element={<AuthenticatedComponent component={<EditSite />} resource="SITE" read="1" write="1" />} />
                            <Route path="/user/add" element={<AuthenticatedComponent component={<EditUser />} resource="USER" read="1" write="1" />} />
                            <Route path="/article/add" element={<AuthenticatedComponent component={<EditArticle />} resource="ARTICLE" read="1" write="0" />} />
                            <Route path="/supplier/add" element={<AuthenticatedComponent component={<EditSupplier />} resource="SUPPLIER" read="1" write="1" />} />
                            <Route path="/supplierverification/add" element={<AuthenticatedComponent component={<EditSupplierVerification />} resource="SUPPLIERVERIFICATION" read="1" write="1" />} />
                            <Route path="/certificate/add" element={<AuthenticatedComponent component={<EditCertificate />} resource="CERTIFICATE" read="1" write="1" />} />
                            <Route path="/withdrawal/add" element={<AuthenticatedComponent component={<EditWithdrawal />} resource="WITHDRAWAL" read="1" write="1" />} />
                            <Route path="/deposit/add" element={<AuthenticatedComponent component={<EditDeposit />} resource="DEPOSIT" read="1" write="1" />} />
                            <Route path="/order/add" element={<AuthenticatedComponent component={<EditOrder />} resource="ORDER" read="1" write="1" />} />
                            <Route path="/articlegroup/detail/:articlegroupid?" element={<AuthenticatedComponent component={<ArticleGroupDetails />} resource="ARTICLEGROUP" read="1" write="0" />} />
                            <Route path="/article/detail/:articleid?" element={<AuthenticatedComponent component={<ArticleDetails />} resource="ARTICLE" read="1" write="1" />} />
                            <Route path="/site/detail/:siteid?" element={<AuthenticatedComponent component={<SiteDetails />} resource="SITE" read="1" write="1" />} />
                            <Route path="/certificate/detail/:certificateid?" element={<AuthenticatedComponent component={<CertificateDetails />} resource="CERTIFICATE" read="1" write="1" />} />
                            <Route path="/supplier/detail/:supplierid?" element={<AuthenticatedComponent component={<SupplierDetails />} resource="SUPPLIER" read="1" write="0" />} />
                            <Route path="/partner/detail/:partnerid?" element={<AuthenticatedComponent component={<PartnerDetails />} resource="PARTNER" read="1" write="1" />} />
                            <Route path="/user/detail/:userid?" element={<AuthenticatedComponent component={<UserDetails />} resource="USER" read="1" write="1" />} />
                            <Route path="/order/detail/:orderid?" element={<AuthenticatedComponent component={<OrderDetails />} resource="ORDER" read="1" write="1" />} />
                            <Route path="/delivery/detail/:deliveryid?" element={<AuthenticatedComponent component={<DeliveryDetails />} resource="DELIVERY" read="1" write="1" />} />
                            <Route path="/articlegroup/edit/:articlegroupid?" element={<AuthenticatedComponent component={<EditArticleGroup />} resource="ARTICLEGROUP" read="1" write="1" />} />
                            <Route path="/partner/edit/:partnerid?" element={<AuthenticatedComponent component={<EditPartner />} resource="PARTNER" read="1" write="1" />} />
                            <Route path="/site/edit/:siteid?" element={<AuthenticatedComponent component={<EditSite />} resource="SITE" read="1" write="1" />} />
                            <Route path="/user/edit/:userid?" element={<AuthenticatedComponent component={<EditUser />} resource="USER" read="1" write="1" />} />
                            <Route path="/article/edit/:articleid?" element={<AuthenticatedComponent component={<EditArticle />} resource="ARTICLE" read="1" write="1" />} />
                            <Route path="/supplier/edit/:supplierid?" element={<AuthenticatedComponent component={<EditSupplier />} resource="SUPPLIER" read="1" write="1" />} />
                            <Route path="/supplierverification/edit/:supplierverificationid?" element={<AuthenticatedComponent component={<EditSupplierVerification />} resource="SUPPLIERVERIFICATION" read="1" write="1" />} />
                            <Route path="/certificate/edit/:certificateid?" element={<AuthenticatedComponent component={<EditCertificate />} resource="CERTIFICATE" read="1" write="1" />} />
                            <Route path="/withdrawal/edit/:withdrawalid?" element={<AuthenticatedComponent component={<EditWithdrawal />} resource="WITHDRAWAL" read="1" write="1" />} />
                            <Route path="/deposit/edit/:depositid?" element={<AuthenticatedComponent component={<EditDeposit />} resource="DEPOSIT" read="1" write="1" />} />
                            <Route path="/stock" element={<AuthenticatedComponent component={<Stock />} resource="STOCK" read="1" write="0" />} />
                            <Route path="/importFile" element={<AuthenticatedComponent component={<ImportFile />} resource="IMPORT" read="1" write="1" />} />
                            <Route path='/importorders' element={<AuthenticatedComponent component={<ImportOrderFile />} resource="IMPORT" read="1" write="1" />} />
                            <Route path="/order/edit/:orderid?" element={<AuthenticatedComponent component={<EditOrder />} resource="ORDER" read="1" write="1" />} />
                        </Routes>
                    </BrowserRouter>
                    <ToastContainer autoClose={3000} />
                </Provider>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
