import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import Modal from '../../components/Modal';
import helper from '../../services/helper';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPartner: (partner) => dispatch({
            type: 'SET_PARTNER',
            partner: partner,
        }),
    };
};

class Partner extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {       
            partner: {},
            isOpen: false,
			deletepartnerid:"",
			partnerLoading:false,
        }   
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deletePartner = this._deletePartner.bind(this);     
    }

    componentDidMount() {  
        this._isMounted = true;            
        var that = this;    
		var promises = [];
		this.setState({partnerLoading:true},function(){		  
			NetworkService.getPartners(that.props.session, function (partner) {
				for(let item in partner){   // eslint-disable-line no-unused-vars
					if(partner[item].userid){
						promises.push(NetworkService.getUser(partner[item].userid, that.props.session));
					} else {
						promises.push(new Promise((resolve,reject)=>{resolve()}));
					}
				}
				Promise.all(promises).then(values=>{                   
					for(var i = 0; i <values.length; i++){                            
						partner[i].user = values[i];
					}      
					if (that._isMounted) {                  
						that.setState({
							partners: partner,
							partnerLoading:false,
						})
					}
				}).catch(reason => {
					console.log(reason);
					that.setState({partnerLoading:false});
				});
			}, function (err) {
				console.log(err);
				that.setState({partnerLoading:false});
			});
		}) 
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, partnerid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletepartnerid: partnerid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletepartnerid: null
        });        
    } 

    _deletePartner(){               
        var that =this;                       
        NetworkService.deletePartner(that.state.deletepartnerid, that.props.session, function (partner) {                         
            that.props.push(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _renderPartnerRows(){        
        let rows = [];           
        var hasAccess =helper.checkAccess(this.props.session.scope, "PARTNER", 1, 1)         
        if (this.state.partners && this.state.partners.length > 0) {
            rows =this.state.partners.map((item, i) => {                                
            return (
                <tr key={i} className={hasAccess ?"tr-clickable":""} onClick={e => hasAccess ? this._addPartner(item) : ""}>                    
                    <td>{item.description}</td>
                    <td>{item.corporateid}</td>                    
                    <td>{item.user!==undefined ? (item.user.givenname +' '+item.user.surname) : ""}</td>   
                    {/* [OV] Removed Delete button
                    <td>  
                    {hasAccess ?               
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.partnerid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>                                       */}
                </tr>
            )})} else if(this.state.partnerLoading){
				return <tr><td colSpan="4" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="4">{i18n.t("partner_message_nodata")}</td></tr>
            }

        return rows;
    }

    _addPartner(item){
        if(item!==undefined){            
            this.props.setPartner({
                partnerid: item.partnerid,               
                description: item.description,              
                corporateid: item.corporateid,   
                userid: item.userid                               
            });
            this.props.push(`/partner/detail/${item.partnerid}`);
        }else{            
            this.props.push('/partner/add');
        }
    }

    render() {  
        const title = i18n.t("common_partners");                    
        var hasAccess =helper.checkAccess(this.props.session.scope, "SITE", 1, 1)         
        return (
            <div id="page-content-wrapper">        
                <Navbar title={title}/>        
                <div className="container mt-5">                      
                    <div className="row justify-content-center">
                        
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addPartner()}> <i className="fa fa-plus"></i> {i18n.t("partner_button_add")}</button>
                        </div>       
                        : null
                        }                              
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">                        
                        <thead>
                        <tr>
                            <th>{i18n.t("partner_common_description")}</th>
                            <th>{i18n.t("partner_common_corporateid")}</th>
                            <th>{i18n.t("partner_common_user")}</th>
                            
                        </tr>
                        </thead>
                        <tbody>                   
                        {
                        this._renderPartnerRows()                            
                        }                    
                        </tbody>                
                    </table>
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deletePartner}>                    
                    {i18n.t("partner_message_delete")}
                </Modal>
            </div> 
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
