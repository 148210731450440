import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        withdrawal: state.withdrawal
    }
}

class EditWithdrawal extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
         if (typeof this.props.match.params.withdrawalid == 'undefined'){
                this.state = {     
                    withdrawal:{  
                        withdrawaltype:"",
                        amount:"",
                        reference:"",
                        userid:"",
                        depositid:"",
                        certificateid:"",
                        registrationdate: moment().toDate()
                    }
                }      
            }else{
                this.state = {     
                    withdrawal: Object.assign({},this.props.withdrawal)
                } 
            }               
    }
    
    componentDidMount() {  
        this._isMounted = true;            
        var that = this;         
        NetworkService.getSimpleUsersList(that.props.session, function (user) {  
            if (that._isMounted) {              
                that.setState({users:user})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleDepositsList(that.props.session, function (deposit) {                
            if (that._isMounted) { 
                that.setState({deposits:deposit})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleCertificatesList(that.props.session, function (certificate) {  
            if (that._isMounted) {               
               that.setState({certificates:certificate})            
            }
        }, function (err) {
            console.log(err);
        });

        if (typeof this.props.match.params.withdrawalid !== 'undefined'){       
            NetworkService.getWithdrawal(this.props.match.params.withdrawalid, that.props.session, function (withdrawal) {             
                if (that._isMounted) {                                     
                    that.setState({withdrawal: Object.assign({}, withdrawal)})            
                }
            }, function (err) {
                console.log(err);
            });
        }
    }  
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){                
        this.props.push('/withdrawal');
    } 

    _handleChange(type, val){               
        let withdrawal = Object.assign({},this.state.withdrawal);         
        withdrawal[type] = val;
        this.setState({withdrawal})                         
    }
    
     _handleDateChange = date => {
        let withdrawal = Object.assign({},this.state.withdrawal);         
        withdrawal.registrationdate = date;        
         this.setState({
            withdrawal: withdrawal           
        });
    };

    createUserItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    createCertificateItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_certificate")}</option>);
        if(this.state.certificates && this.state.certificates.length>0){
            for (let i = 0; i < this.state.certificates.length; i++) {
                items.push(<option key={i} value={this.state.certificates[i].certificateid}>{this.state.certificates[i].name}</option>);
            }
        }

        return items;
    }

    _saveWithdrawal(e){    
        e.preventDefault();           
        var that =this;         
        let withdrawal = Object.assign({},that.state.withdrawal);       
        withdrawal.registrationdate = moment(withdrawal.registrationdate).format('YYYY-MM-DD');    

        if(withdrawal.withdrawalid!=null || withdrawal.withdrawalid!==undefined){
            NetworkService.updateWithdrawal(withdrawal, that.props.session, function (withdrawal) {            
                that.props.push('/withdrawal');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createWithdrawal(withdrawal, that.props.session, function (withdrawal) {                             
                that.props.push('/withdrawal');                       
            }, function (err) {
                console.log(err);
            });
        }                  
       
    } 

    render() {           
        const title = i18n.t("withdrawal_title_edit");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">    
                <form onSubmit={e => this._saveWithdrawal(e)}>                               
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">                           
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_type")}</label>                                        
                                <select required className="form-control" value={this.state.withdrawal.withdrawaltype || ""} onChange={e => this._handleChange("withdrawaltype", e.target.value)}>
                                    <option value="">{i18n.t("common_select_withdrawaltype")}</option>
                                    <option value="sale">Sale</option>
                                    <option value="deposittalking">Deposittalking</option>   
                                    <option value="expiry">Expiry</option>                                                           
                                </select>
                            </div>    
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_amount")}</label>
                                <input required className="form-control" type="number" value={this.state.withdrawal.amount || ""} onChange={e => this._handleChange("amount", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_reference")}</label>
                                <input className="form-control" type="text" value={this.state.withdrawal.reference || ""} onChange={e => this._handleChange("reference", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_user")}</label>                               
                                 <select required name="select" className="form-control" value={this.state.withdrawal.userid  || ""} onChange={e => this._handleChange("userid", e.target.value)} >
                                    {this.createUserItems()}
                                </select>                               
                            </div>                        
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_certificate")}</label>                               
                                 <select name="select" className="form-control" value={this.state.withdrawal.certificateid ||''} onChange={e => this._handleChange("certificateid", e.target.value)} >
                                    {this.createCertificateItems()}
                                </select>                               
                            </div>                       
                            <div className="form-group">
                                <label>{i18n.t("withdrawal_common_registrationdate")}</label>
                                <div>
                                    <DatePicker required className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.withdrawal.registrationdate ? moment(this.state.withdrawal.registrationdate).toDate() : null} value={moment(this.state.withdrawal.registrationdate).toDate()}  onChange={this._handleDateChange} />
                                </div>                                
                            </div>                          
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div> 
                </form>                               
            </div>            
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditWithdrawal);
