import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'
import AddClaim from '../../components/claim/AddClaim'
import ModalEdit from '../../components/ModalEdit';
import Modal from '../../components/Modal';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        certificate: state.certificate
    }
}

class CertificateDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            certificate: Object.assign({},this.props.certificate),
            isModalOpen: false,
            deleteclaimid:""
        }
        this._closeClaim = this._closeClaim.bind(this);
        this._saveClaim = this._saveClaim.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
		this._deleteClaim = this._deleteClaim.bind(this);
		this._fetchData = this._fetchData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.match && typeof this.props.match.params.certificateid !== 'undefined'){
            this._fetchData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
	}

	_fetchData(){
		var that = this;
		NetworkService.getCertificate(that.props.match.params.certificateid, that.props.session, function (certificate) {
			if (that._isMounted) {
				that.setState({certificate: Object.assign({}, certificate)})
			}
		}, function (err) {
			console.log(err);
		});
		NetworkService.getClaimsByCertificate(that.props.match.params.certificateid, that.props.session, function (claim) {
			if (that._isMounted) {
				that.setState({claims:claim})
			}
		}, function (err) {
			console.log("error ",err);
		});
	}

    _editCertificate(item){
        if(item!==undefined){
            this.props.push(`/certificate/edit/${item.certificateid}`);
        }
    }

    _closeClaim() {
        this.setState({ claimIsOpen: false });
    }

    _saveClaim() {
        this.setState({ claimIsOpen: false },function(){
			this._fetchData();
		});
    }

    toggleModal(e, claimid){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            deleteclaimid: claimid
        });
    }

    onModalClose(){
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            deleteclaimid: null
        });
    }

    _renderClaimRows(){
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "CLAIM", 1, 1)
        if (this.state.claims && this.state.claims.length > 0) {
        rows =this.state.claims.map((item, i) => {
            return (
                <tr key={i} onClick={e => hasAccess ?  this.setState({ claimIsOpen: true, claimitem: item }) : ""}>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td>{item.transfer? "Yes" : "No"}</td>
                    <td>
                    {hasAccess ?
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.claimid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>
                </tr>
            )})}
            else{
                return <tr><td colSpan="3">{i18n.t("claim_message_nodata")}</td></tr>
            }

        return rows;
    }

    _deleteClaim(){
		var that =this;
		this.setState({ isModalOpen: false },function(){
			NetworkService.deleteClaim(that.state.deleteclaimid, that.props.session, function (claim) {
				that._fetchData();
			}, function (err) {
				console.log(err);
			});
		});
    }

    render() {
        const title = <p><Link className="link" to='/certificate'> {i18n.t('common_certificates')}</Link> {this.state.certificate.name}</p>;
        var hasAccess =helper.checkAccess(this.props.session.scope, "CERTIFICATE", 1, 1);
        var hasClaimAccess =helper.checkAccess(this.props.session.scope, "CLAIM", 1, 1);
        return (
            <div id="page-content-wrapper">
            <Navbar title={title}/>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    { hasAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editCertificate(this.state.certificate)}> <i className="fa fa-edit"></i> {i18n.t("certificate_button_edit")}</button>
                    </div>
                    : null
                    }
                    { hasClaimAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => { this.setState({ claimIsOpen: true, claimitem: null  }) }}> <i className="fa fa-plus"></i> {i18n.t("certificate_button_add_claim")}</button>
                    </div>
                    : null
                    }
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 ">
                        <nav className="tabs">
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{i18n.t("common_information")}</a>
                                <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{i18n.t("common_claim")}</a>
                            </div>
                        </nav>
                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="card mt-5">
                                    <div className="card-header">
                                        <i className="fa fa-info-circle"></i>
                                        <span>  <strong>{i18n.t("common_information")}</strong></span>
                                    </div>
                                    <div className="card-body">
                                        <label><strong>{i18n.t("certificate_common_name")}</strong></label>
                                        <p>{this.state.certificate.name || ""}</p>
                                        <label><strong>{i18n.t("certificate_common_description")}</strong></label>
                                        <p>{this.state.certificate.description || ""}</p>
                                        <label><strong>{i18n.t("certificate_common_expirymonths")}</strong></label>
                                        <p>{(this.state.certificate.expirymonths!==null || this.state.certificate.expirymonths!==undefined) ? this.state.certificate.expirymonths : ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <table className="table table-striped mt-4 table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>{i18n.t("claim_common_name")}</th>
                                        <th>{i18n.t("claim_common_code")}</th>
                                        <th>{i18n.t("claim_common_transfer")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{this._renderClaimRows()}</tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalEdit show={this.state.claimIsOpen}
                title={i18n.t("common_claim")}
                onClose={this._closeClaim}>
                {
                    <AddClaim that={this} item={this.state.claimitem} onClose={this._closeClaim} onSave={this._saveClaim}/>
                }
            </ModalEdit>
            <Modal show={this.state.isModalOpen}
                onClose={this.onModalClose}
                onOk={this._deleteClaim}>
                {i18n.t("claim_message_delete")}
            </Modal>
        </div>
        );
    }
};

export default connect(mapStateToProps)(CertificateDetails);
