import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { routerMiddleware } from "connected-react-router";   // [OV] Eliminated, doesn't work with React 18
import "font-awesome/css/font-awesome.min.css";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
// import { createRoot } from 'react-dom/client';
import { applyMiddleware, compose, createStore } from "redux";
import App from "./App";
import "./index.css";
import createRootReducer from "./reducers";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_DEMO === "true") {
  require("./demo.css");
}

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history)
  // composeEnhancers(applyMiddleware(routerMiddleware(history)))     // [OV] Eliminated, doesn't work with React 18
);

//ReactDOM.render(<App store={store} history={history} />, document.getElementById("root"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App store={store} history={history} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Check if we are on prod server or not
//if (window.location.hostname !== 'woody.com') {
//    document.getElementsByTagName('body')[0].classList += 'demo';
//}

//Print logo, ofc
console.log(
  "%c _   _                  _____               _ (R)\n| \\ | |                / ____|             | |      _____  _____             _       _   _                  \n|  \\| | __ __      __ | (___   ___  ___  __| |      \\_   \\/__   \\  ___  ___ | |_   _| |_(_) ___  _ __  ___ \n| . ` |/ _ \\ \\ /\\ / /  \\___ \\ / _ \\/ _ \\/ _` |       / /\\/  / /\\/ / __|/ _ \\| | | | | __| |/ _ \\| '_ \\/ __|\n| |\\  |  __/\\ V  V /   ____) |  __/  __/ (_| |    /\\/ /_   / /    \\__ \\ (_) | | |_| | |_| | (_) | | | \\__ \\\n|_| \\_|\\___| \\_/\\_/   |_____/ \\___|\\___|\\__,_|    \\____/   \\/     |___/\\___/|_|\\__,_|\\__|_|\\___/|_| |_|___/\n                                     https://newseed.se/",
  "color: green; font-weight: bold;"
);
