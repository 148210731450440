import { toast } from 'react-toastify';
import i18n from './i18n';

const serverAddress = process.env.REACT_APP_SERVER_ADDRESS // "http://localhost:3001/"

const NetworkService = {
	login: function (user) {
		var promise = new Promise((resolve, reject) => {

			fetch(serverAddress + "v1/login/", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					email: user.email,
					password: user.password,
				})
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("login_failed"))
					reject(error);
				});
		});
		return promise;
	},
	handleJsonResponse: function (response) {
		var promise = new Promise((resolve, reject) => {
			response.json()
				.then(responseJson => {
					if (response.ok) {
						resolve(responseJson);
					}
					else {
						reject(responseJson);
					}
				})
				.catch(error => {
					reject(error)
				});
		});
		return promise;
	},
	handleEmptyResponse: function (response) {
		var promise = new Promise((resolve, reject) => {
			if (response.ok) {
				resolve({});
			}
			else {
				reject({});
			}
		});
		return promise;
	},
	getRoles: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/role", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleRolesList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/rolelist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createUser: function (user, session, callback, errCallback) {
		fetch(serverAddress + "v1/user/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(user)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateUser: function (user, session, callback, errCallback) {
		fetch(serverAddress + "v1/user/" + user.userid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(user)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteUser: function (userid, session, callback, errCallback) {
		fetch(serverAddress + "v1/user/" + userid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getUser: function (userid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/user/" + userid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getUsers: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/user", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleUsersList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/userlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createPartner: function (partner, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partner)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updatePartner: function (partner, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner/" + partner.partnerid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partner)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deletePartner: function (partnerid, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner/" + partnerid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getPartner: function (partnerid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/partner/" + partnerid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getPartners: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/partner", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimplePartnersList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/partnerlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createSite: function (site, session, callback, errCallback) {
		fetch(serverAddress + "v1/site/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(site)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateSite: function (site, session, callback, errCallback) {
		fetch(serverAddress + "v1/site/" + site.siteid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(site)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteSite: function (siteid, session, callback, errCallback) {
		fetch(serverAddress + "v1/site/" + siteid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getSite: function (siteid, session, callback, errCallback) {
		fetch(serverAddress + "v1/site/" + siteid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSites: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/site", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleSitesList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/sitelist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleUnitsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/unitlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getUnitNames: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/unitnameslist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getUnitNameById: function (unitnameid, session, callback, errCallback) {
		fetch(serverAddress + "v1/unitname/" + unitnameid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json",
			},
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	updateUnitName: function (unitname, session, callback, errCallback) {
		fetch(serverAddress + "v1/unitname/" + unitname.unitnameid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(unitname)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"))
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"))
				errCallback(error)
			});
	},
	saveUnitName: function (unitname, session, callback, errCallback) {
		fetch(serverAddress + "v1/unitname/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(unitname)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"))
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"))
				errCallback(error)
			});
	},
	deleteUnitName: function (unitnameid, session, callback, errCallback) {
		fetch(serverAddress + "v1/unitname/" + unitnameid, {
			method: "DELETE", 
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"))
				callback(responseJson)
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"))
				errCallback(error)
			});
	},
	createArticle: function (article, session, callback, errCallback) {
		fetch(serverAddress + "v1/article/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(article)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateArticle: function (article, session, callback, errCallback) {
		console.log("\n\nupdateArticle: " + JSON.stringify(article));
		fetch(serverAddress + "v1/article/" + article.articleid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(article)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteArticle: function (articleid, session, callback, errCallback) {
		fetch(serverAddress + "v1/article/" + articleid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getArticle: function (articleid, session, callback, errCallback) {
		fetch(serverAddress + "v1/article/" + articleid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				console.log("getArticle responseJson: " + JSON.stringify(responseJson));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getArticleByArticleGroupId: function (articlegroupid, session, callback, errCallback) {
		fetch(serverAddress + "v1/articles/" + articlegroupid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getArticles: function (query, session, callback, errCallback) {
		fetch(serverAddress + "v1/article" + query, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				console.log("getArticles: ", responseJson)
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleArticlesList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/articlelist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createArticleGroup: function (articlegroup, session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegroup/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(articlegroup)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateArticleGroup: function (articlegroup, session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegroup/" + articlegroup.articlegroupid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(articlegroup)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteArticleGroup: function (articlegroupid, session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegroup/" + articlegroupid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getArticleGroup: function (articlegroupid, session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegroup/" + articlegroupid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getArticleGroups: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegroup", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleArticleGroupsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/articlegrouplist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createSupplier: function (supplier, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplier/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplier)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateSupplier: function (supplier, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplier/" + supplier.supplierid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplier)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteSupplier: function (supplierid, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplier/" + supplierid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getSupplierPromise: function(supplierid, session) {
		return new Promise((resolve, reject) => {
			this.getSupplier(supplierid, session, resolve, reject);
		});
	},
	getSupplier: function (supplierid, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplier/" + supplierid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSuppliers: function (query, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplier" + query, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleSuppliersList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createSupplierVerification: function (supplierverification, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplierverification)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateSupplierVerification: function (supplierverification, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification/" + supplierverification.supplierverificationid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(supplierverification)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteSupplierVerification: function (supplierverificationid, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification/" + supplierverificationid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getSupplierVerification: function (supplierverificationid, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification/" + supplierverificationid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSupplierVerifications: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleSupplierVerificationsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverificationlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createDeposit: function (deposit, session, callback, errCallback) {
		fetch(serverAddress + "v1/deposit/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(deposit)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateDeposit: function (deposit, session, callback, errCallback) {
		fetch(serverAddress + "v1/deposit/" + deposit.depositid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(deposit)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteDeposit: function (depositid, session, callback, errCallback) {
		fetch(serverAddress + "v1/deposit/" + depositid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getDeposit: function (depositid, session, callback, errCallback) {
		fetch(serverAddress + "v1/deposit/" + depositid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getDeposits: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/deposit", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleDepositsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/depositlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createWithdrawal: function (withdrawal, session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawal/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(withdrawal)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateWithdrawal: function (withdrawal, session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawal/" + withdrawal.withdrawalid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(withdrawal)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteWithdrawal: function (withdrawalid, session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawal/" + withdrawalid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getWithdrawal: function (withdrawalid, session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawal/" + withdrawalid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getWithdrawals: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawal", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleWithdrawalsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/withdrawallist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createCertificate: function (certificate, session, callback, errCallback) {
		fetch(serverAddress + "v1/certificate/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(certificate)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateCertificate: function (certificate, session, callback, errCallback) {
		fetch(serverAddress + "v1/certificate/" + certificate.certificateid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(certificate)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteCertificate: function (certificateid, session, callback, errCallback) {
		fetch(serverAddress + "v1/certificate/" + certificateid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getCertificate: function (certificateid, session, callback, errCallback) {
		fetch(serverAddress + "v1/certificate/" + certificateid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getCertificates: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/certificate", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleCertificatesList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/certificatelist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSession: function (session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/session", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"session": session,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({sessionstring:session})
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getStock: function (session, query) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/stock?" + queryParams(query), {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getPdf: function (withdrawalid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/fetchpdf/" + withdrawalid, {
				method: "GET",
				headers: {
					"session": session.guid,
					'Content-Type': 'application/pdf'
				},
				responseType: 'blob'
			})
				.then(response =>
					response.blob()
				)
				.then(blob => {
					resolve(blob);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getDeliveryPdf: function (deliveryid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/deliverypdf/" + deliveryid, {
				method: "GET",
				headers: {
					"session": session.guid,
					'Content-Type': 'application/pdf'
				},
				responseType: 'blob'
			})
				.then(response =>
					response.blob()
				)
				.then(blob => {
					resolve(blob);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getClaim: function (claimid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/claim/" + claimid, {
				method: "GET",
				headers: {
					"session": session.guid,
					"Content-Type": "application/json"
				},
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				resolve(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
		return promise;
	},
	createClaim: function (claim, session, callback, errCallback) {
		fetch(serverAddress + "v1/claim/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(claim)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateClaim: function (claim, session, callback, errCallback) {
		fetch(serverAddress + "v1/claim/" + claim.claimid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(claim)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deleteClaim: function (claimid, session, callback, errCallback) {
		fetch(serverAddress + "v1/claim/" + claimid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getClaimsByCertificate: function (certificateid, session, callback, errCallback) {
		fetch(serverAddress + "v1/claims/" + certificateid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getClaimsBySupplierPromise: function(supplierid, session) {
		return new Promise((resolve, reject) => {
			this.getClaimsBySupplier(supplierid, session, resolve, reject);
		});
	},
	getClaimsBySupplier: function (supplierid, session, callback, errCallback) {
		fetch(serverAddress + "v1/claims/supplier/" + supplierid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getSimpleClaimsList: function (session, callback, errCallback) {
		fetch(serverAddress + "v1/claimlist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createDelivery: function (delivery, session, callback, errCallback) {
		fetch(serverAddress + "v1/delivery/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(delivery)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateDelivery: function (delivery, session, callback, errCallback) {
		fetch(serverAddress + "v1/delivery/" + delivery.deliveryid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(delivery)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	getOrders: function (query, session, callback, errCallback) {
		fetch(serverAddress + "v1/order" + query, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	createOrder: function (order, session, callback, errCallback) {
		fetch(serverAddress + "v1/order/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(order)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updateOrder: function (order, session, callback, errCallback) {
		fetch(serverAddress + "v1/order/" + order.orderid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(order)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	getOrder: function (orderid, session, callback, errCallback) {
		fetch(serverAddress + "v1/order/" + orderid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getOrderPdf: function (orderid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/orderpdf/" + orderid, {
				method: "GET",
				headers: {
					"session": session.guid,
					'Content-Type': 'application/pdf'
				},
				responseType: 'blob'
			})
				.then(response =>
					response.blob()
				)
				.then(blob => {
					resolve(blob);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	deleteOrder: function (orderid, session, callback, errCallback) {
		fetch(serverAddress + "v1/order/" + orderid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	getDepositCodes: function (articleid, claimid, siteid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/deposit/code/" + articleid + "/" + claimid + "/" + siteid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getDeliveries: function (query, session, callback, errCallback) {
		fetch(serverAddress + "v1/delivery" + query, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getDelivery: function (deliveryid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/delivery/" + deliveryid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getDepositsByDeliveryId: function (deliveryid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/deposits/" + deliveryid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getAvailableAmount: function (articleid, claimid, siteid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/deposit/amount/" + articleid + "/" + claimid + "/" + siteid, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getReport: function (code, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/report/" + code, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
				.then(response => {
					return NetworkService.handleJsonResponse(response)
				})
				.then(responseJson => {
					resolve(responseJson);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	completeOrderrow: function (orderid, withdrawal, session, callback, errCallback) {
		fetch(serverAddress + "v1/order/"+orderid+"/withdrawal", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(withdrawal)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	getStockReport: function (session, query) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/stock/report?" + queryParams(query), {
				method: "GET",
				headers: {
					Accept: "application/octet-stream",
					"session": session.guid,
					"Content-Type": "application/octet-stream"
				}
			})
			.then(response =>
				response.blob()
			)
			.then(blob => {
				resolve(blob);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
		return promise;
	},
	getDeliveryNumberAvailability: function (deliverynumber, siteid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/delivery/available", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({deliverynumber:deliverynumber,siteid:siteid})
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(blob => {
				resolve(blob);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
		return promise;
	},
	getOrderNumberAvailability: function (ordernumber, siteid, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/order/available", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ordernumber:ordernumber,siteid:siteid})
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(blob => {
				resolve(blob);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
		return promise;
	},
	updateOrderRow: function (orderrow, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/orderrow/" + orderrow.orderrowid, {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(orderrow)
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				resolve(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				reject(error);
			});
		});
		return promise;
	},
	getSupplierVerificationsBySupplierid: function (supplierid, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverifications/" + supplierid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	uploadCertificate: function (supplierverificationid, data, session, callback, errCallback) {
		fetch(serverAddress + "v1/supplierverification/"+supplierverificationid+"/file", {
			method: "POST",
			headers: {
				"session": session.guid
			},
			body: data
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_upload_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_upload_failed"));
				errCallback(error);
			});
	},
	downloadCertificate: function (id, session) {
		var promise = new Promise(function (resolve, reject) {
			fetch(serverAddress + "v1/downloadCertificate/" + id, {
				method: "GET",
				headers: {
					"session": session.guid,
					'Content-Type': 'application/pdf'
				},
				responseType: 'blob'
			}).then(response =>	response.blob())
				.then(blob => {
					resolve(blob);
				})
				.catch(error => {
					toast.error(i18n.t("common_get_resource_failed"));
					reject(error);
				});
		});
		return promise;
	},
	getBlocklist: function(session, callback, errCallback) {
		fetch(serverAddress + "v1/blocklist", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
		.then(response => {
			return NetworkService.handleJsonResponse(response)
		})
		.then(responseJson => {
			callback(responseJson);
		})
		.catch(error => {
			toast.error(i18n.t("common_get_resource_failed"));
			errCallback(error);
		});
	},
	deleteBlocklist: function (blocklistid, session, callback, errCallback) {
		fetch(serverAddress + "v1/blocklist/" + blocklistid, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
		.then(response => {
			return NetworkService.handleEmptyResponse(response)
		})
		.then(responseJson => {
			toast.success(i18n.t("common_delete_successful"));
			callback(responseJson);
		})
		.catch(error => {
			toast.error(i18n.t("common_delete_failed"));
			errCallback(error);
		});
	},
	uploadImport: function ( data, info, session, callback, errCallback) {
		var query = new URLSearchParams({
			replace: info.replace ? 1 : 0,
			partnerid: info.partnerid
		});

		fetch(serverAddress + "v1/importFile/file?" + query, {
			method: "post",
			headers: {
				"session": session.guid
			},
			body: data
		})
			// .then(response => response.json())
  			// .then(json => console.log(json))
			// .then(response => {
			// 	console.log("uploadImport response: ", response);
			// 	return NetworkService.handleEmptyResponse(response)
			// })
			.then(responseJson => responseJson.json())
			.then(json => {
				callback(json);
			})
			.catch(error => {

				errCallback(error);
			});
	},
	saveHelp: function(session, data) {
		return new Promise((resolve, reject) => {
			fetch(serverAddress + "v1/help", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				resolve(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
	},
	getHelpByGroup: function(session, group) {
		return new Promise((resolve, reject) => {
			fetch(serverAddress + "v1/help/" + group, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				resolve(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
	},
	getHelpRevisionsById: function(session, id) {
		return new Promise((resolve, reject) => {
			fetch(serverAddress + "v1/help/" + id + "/revisions", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"session": session.guid,
					"Content-Type": "application/json"
				}
			})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				resolve(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				reject(error);
			});
		});
	},
	getImportFiles: function(session, callback, errCallback) {
		fetch(serverAddress + "v1/importFile", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			}
		})
		.then(response => {
			return NetworkService.handleJsonResponse(response)
		})
		.then(responseJson => {
			callback(responseJson);
		})
		.catch(error => {
			toast.error(i18n.t("common_get_resource_failed"));
			errCallback(error);
		});
	},
	createPartnerArticleNumber: function (partnerArticleNumber, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_articlenumber/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partnerArticleNumber)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	updatePartnerArticleNumber: function (partnerArticleNumber, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_articlenumber/" + partnerArticleNumber.partner_x_articlenumberid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partnerArticleNumber)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deletePartnerArticleNumber: function (partnerArticleNumberId, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_articlenumber/" + partnerArticleNumberId, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed"));
				errCallback(error);
			});
	},
	createPartnerSupplierCode: function (partnerSupplierCode, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_suppliercode/", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partnerSupplierCode)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	// [OV] Function to get partner supplier codes by supplier id
	getPartnerSupplierCodesBySupplierId: function (supplierid, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_suppliercode/supplier/" + supplierid, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json",
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	getPartnerSupplierCode: function (partnerSupplierCodeId, session, callback, errCallback) {
		console.log("getPartnerSupplierCode: ", partnerSupplierCodeId);
		fetch(serverAddress + "v1/partner_x_suppliercode/" + partnerSupplierCodeId, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json",
			}
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_get_resource_failed"));
				errCallback(error);
			});
	},
	updatePartnerSupplierCode: function (partnerSupplierCode, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_suppliercode/" + partnerSupplierCode.partner_x_suppliercodeid, {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"session": session.guid,
				"Content-Type": "application/json"
			},
			body: JSON.stringify(partnerSupplierCode)
		})
			.then(response => {
				return NetworkService.handleJsonResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_save_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_save_failed"));
				errCallback(error);
			});
	},
	deletePartnerSupplierCode: function (partnerSupplierCodeId, session, callback, errCallback) {
		fetch(serverAddress + "v1/partner_x_suppliercode/" + partnerSupplierCodeId, {
			method: "DELETE",
			headers: {
				"session": session.guid
			}
		})
			.then(response => {
				return NetworkService.handleEmptyResponse(response)
			})
			.then(responseJson => {
				toast.success(i18n.t("common_delete_successful"));
				callback(responseJson);
			})
			.catch(error => {
				toast.error(i18n.t("common_delete_failed")  + partnerSupplierCodeId);
				errCallback(error);
			});
	},
}



function queryParams(params) {
	return Object.keys(params)
		.map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&');
}

export default NetworkService;
