import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        supplier: state.supplier
    }
}

class EditSupplier extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        if (this.props.match && typeof this.props.match.params.supplierid == 'undefined'){
            this.state = {
                supplier:{
                    userid:"",
                    partnerid:"",
                    name:"",
                    street:"",
                    postcode:"",
                    city:"",
                    country:"",
                    verificationinterval:"",
                    code:"",
                    //certificateid:"",
                    articlegroup:[],
                },
                articlegroupitem:""
            }
        }else{
            this.state = {
                supplier: Object.assign({},this.props.supplier)
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var that = this;

        if (that.props.match && typeof that.props.match.params.supplierid !== 'undefined'){
            NetworkService.getSupplier(that.props.match.params.supplierid, that.props.session, function (supplier) {
                if (that._isMounted) {
                    that.setState({supplier: Object.assign({}, supplier)})
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleUsersList(that.props.session, function (user) {
            if (that._isMounted) {
                that.setState({users:user})
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimplePartnersList(that.props.session, function (partner) {
            if (that._isMounted) {
                that.setState({partners:partner})
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleCertificatesList(that.props.session, function (certificate) {
            if (that._isMounted) {
                that.setState({certificates:certificate})
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleArticleGroupsList(that.props.session, function (articlegroup) {
            if (that._isMounted) {
                that.setState({articlegroups:articlegroup})
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleClaimsList(that.props.session, function (claim) {
            if (that._isMounted) {
                that.setState({claims:claim})
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){
        if(this.state.supplier.supplierid){
            this.props.push('/supplier/detail/'+this.state.supplier.supplierid);
        } else {
            this.props.push('/supplier');
        }
    }

    _handleChange(type, val){
        let supplier = Object.assign({},this.state.supplier);
        supplier[type] = val;
        this.setState({
            supplier: supplier
        });
    }

    createUserItems() {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    createPartnerItems() {
        let items = [];
        items.push(<option key="partnerlistinit" value="">{i18n.t("common_select_partner")}</option>);
        if(this.state.partners && this.state.partners.length>0){
            for (let i = 0; i < this.state.partners.length; i++) {
                items.push(<option key={i} value={this.state.partners[i].partnerid}>{this.state.partners[i].description}</option>);
            }
        }
        return items;
    }

    createClaimItems(){
        let items = [];
        items.push(<option key="claiminit" value="">{i18n.t("common_select_claim")}</option>);
        if(this.state.claims){
            var result = this.state.claims.reduce((r, a) => {
                r[a.certificateid] = [...r[a.certificateid] || [], a];
                return r;
            }, {});

            var keysArr = Object.keys(result);

            for(let j=0; j<keysArr.length; j++){
                if(this.state.certificates){
                   var certificate =this.state.certificates.filter(x => x.certificateid === parseInt(keysArr[j]));
                    var claims =result[keysArr[j]];
                    items.push(<optgroup key={"ttl_"+j} label={certificate[0].name}></optgroup>)
                    for (let i = 0; i < claims.length; i++) {
                        items.push(<option key={"citml_"+j+"_"+i} value={claims[i].claimid}>{claims[i].name}</option>);
                    }
                }
            }
        }
        return items;
    }

    createArticleGroupItems() {
        let items = [];
        items.push(<option key="articlegrouplist" value="">{i18n.t("common_select_articlegroup")}</option>);
        if(this.state.articlegroups && this.state.articlegroups.length>0){
            for (let i = 0; i < this.state.articlegroups.length; i++) {
                items.push(<option key={"art_itm_"+i} value={this.state.articlegroups[i].articlegroupid}>{this.state.articlegroups[i].name}</option>);
            }
        }
        return items;
    }

    _saveSupplier(e){
        e.preventDefault();
        var that =this;
        let supplier = Object.assign({},that.state.supplier);
        if(supplier.supplierid!=null || supplier.supplierid!==undefined){
            NetworkService.updateSupplier(supplier, that.props.session, function (supplier) {
                that.props.push('/supplier');
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createSupplier(supplier, that.props.session, function (supplier) {
                that.props.push('/supplier');
            }, function (err) {
                console.log(err);
            });
        }
    }

    _handleClaimChange(index, claimid){
        let supplier = Object.assign({},this.state.supplier);
        var artgroup = supplier.articlegroup[index];
        var found = supplier.articlegroup.some(el => (el.articlegroupid === artgroup.articlegroupid && el.claimid ===  parseInt(claimid)));
        if(!found){
            artgroup.claimid = parseInt(claimid);
            supplier.articlegroup[index] = artgroup
        }
        this.setState({
            supplier: supplier
        });
    }

    _addArticleGroup(type, val){
        let articlegroup = Object.assign({}, this.state.articlegroups.find( articlegroup => articlegroup.articlegroupid === parseInt(val)));
        this.setState({
            articlegroupitem: articlegroup
        });
    }

    _add(){
        let articlegroupitem = Object.assign({}, this.state.articlegroupitem);
        articlegroupitem.claimid=""
        let supplier = Object.assign({},this.state.supplier);
        supplier.articlegroup.push(articlegroupitem);
        this.setState({
            supplier: supplier
        });
    }

    _removeArticleGroup(type, item){
        var arr = this.state.supplier.articlegroup || [];
        for( var i = 0; i < arr.length; i++){
            if ( arr[i] === item) {
              arr.splice(i, 1);
            }
        }

        let supplier = Object.assign({},this.state.supplier);
        supplier[type] = arr;
        this.setState({
            supplier: supplier
        });
    }

    render() {
        var that = this;
        const title = i18n.t("supplier_title_edit");
        return (
            <div id="page-content-wrapper">
            <Navbar title={title}/>
            <div className="panel panel-default">
                <form onSubmit={e => this._saveSupplier(e)}>
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_name")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.name || ""} onChange={e => this._handleChange("name", e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_street")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.street || ""} onChange={e => this._handleChange("street", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_postcode")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.postcode || ""} onChange={e => this._handleChange("postcode", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_city")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.city || ""} onChange={e => this._handleChange("city", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_country")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.country || ""} onChange={e => this._handleChange("country", e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label>{i18n.t("supplier_common_code")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.code || ""} onChange={e => this._handleChange("code", e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label>{i18n.t("supplier_common_verificationinterval")}</label>
                                <input className="form-control" type="number" value={this.state.supplier.verificationinterval || ""} onChange={e => this._handleChange("verificationinterval", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_certificatenumber")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.certificatenumber || ""} onChange={e => this._handleChange("certificatenumber", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_secondarycertificatenumber")}</label>
                                <input className="form-control" type="text" value={this.state.supplier.secondarycertificatenumber || ""} onChange={e => this._handleChange("secondarycertificatenumber", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_user")}</label>
                                 <select name="select" className="form-control" value={this.state.supplier.userid || ""} onChange={e => this._handleChange("userid", e.target.value)} required>
                                    {this.createUserItems()}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_partner")}</label>
                                 <select name="select" className="form-control" value={this.state.supplier.partnerid || ""} onChange={e => this._handleChange("partnerid", e.target.value)} required>
                                    {this.createPartnerItems()}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("supplier_common_articlegroup")}</label>
                                <div className="row">
                                    <div className="col-md-9 col-sm-12">
                                        <select className="form-control" onChange={e => this._addArticleGroup("articlegroup", e.target.value)} >
                                            {this.createArticleGroupItems()}
                                        </select>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group">
                                            <button className="btn btn-primary col-md-12" type="button" onClick={e => this._add()}>{i18n.t("common_button_add")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {that.state.supplier.articlegroup && that.state.supplier.articlegroup.map((item,index) => (
                                <div className="row assign" key={"claims_"+index}>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="alert alert-info alert ng-isolate-scope alert-alert-info alert-dismissible" >{item.name}
                                            <button type="button" className="close" onClick={e => that._removeArticleGroup("articlegroup", item)} >
                                                <span aria-hidden="true">×</span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <select className="form-control" value={item.claimid || ""} onChange={e => this._handleClaimChange(index, e.target.value)} required >
                                                {this.createClaimItems()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </fieldset>
                    </div>
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditSupplier);
