// import { push } from "connected-react-router";
// import {push} from '../services/push'
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom"; 
import { withRouter } from '../services/withRouter'

const mapStateToProps = function (state) {
  return {
    session: state.session
  };
};

let parsed;

class TableHeader extends React.Component {
  componentDidMount() {
    if (this.props.location.search === null || this.props.location.search === "") {

      // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
      this.props.push(`?order=desc&orderby=registrationdate`);
      parsed = queryString.parse(`?order=desc&orderby=registrationdate`);
    }
  }

  parseSearch() {
    parsed = queryString.parse(this.props.location.search);
  }

  sort(event) {
    event.preventDefault();
    if (this.props.headerdata.order) {
      this.parseSearch();
      parsed.orderby = this.props.headerdata.column;
      if (parsed.order === "desc") {
        parsed.order = "asc";
      } else if (parsed.order === "asc") {
        parsed.order = "";
        parsed.orderby = "";
      } else {
        parsed.order = "desc";
      }

      Object.keys(parsed).forEach((key) => parsed[key] === "" && delete parsed[key]);

      // [OV] Switched 'push' from 'connected-react-router' to 'props.push'
      this.props.push(this.props.location.pathname + "?" + queryString.stringify(parsed));
    }
  }

  renderChevron() {
    let chevron;
    this.parseSearch();
    if (parsed.orderby === this.props.headerdata.column) {
      if (parsed.order === "asc") {
        chevron = (
          <i
            style={{ marginLeft: 5 }}
            className="fa fa-chevron-down"
            aria-hidden="true"
          ></i>
        );
      } else if (parsed.order === "desc") {
        chevron = (
          <i
            style={{ marginLeft: 5 }}
            className="fa fa-chevron-up"
            aria-hidden="true"
          ></i>
        );
      } else {
        chevron = null;
      }
    } else {
      chevron = null;
    }

    return chevron;
  }

  render() {
    return (
      <th>
        {this.props.headerdata.order ? (
          <button className="btn btn-link" onClick={(e) => this.sort(e)}>
            {this.props.headerdata.header}
          </button>
        ) : (
          this.props.headerdata.header
        )}
        {this.renderChevron()}
      </th>
    );
  }
}

export default connect(mapStateToProps)(withRouter(TableHeader));
