import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Modal from '../../components/Modal';
import Navbar from '../../components/Navbar';
import helper from '../../services/helper';
import i18n from '../../services/i18n';
import NetworkService from '../../services/NetworkService';
import Table from './Table';
import TableHeader from '../TableHeader';

const mapStateToProps = function (state) {
  return {
    session: state.session,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSupplier: (supplier) => dispatch({
      type: 'SET_SUPPLIER',
      supplier: supplier,
    }),
  };
};

let searchQuery;

const columns = [
  { Header: i18n.t("supplier_common_name"), accessor: "name" },
  { Header: i18n.t("supplier_common_street"), accessor: "street" },
  { Header: i18n.t("supplier_common_postcode"), accessor: "postcode" },
  { Header: i18n.t("supplier_common_city"), accessor: "city" },
  { Header: i18n.t("supplier_common_country"), accessor: "country" },
  {
    Header: i18n.t("supplier_common_verificationinterval"),
    accessor: "verificationinterval",
  },
  { Header: i18n.t("supplier_common_user"), id: "user", accessor: (d) => `${d.user.givenname} ${d.user.surname}` },
  { Header: i18n.t("supplier_common_partner"), id: "partner", accessor: (d) => `${d.partner.description}` },
  {
    Header: i18n.t("supplier_common_certificatenumber"),
    accessor: "certificatenumber",
  },
  {
    Header: i18n.t("supplier_common_secondarycertificatenumber"),
    accessor: "secondarycertificatenumber",
  },
  { Header: i18n.t("supplier_common_code"), accessor: "code" },
];

class Supplier extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    searchQuery = this.props.location.search;
    this.state = {
      supplier: {},
      isOpen: false,
      deletesupplierid: "",
      supplierLoading: false,
      selectedSupplier: {},
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this._deleteSupplier = this._deleteSupplier.bind(this);
    // [OV] Not used: this._renderSupplierHeaders = this._renderSupplierHeaders.bind(this);
    this._fetchSuppliers = this._fetchSuppliers.bind(this);
    this._saveSupplierCode = this._saveSupplierCode.bind(this);
    this._supplierCodeChange = this._supplierCodeChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchSuppliers();
  }

  componentDidUpdate() {
    if (searchQuery !== this.props.location.search) {
      searchQuery = this.props.location.search;
      this._fetchSuppliers();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _fetchSuppliers() {
    var that = this;
    this.setState({ supplierLoading: true }, function () {
      NetworkService.getSuppliers(
        searchQuery,
        that.props.session,
        function (supplier) {
          if (that._isMounted) {
            that.setState({ suppliers: supplier, supplierLoading: false });
          }
        },
        function (err) {
          console.log(err);
          that.setState({ supplierLoading: false });
        }
      );
    });
  }

  toggleModal(e, supplierid) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen,
      deletesupplierid: supplierid,
    });
  }

  onClose() {
    this.setState({
      isOpen: !this.state.isOpen,
      deletewithdrawalid: null,
    });
  }

  _deleteSupplier() {
    var that = this;
    NetworkService.deleteSupplier(
      that.state.deletesupplierid,
      that.props.session,
      function (supplier) {
        that.props.push(0);
      },
      function (err) {
        console.log(err);
      }
    );
  }

  //For partners, render custom supplier code
  _renderPartnerSupplierCode(supplier) {
    if (this.props.session && this.props.session.scope && this.props.session.scope.partner) {
        //Ugly clone in order to avoid mutation
        let selectedSupplier = JSON.parse(JSON.stringify(supplier));
        return (
            <>
                <p>{supplier.partnersuppliercode} <a href="#" onClick={()=>{this.setState({selectedSupplier:selectedSupplier, editSupplierCode:true})}}><i className="fa fa-edit"/></a></p> 
            </>
        );
    } else {
        return null;
    }
}

  _renderSupplierRows() {
    let rows = [];
    var hasAccess = helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1);
    if (this.state.suppliers && this.state.suppliers.length > 0) {
      rows = this.state.suppliers.map((item, i) => {
        return (
          <tr
            key={i}
            className={hasAccess ? "tr-clickable" : ""}
            onClick={(e) => (hasAccess ? this._addSupplier(item) : "")}
          >
            <td>{item.name}</td>
            <td>{item.street}</td>
            <td>{item.postcode}</td>
            <td>{item.city}</td>
            <td>{item.country}</td>
            <td>{item.verificationinterval}</td>
            <td>{item.user.givenname + " " + item.user.surname}</td>
            <td>{item.partner.description}</td>
            <td>{item.certificatenumber}</td>
            <td>{item.secondarycertificatenumber}</td>
            <td>{item.code}
            {this._renderPartnerSupplierCode(item)}
            </td>
            <td>
              {hasAccess ? (
                <button
                  className="pull-right btn btn-danger"
                  onClick={(e) => this.toggleModal(e, item.supplierid)}
                >
                  {i18n.t("common_button_delete")}
                </button>
              ) : null}
            </td>
          </tr>
        );
      });
    } else if (this.state.supplierLoading) {
      return (
        <tr>
          <td colSpan="10" className="loading-td">
            <i className="fa fa-spin fa-spinner"></i>
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td colSpan="10">{i18n.t("supplier_message_nodata")}</td>
        </tr>
      );
    }

    return rows;
  }

  _addSupplier(item) {
    if (item !== undefined) {
      this.props.setSupplier({
        supplierid: item.supplierid,
        userid: item.userid,
        partnerid: item.partnerid,
        name: item.name,
        street: item.street,
        postcode: item.postcode,
        city: item.city,
        country: item.country,
        verificationinterval: item.verificationinterval,
        certificatenumber: item.certificatenumber,
        secondarycertificatenumber: item.secondarycertificatenumber,
        code: item.code,
      });
      this.props.push(`/supplier/detail/${item.supplierid}`);
    } else {
      this.props.push("/supplier/add");
    }
  }

    _renderSupplierHeaders() {
    let headers = [
      {
        header: i18n.t("supplier_common_name"),
        column: "name",
        order: true,
      },
      {
        header: i18n.t("supplier_common_street"),
        column: "street",
        order: true,
      },
      {
        header: i18n.t("supplier_common_postcode"),
        column: "postcode",
        order: true,
      },
      {
        header: i18n.t("supplier_common_city"),
        column: "city",
        order: true,
      },
      {
        header: i18n.t("supplier_common_country"),
        column: "country",
        order: true,
      },
      {
        header: i18n.t("supplier_common_verificationinterval"),
        column: "verificationinterval",
        order: true,
      },
      {
        header: i18n.t("supplier_common_user"),
        column: "user",
        order: true,
      },
      {
        header: i18n.t("supplier_common_partner"),
        column: "partner",
        order: true,
      },
      {
        header: i18n.t("supplier_common_certificatenumber"),
        column: "certificatenumber",
        order: true,
      },
      {
        header: i18n.t("supplier_common_secondarycertificatenumber"),
        column: "secondarycertificatenumber",
        order: true,
      },
      {
        header: i18n.t("supplier_common_code"),
        column: "code",
        order: true,
      },
    ];

    return headers.map((header, i) => {
      return <TableHeader key={i} headerdata={header} />;
    });
    }


  //Modal for creating custom supplier code
  _renderSupplierCodeModal(){
      return(
          <input type="text" id="order-search-freetext" className="form-control" value={this.state.selectedSupplier.partnersuppliercode} onChange={this._supplierCodeChange} placeholder={i18n.t("supplier_common_code")} />
      );
  }

  //Save partner custom article number
  _saveSupplierCode(){
      let supplier = this.state.selectedSupplier;
      let partnerSupplierCode = {
          supplierid: supplier.supplierid,
          partnerid: this.props.session.scope.partner,
          suppliercode: supplier.partnersuppliercode
      };
      NetworkService.createPartnerSupplierCode(partnerSupplierCode, this.props.session, (value)=>{
          this.setState({selectedSupplier:{},editSupplierCode:false});
          this._fetchSuppliers();
      }, (err)=> {
          console.log(err);
      })
  }

  _supplierCodeChange(event) {
      let selectedSupplier = this.state.selectedSupplier;
      selectedSupplier.partnersuppliercode = event.target.value;
      this.setState({ selectedSupplier: selectedSupplier });
  } 

  render() {
    const title = i18n.t("common_suppliers");
    var hasAccess = helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1);
    return (
      <div id="page-content-wrapper">
        <Navbar title={title} />
        <div className="mt-5 mx-2">
          <div className="row justify-content-center">
            {hasAccess ? (
              <div className="col-md-4 ">
                <button
                  className="btn btn-outline-secondary col-md-12"
                  onClick={(e) => this._addSupplier()}
                >
                  {" "}
                  <i className="fa fa-plus"></i> {i18n.t("supplier_button_add")}
                </button>
              </div>
            ) : null}
          </div>

          {this.state.suppliers && this.state.suppliers.length > 0 && columns && (
            <Table
              columns={columns}
              data={this.state.suppliers}
              hasAccess={() =>
                helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1)
              }
              isPartnerUser={this.props.session && this.props.session.scope && this.props.session.scope.partner}
              onClickRow={(item) => this._addSupplier(item)}
              defaultSortBy={"name"}
              editCallback={(selectedSupplier)=>{console.log(selectedSupplier);this.setState({selectedSupplier:selectedSupplier, editSupplierCode:true})}}
            />
          )}
        </div>
        <Modal show={this.state.editSupplierCode}
            onClose={()=>{this.setState({selectedSupplier:{},editSupplierCode:false})}}
            onOk={this._saveSupplierCode}>                    
            {this._renderSupplierCodeModal()}
        </Modal>
        <Modal
          show={this.state.isOpen}
          onClose={this.onClose}
          onOk={this._deleteSupplier}
        >
          {i18n.t("supplier_message_delete")}
        </Modal>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
