const partner = (state = [], action) => {
    switch (action.type) {
        case 'SET_PARTNER':
            return ({  
                partnerid: action.partner.partnerid,                        
                description:action.partner.description,
                corporateid:action.partner.corporateid,
                userid:action.partner.userid,                
            })
        default:
            return state
    }
}

export default partner