import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        partner: state.partner
    }
}

class EditPartner extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)
       
        if (this.props.match && typeof this.props.match.params.partnerid == 'undefined'){
            this.state = {     
                partner:{   
                    description:"",
                    corporateid:"",
                    userid:""                    
                }
            }      
        }else{          
            this.state = {     
                partner: Object.assign({},this.props.partner)
            } 
        }               
    }

    componentDidMount() {  
        this._isMounted = true;      
        var that = this;      
        
        if (this.props.match && typeof that.props.match.params.partnerid !== 'undefined'){       
            NetworkService.getPartner(that.props.match.params.partnerid, that.props.session).then(partner => {
                if (that._isMounted) {                                     
                    that.setState({partner: Object.assign({}, partner)})            
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleUsersList(that.props.session, function (user) {    
            if (that._isMounted) {           
                that.setState({users:user})            
            }
        }, function (err) {
            console.log(err);
        });
    }   

    _savPartner(e){  
        e.preventDefault();                               
        var that =this;        
        let partner = Object.assign({},this.state.partner);                 
        if(partner.partnerid!=null || partner.partnerid!==undefined){
            NetworkService.updatePartner(partner, that.props.session, function (partner) {            
                that.props.push('/partner');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createPartner(partner, that.props.session, function (partner) {              
                that.props.push('/partner');                       
            }, function (err) {
                console.log(err);
            });
        }
    } 

    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){               
        if(this.state.partner.partnerid){
            this.props.push('/partner/detail/'+this.state.partner.partnerid);
        } else {
            this.props.push('/partner');
        }
    } 

    _handleChange(type, val){        
        let partner = Object.assign({},this.state.partner);                 
        partner[type] = val;    
        this.setState({
            partner: partner           
        });   
    }
    
    createSelectItems() {        
        let items = [];
        items.push(<option key="sellistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }

        return items;
    }

    render() {  
        const title = i18n.t("partner_title_edit");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default"> 
                <form onSubmit={e => this._savPartner(e)}>                                        
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">                             
                            <div className="form-group">
                                <label>{i18n.t("partner_common_description")}</label>
                                <textarea className="form-control" type="text" value={this.state.partner.description || ""} onChange={e => this._handleChange("description", e.target.value)} rows="4" required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("partner_common_corporateid")}</label>
                                <input className="form-control" type="text" value={this.state.partner.corporateid || ""} onChange={e => this._handleChange("corporateid", e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label>{i18n.t("partner_common_user")}</label>                               
                                 <select name="select" className="form-control" value={this.state.partner.userid || ""} onChange={e => this._handleChange("userid", e.target.value)} >
                                    {this.createSelectItems()}
                                </select>                               
                            </div>
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                    </form>                                
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditPartner);
