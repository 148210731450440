const supplierverification = (state = [], action) => {
    switch (action.type) {
        case 'SET_SUPPLIERVERIFICATION':
            return ({  
                supplierverificationid: action.supplierverification.supplierverificationid,                        
                supplierid: action.supplierverification.supplierid,
                userid: action.supplierverification.userid,                
                verificationdate: action.supplierverification.verificationdate,
            })
        default:
            return state
    }
}

export default supplierverification