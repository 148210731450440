import React from 'react';
import { Editor } from "@tinymce/tinymce-react";    // [OV] The TinyMCE editor (install by: $ npm install @tinymce/tinymce-react)
import { connect } from 'react-redux';
import '../../App.css';
import HelpService from '../../services/HelpService';
import ModalEdit from '../../components/ModalEdit';
import i18n from "../../services/i18n";
import NetworkService from '../../services/NetworkService';
import Moment from 'moment';
import helper from '../../services/helper';

const mapStateToProps = function (state) {   
    return {
        session: state.session
    }
}

class HelpIcon extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
        this.state = {            
            isOpen: false,
            canEdit: true,
            edit: false,
            revisions: null,
            helptext: {
                content: ''
            }
        }

        this._openHelp = this._openHelp.bind(this);
        this._closeHelp = this._closeHelp.bind(this);
        this._saveHelp = this._saveHelp.bind(this);         
    }

    componentDidMount() {
        this._isMounted = true;

        var that = this;

        // Set default styles
        var style = this.props.style || {};
        style.cursor = 'pointer';

        this.setState({
            style: style
        });


        // Set canEdit permissions
        this.setState({
            canEdit: helper.checkAccess(this.props.session.scope, "HELP", 1, 1)
        });

        // Set code & group
        let code = this.props.code;
        let group = btoa(window.location.pathname);

        // Get session
        let session = this.props.session;

        HelpService.getByGroup(session, group)
            .then(function (result) {
                let helptext = result.find(helptext => helptext.code === code);

                if (that._isMounted) {
                    that.setState({
                        helptext: helptext
                    })
                }
            })
            .catch(function (err) {
                console.log(err);
            });        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _openHelp(e) {        
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isOpen: true
        });  
    }

    _closeHelp() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    _saveHelp() {
        let data = {
            group: btoa(window.location.pathname),
            code: this.props.code,
            content: this.state.helptext.content
        };

        NetworkService.saveHelp(this.props.session, data)
            .then(() => {
                this._toggleEdit();
            })
            .catch(err => console.log(err));
    }

    _cancel() {
        this.setState({
            edit: false
        });
    }

    // [OV] This has been adapted to TinyMCE (very small changes)
    _onContentChange = (value, editor) => {
        let helptext = this.state.helptext;

        helptext.content = value;

        this.setState({
          helptext: helptext
        });
    };

    _toggleEdit(e) {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.setState({
            edit: !this.state.edit
        })
    }

    _setRevision(e, revision) {
        e.stopPropagation();
        e.preventDefault();

        this.setState({
            helptext: revision
        });
    }

    _renderRevisions() {

        if (!this.state.revisions) {
            let session = this.props.session;

            NetworkService.getHelpRevisionsById(session, this.state.helptext.helpid)
                .then(revisions => {
                    revisions = revisions
                                    .sort((a, b) => Moment(a.created).diff(Moment(b.created)))
                                    .reverse();

                    this.setState({
                        revisions: revisions
                    });
                })
                .catch(err => console.log(err));
        }

        return (
            <section>
                <label>Tidigare revisioner:</label>
                <ul className='list-group'>
                    {
                        (this.state.revisions) 
                        ?
                            this.state.revisions.map(revision => 
                                <li className={"list-group-item " + (revision.helptextid === this.state.helptext.helptextid ? 'active' : '')}
                                    style={{cursor: 'pointer'}}
                                    onClick={e => this._setRevision(e, revision)}
                                    key={revision.helptextid}>
                                    {Moment(revision.created).format('YYYY-MM-DD HH:mm')}
                                </li>
                            )
                        :
                        ''
                    }
                </ul>
            </section>
        );
    }

    _renderHelp() {
        return (
            <div>
                <div style={{position:'relative', minHeight: 50 }}>
                    {
                        // [OV] This renders helptext.content interpreting the HTML codes (richer text)
                        ((this.state.helptext) ? (<div dangerouslySetInnerHTML={{ __html: this.state.helptext.content }}></div> ): '')
                    }

                    {
                        (this.state.canEdit) ? <a className="fa fa-pencil" onClick={e => this._toggleEdit(e)} style={{ cursor: 'pointer', position: 'absolute', right: 10, top: 10}}></a> : ''
                    }                
                </div>

                <div className="modal-footer mt-5"></div>
            </div>
        );
    }    

    _renderHelpEdit() {     
        if (!this.state.helptext) {
            // Do not mutate state directly. Use setState()  
            this.state.helptext = { content: '' };
        }
        
        return (
            <div>
                {/* [OV] Editor instead of textarea (TinyMCE) */}
                <Editor 
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}     // [OV] The key is generated for free at tiny.cloud
                    className="form-control" 
                    rows="10" 
                    value={this.state.helptext.content} 
                    init={{
                        // [OV] Initial values 
                        height: 500,
                        menubar: false
                    }}
                    // [OV] Instead of the earlier arrow function: onEditorChange
                    onEditorChange={this._onContentChange}
                    />

                {
                    this.state.helptext.helpid ? this._renderRevisions() : ''
                }

                <div className="modal-footer mt-5">
                    <button className="btn btn-primary" type="button" onClick={e => this._saveHelp()}>{i18n.t("common_button_ok")}</button>
                    <button className="btn btn-danger" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                </div>
            </div>
        );
    }

    render() {   
        if (!this.state.helptext && !this.state.canEdit) {
            return (
                <span></span>
            );
        } else {
            return (
                <div style={{display: 'inline-block'}}>
                    <span className="fa fa-question-circle" style={this.state.style} onClick={e => this._openHelp(e)}></span>
                    <ModalEdit show={this.state.isOpen}
                        title={i18n.t("common_help_title")}
                        onClose={this._closeHelp}
                        onOk={this._saveHelp}>
                        {
                            (this.state.edit) ? this._renderHelpEdit() : this._renderHelp()
                        }
                    </ModalEdit>
                </div>
            );
        }
    }
};

export default connect(mapStateToProps)(HelpIcon);
