import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'

const mapStateToProps = function (state) {
    return {
        session: state.session,
        site: state.site
    }
}

class SiteDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            site: Object.assign({},this.props.site)
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var that = this;
        if (that.props.match && typeof that.props.match.params.siteid !== 'undefined'){
            NetworkService.getSite(that.props.match.params.siteid, that.props.session, function (site) {
                if (that._isMounted) {
                    that.setState({site: Object.assign({}, site)})
                }
            }, function (err) {
                console.log(err);
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _editSite(item){
        if(item!==undefined){
            this.props.push(`/site/edit/${item.siteid}`);
        }
    }

    render() {
        const title = <p><Link className="link" to='/site'> {i18n.t('common_sites')}</Link> {this.state.site.name}</p>;
        var hasAccess =helper.checkAccess(this.props.session.scope, "SITE", 1, 1)
        return (
            <div id="page-content-wrapper">
            <Navbar title={title}/>
            <div className="container mt-5">
                    <div className="row justify-content-center">
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editSite(this.state.site)}> <i className="fa fa-edit"></i> {i18n.t("site_button_edit")}</button>
                        </div>
                        : null
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="card mt-5">
                                <div className="card-header">
                                    <i className="fa fa-info-circle"></i>
                                    <span>  <strong>{i18n.t("common_information")}</strong></span>
                                </div>
                                <div className="card-body">
                                    <label><strong>{i18n.t("site_common_certificatenumber")}</strong></label>
                                    <p>{this.state.site.certificatenumber || ""}</p>
                                    <label><strong>{i18n.t("site_common_name")}</strong></label>
                                    <p>{this.state.site.name || ""}</p>
                                    <label><strong>{i18n.t("site_common_user")}</strong></label>
                                    <p>{this.state.site.user ? (this.state.site.user.givenname+' '+this.state.site.user.surname) : ""}</p>
                                    <label><strong>{i18n.t("site_common_partner")}</strong></label>
                                    <p>{this.state.site.partner ?  this.state.site.partner.description:  ""}</p>
                                    <label><strong>{i18n.t("site_common_code")}</strong></label>
                                    <p>{this.state.site.code || ""}</p>
                                </div>
                            </div>
                        </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-address-book"></i>
                                <span>  <strong>{i18n.t("common_address")}</strong></span>
                            </div>
                            <div className="card-body">
                                <label><strong>{i18n.t("site_common_street")}</strong></label>
                                <p>{this.state.site.street || ""}</p>
                                <label><strong>{i18n.t("site_common_postcode")}</strong></label>
                                <p>{this.state.site.postcode || ""}</p>
                                <label><strong>{i18n.t("site_common_city")}</strong></label>
                                <p>{this.state.site.city || ""}</p>
                                <label><strong>{i18n.t("site_common_country")}</strong></label>
                                <p>{this.state.site.country || ""}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(SiteDetails);
