const helper = {
    checkAccess: function (arr, res, read, write) {
        // console.log("arr: ", arr);
        // console.log("res: ", res);
        // console.log("read: ", read);
        // console.log("write: ", write);
        var scopeArray = arr.resources;	          
        let isExist = scopeArray.some(o => o.name === res && o.read >= read && o.write >=write);
        // console.log("isExist: ", isExist);	        
        return isExist;
    },
    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");         
        if (parts.length === 2) return parts.pop().split(";").shift();
    },
    deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    sortArray(arr, key) {
        return arr.sort((a, b) => {
            let x = a[key];
            let y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
}

export default helper;
