import { combineReducers } from 'redux'
import session from './session'
import user from './user';
import certificate from './certificate';
import withdrawal from './withdrawal';
import deposit from './deposit';
import supplierverification from "./supplierverification";
import supplier from "./supplier"
import articlegroup from "./articlegroup"
import article from "./article"
import site from "./site"
import partner from "./partner"
import order from "./order"
import delivery from "./delivery"
// import { connectRouter } from 'connected-react-router'   // [OV] Eliminated, doesn't work with React 18

const createRootReducer = (history) => combineReducers({
  session,
  user,
  certificate,
  withdrawal,
  deposit,
  supplierverification,
  supplier,
  articlegroup,
  article,
  site,
  partner,
  order,
  delivery,
  // router: connectRouter(history)   // [OV] Eliminated, doesn't work with React 18
})

export default createRootReducer
