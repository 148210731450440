const withdrawal = (state = [], action) => {
    switch (action.type) {
        case 'SET_WITHDRAWAL':
            return ({             
                withdrawalid: action.withdrawal.withdrawalid,
                withdrawaltype: action.withdrawal.withdrawaltype,
                amount: action.withdrawal.amount,
                reference: action.withdrawal.reference,
                userid: action.withdrawal.userid,
                depositid: action.withdrawal.depositid,
                claimid: action.withdrawal.claimid,
                registrationdate: action.withdrawal.registrationdate
            })
        default:
            return state
    }
}

export default withdrawal