// Since 'withRouter' is no longer a part of 'react-router-dom'
// you need this wrapper to replace it.
// Source: https://reactrouter.com/en/6.8.1/start/faq

// What happened to withRouter? I need it!
// This question usually stems from the fact that you're using
// React class components, which don't support hooks. In React
// Router v6, we fully embraced hooks and use them to share all
// the router's internal state. But that doesn't mean you can't
// use the router. Assuming you can actually use hooks (you're
// on React 16.8+), you just need a wrapper.


import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

