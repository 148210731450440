import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        site: state.site
    }
}

class EditSite extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        if (this.props.match && typeof this.props.match.params.siteid == 'undefined'){
            this.state = {
                site:{
                    partnerid:"",
                    userid:"",
                    certificatenumber:"",
                    name:"",
                    street:"",
                    postcode:"",
                    city:"",
                    country:"",
                    code:""
                }
            }
        }else{
            this.state = {
                site: Object.assign({},this.props.site)
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        var that = this;
        if (that.props.match && typeof that.props.match.params.siteid !== 'undefined'){
            NetworkService.getSite(that.props.match.params.siteid, that.props.session, function (site) {
                if (that._isMounted) {
                    that.setState({site: Object.assign({}, site)})
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleUsersList(that.props.session, function (user) {
            if (that._isMounted) {
                that.setState({users:user})
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimplePartnersList(that.props.session, function (partner) {
            if (that._isMounted) {
                that.setState({partners:partner})
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){
        if(this.state.site.siteid){
            this.props.push('/site/detail/'+this.state.site.siteid);
        } else {
            this.props.push('/site');
        }
    }

    _handleChange(type, val){
        let site = Object.assign({},this.state.site);
        site[type] = val;
        this.setState({
            site: site
        });
    }

    createUserItems() {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    createPartnerItems() {
        let items = [];
        items.push(<option key="partlistinit" value="">{i18n.t("common_select_partner")}</option>);
        if(this.state.partners && this.state.partners.length>0){
            for (let i = 0; i < this.state.partners.length; i++) {
                items.push(<option key={i} value={this.state.partners[i].partnerid}>{this.state.partners[i].description}</option>);
            }
        }
        return items;
    }

    _saveSite(e){
        e.preventDefault();
        var that =this;
        let site = Object.assign({},this.state.site);
        if(site.siteid!=null || site.siteid!==undefined){
            NetworkService.updateSite(site, that.props.session, function (site) {
                that.props.push('/site');
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createSite(site, that.props.session, function (site) {
                that.props.push('/site');
            }, function (err) {
                console.log(err);
            });
        }
    }

    render() {
        const title = i18n.t("site_title_edit");
        return (
            <div id="page-content-wrapper">
            <Navbar title={title}/>
            <div className="panel panel-default">
                <form onSubmit={e => this._saveSite(e)}>
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">
                            <div className="form-group">
                                <label>{i18n.t("site_common_certificatenumber")}</label>
                                <input className="form-control" type="text" value={this.state.site.certificatenumber || ""} onChange={e => this._handleChange("certificatenumber", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_name")}</label>
                                <input className="form-control" type="text" value={this.state.site.name || ""} onChange={e => this._handleChange("name", e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_street")}</label>
                                <input className="form-control" type="text" value={this.state.site.street || ""} onChange={e => this._handleChange("street", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_postcode")}</label>
                                <input className="form-control" type="text" value={this.state.site.postcode || ""} onChange={e => this._handleChange("postcode", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_city")}</label>
                                <input className="form-control" type="text" value={this.state.site.city || ""} onChange={e => this._handleChange("city", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_country")}</label>
                                <input className="form-control" type="text" value={this.state.site.country || ""} onChange={e => this._handleChange("country", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("site_common_code")}</label>
                                <input type="text" className="form-control" value={this.state.site.code || ""} onChange={e => this._handleChange("code", e.target.value)}  />
                            </div>

                            <div className="form-group">
                                <label>{i18n.t("site_common_partner")}</label>
                                 <select name="select" className="form-control" value={this.state.site.partnerid || ""} onChange={e => this._handleChange("partnerid", e.target.value)} required>
                                    {this.createPartnerItems()}
                                </select>
                            </div>

                            <div className="form-group">
                                <label>{i18n.t("site_common_user")}</label>
                                 <select name="select" className="form-control" value={this.state.site.userid || ""} onChange={e => this._handleChange("userid", e.target.value)} required>
                                    {this.createUserItems()}
                                </select>
                            </div>
                        </fieldset>
                    </div>
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditSite);
