import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        article: state.article
    }
}

class EditArticle extends React.Component {
    _isMounted = false;  
    constructor(props) {
        super(props) 
        this.state = {     
            article: Object.assign({},this.props.article)
        }     
    }

    componentDidMount() {   
        this._isMounted = true;              
        var that = this;                
        if (that.props.match && typeof that.props.match.params.articleid !== 'undefined'){       
            NetworkService.getArticle(that.props.match.params.articleid, that.props.session, function (article) {             
                if (that._isMounted) {                                     
                    that.setState({article: Object.assign({}, article)})            
                }
            }, function (err) {
                console.log(err);
            });
        }
    }   

    componentWillUnmount() { 
        this._isMounted = false;
    }

    _editArticle(item){          
        if(item!==undefined){                                   
            this.props.push(`/article/edit/${item.articleid}`);
        }
    } 

    render() {                         
        const title = <p><Link className="link" to='/article'> {i18n.t('common_articles')}</Link> {'>'} {this.state.article.articlenumber}</p>;  
        var hasAccess =helper.checkAccess(this.props.session.scope, "ARTICLE", 1, 1) 
        console.log("ArticleDetails unit: ", this.state.article.unit);         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="container mt-5">   
                <div className="row justify-content-center">
                    { hasAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => {
                            console.log("Button: ", this.state.article)
                            this._editArticle(this.state.article)
                        }}> 
                            <i className="fa fa-edit"></i> {i18n.t("article_button_edit")}
                        </button>
                    </div>       
                    : null
                    }                              
                </div>  
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                    <div className="card mt-5">
                        <div className="card-header">
                            <i className="fa fa-info-circle"></i>
                            <span>  <strong>{i18n.t("common_information")}</strong></span>   
                        </div>
                        <div className="card-body">
                            <label><strong>{i18n.t("article_common_number")}</strong></label>
                            <p>{this.state.article.articlenumber || ""}</p>
                            <label><strong>{i18n.t("article_common_description")}</strong></label>
                            <p>{this.state.article.articledescription || ""}</p>

                            <label><strong>{i18n.t("article_common_category")}</strong></label>
                            <p>{this.state.article.category || ""}</p>
                            <label><strong>{i18n.t("article_common_articlegroups")}</strong></label>
                            {this.state.article.articlegroups?.map((group) => {
                                    return <div key={group.certificateid}>[{group.certificatename}] {group.name}</div>
                            }) }
                        </div>                    
                    </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                    <div className="card mt-5">
                        <div className="card-header">
                            <i className="fa fa-cube"></i>
                            <span>  <strong>{i18n.t("common_article_dimension")}</strong></span>   
                        </div>
                        <div className="card-body">
                            <label><strong>{i18n.t("article_common_height")}</strong></label>
                            <p>{this.state.article.height || ""}</p>
                            <label><strong>{i18n.t("article_common_width")}</strong></label>
                            <p>{this.state.article.width || ""}</p>
                            <label><strong>{i18n.t("article_common_depth")}</strong></label>
                            <p>{this.state.article.depth || ""}</p>
                            <label><strong>{i18n.t("article_common_unit")}</strong></label>
                            <p>{this.state.article.unit?this.state.article.unit.unit:"" || ""}</p>
                        </div>                    
                    </div>
                    </div>
                </div>
                {/* If this article has a partner id render this div */}
                {this.state.article.partnerid ?
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-money"></i>
                                <span>  <strong>{i18n.t("common_partner")}</strong></span>
                            </div>
                            <div className="card-body">
                                {/* <label><strong>{i18n.t("common_partner")}</strong></label> */}
                                <p>{this.state.article.partnerid ? this.state.article.partner.description : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
                :""}
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditArticle);
