import i18next from 'i18next';
import helper from './helper';

i18next
    .init({
        interpolation: {
            escapeValue: false,
        },
        lng: helper.getCookie("language") || "sv",
        resources: {
            en: {
                translation: {
                    common_site: "Site",
                    common_partner: "Partner",
                    login_common_email: "Email",
                    login_common_password: "Password",
                    login_button_signin: "Sign in",
                    common_dashboard: "Dashboard",
                    common_user: "User",
                    common_article: "Article",
                    common_articlegroup: "Article group",
                    common_supplier: "Supplier",
                    common_supplierverification: "Supplier verification",
                    common_deposit: "Deposit",
                    common_withdrawal: "Withdrawal",
                    common_certificate: "Certificate",
                    user_message_delete: "Are you sure you want to delete this user?",
                    user_button_add: "Add user",
                    user_common_givenname: "Given name",
                    user_common_surname: "Surname",
                    user_common_telephone: "Phone",
                    user_common_email: "Email",
                    user_common_role: "Role",
                    user_common_partner: "Partner",
                    user_common_site: "Site",
                    common_button_delete: "Delete",
                    common_button_inactivate: "Deactivate",
                    user_message_nodata: "No user data found",
                    user_title_edit: "Add / Edit User",
                    common_button_ok: "OK",
                    common_button_cancel: "CANCEL",
                    partner_message_delete: "Are you sure you want to delete this partner?",
                    partner_button_add: "Add partner",
                    partner_common_description: "Description",
                    partner_common_corporateid: "Corporate id",
                    partner_common_user: "User",
                    partner_message_nodata: "No partner data found",
                    partner_title_edit: "Add / Edit Partner",
                    site_button_add: "Add site",
                    site_message_delete: "Are you sure you want to delete this site?",
                    site_message_nodata: "No site data found",
                    site_common_name: "Name",
                    site_common_partner: "Partner",
                    site_common_user: "User",
                    site_common_street: "Street",
                    site_common_postcode: "Postcode",
                    site_common_city: "City",
                    site_common_code:"Code",
                    site_common_country: "Country",
                    site_title_edit: "Add / Edit Site",
                    common_select_user: "Select an user",
                    common_select_partner: "Select a partner",
                    common_select_site: "Select a site",
                    common_select_role: "Select a role",
                    user_common_password: "Password",
                    article_message_nodata: "No articles data found",
                    article_common_number: "Article number",
                    article_common_description: "Article description",
                    article_common_category: "Category",
                    article_common_unit: "Unit",
                    common_select_unit: "Select a unit",
                    article_handle_unit_names: "Handle unit names",
                    article_common_unit_name: "Unit name",
                    article_common_width: "Width (mm)",
                    article_common_height: "Height (mm)",
                    article_common_depth: "Depth (mm)",
                    article_common_articlegroup: "Article group",
                    article_common_articlegroups: "Article groups",
                    article_button_add: "Add article",
                    unit_button_add: "Add unit name",
                    article_title_edit: "Add / Edit Article",
                    common_select_articlegroup: "Select an article group",
                    articlegroup_message_delete: "Are you sure you want to delete this article group?",
                    common_confirm: "Confirm",
                    articlegroup_button_add: "Add article group",
                    articlegroup_common_description: "Description",
                    articlegroup_common_name: "Name",
                    articlegroup_message_nodata: "No article group data found",
                    articlegroup_title_edit: "Add / Edit Article group",
                    supplier_message_nodata: "No supplier data found",
                    supplier_button_add: "Add supplier",
                    supplier_message_delete: "Are you sure you want to delete this supplier?",
                    supplier_common_name: "Name",
                    supplier_common_code: "Code",
                    supplier_common_street: "Street",
                    supplier_common_postcode: "Postcode",
                    supplier_common_city: "City",
                    supplier_common_country: "Country",
                    supplier_common_verificationinterval: "Verification interval",
                    supplier_common_partner: "Partner",
                    supplier_common_partner_supplier_codes: "Partner supplier codes",
                    supplier_common_user: "User",
                    supplier_common_certificate:"Certificate",
                    supplier_title_edit: "Add / Edit Supplier",
                    supplierverification_message_nodata: "No supplier verification data found",
                    supplierverification_message_delete: "Are you sure you want to delete this supplier verification?",
                    supplierverification_button_add: "Add supplier verification",
                    supplierverification_common_supplier: "Supplier",
                    supplierverification_common_user: "User",
                    supplierverification_common_verificationdate: "Verification date",
                    supplierverification_title_edit: "Add / Edit Supplier verification",
                    common_select_supplier: "Select a supplier",
                    deposit_button_add: "Add deposit",
                    deposit_message_delete: "Are you sure you want to delete this deposit?",
                    deposit_message_nodata: "No deposit data found",
                    deposit_common_amount: "Credit",
                    deposit_common_mixpercent: "Mix percent",
                    deposit_common_reference: "Reference",
                    deposit_common_location: "Location",
                    deposit_common_site: "Site",
                    deposit_common_supplier: "Supplier",
                    deposit_common_article: "Article",
                    deposit_common_user: "User",
                    deposit_common_certificate: "Certificate",
                    deposit_common_registrationdate: "Registration date",
                    deposit_common_expirydate: "Expiry date",
                    deposit_common_claim: "Claim",
                    common_select_certificate: "Select a certificate",
                    common_select_article: "Select an article",
                    withdrawal_message_nodata: "No withdrawal data found",
                    withdrawal_message_delete: "Are you sure you want to delete this withdrawal?",
                    withdrawal_common_type: "Withdrawal type",
                    withdrawal_common_amount: "Credit",
                    withdrawal_common_reference: "Reference",
                    withdrawal_common_user: "User",
                    withdrawal_common_deposit: "Deposit",
                    withdrawal_common_certificate: "Certificate",
                    withdrawal_common_registrationdate: "Registration date",
                    withdrawal_button_add: "Add withdrawal",
                    common_select_deposit: "Select a deposit",
                    withdrawal_title_edit: "Add / Edit withdrawal",
                    common_select_withdrawaltype: "Select a withdrawal type",
                    certificate_message_delete: "Are you sure you want to delete this certificate?",
                    certificate_message_nodata: "No Certificate data found",
                    certificate_button_add: "Add certificate",
                    common_multiple_search_article: 'Search articlenumber, name, category or articlegroup',
                    common_search: "Search",
                    common_search_advanced: "Advanced Search",
                    common_search_freetext: "Freetext",
                    common_search_articlenumber: "Article number",
                    common_search_claimname: "Claim",
                    common_search_status: "Status",
                    common_search_results_count: "Number of results:",
                    certificate_common_name: "Name",
                    certificate_common_description: "Description",
                    certificate_title_edit: "Add / Edit certificate",
                    common_stock: "Stock",
                    certificate_common_expirymonths: "Expires after months",
                    stock_common_deposit: "Deposit",
                    stock_common_withdrawal: "Withdrawal",
                    stock_common_total: "Total",
                    stock_common_date: "Date",
                    stock_common_before: "Opening balance",
                    stock_search_fromdate: "From date",
                    stock_search_todate: "To date",
                    stock_common_certificate: "Certificate",
                    stock_common_today: "Today",
                    common_reset_search: "Reset filter",
                    stock_common_after: "Closing balance",
                    stock_common_article: "Article",
                    stock_common_supplier: "Supplier",
                    stock_common_reference: "Reference",
                    common_logout: "Logout",
                    common_information: "Basic Information",
                    articlegroup_button_edit:"Edit Article group",
                    common_article_dimension:"Dimension",
                    article_button_edit:"Edit article",
                    common_address: "Address",
                    site_button_edit:"Edit site",
                    certificate_button_edit:"Edit certificate",
                    supplier_button_edit:"Edit supplier",
                    partner_button_edit:"Edit partner",
                    user_button_edit:"Edit user",
                    user_title_detail:"User details",
                    common_users:"Users",
                    common_partners:"Partners",
                    common_sites:"Sites",
                    common_articles:"Articles",
                    common_articlegroups:"Articlegroups",
                    common_suppliers:"Suppliers",
                    common_certificates:"Certificates",
                    common_supplierverifications:"Supplier verifications",
                    supplier_common_articlegroup: "Article group",
                    deposit_common_cubicmeter: "Cubic meter",
                    deposit_common_noofarticle: "Number of Articles",
                    deposit_common_calcreditby: "Calculate credit by",
                    deposit_common_article_alert:"Credits can not be calculated using this article as it does not have any dimension defined.",
                    deposit_select_direct:"Direct credit",
                    deposit_select_article:"Article amount",
                    certificate_button_add_claim:"Add claim",
                    claim_common_name: "Name",
                    claim_common_code: "Code",
                    claim_common_transfer:"Transfer",
                    common_claim: "Claim",
                    claim_message_nodata: "No claim data found",
                    claim_message_delete:"Are you sure you want to delete this claim?",
                    common_select_claim:"Select a claim",
                    common_button_add:"Add",
                    delivery_button_add:"Add delivery",
                    common_delivery:"Delivery",
                    delivery_common_deliverynumber: "Our delivery number",
                    common_order: "Order",
                    order_message_nodata:"No order data found",
                    common_orders:"Orders",
                    order_common_ordernumber:"Our order number",
                    order_common_status:"Status",
                    order_common_registrationdate:"Registration date",
                    order_button_add:"Add order",
                    order_title_edit:"Add / Edit order",
                    order_common_amount: "Credit",
                    orderrow_button_add:"Add orderrow",
                    orderrow_message_nodata:"No orderrow data found",
                    common_orderrows:"Order rows",
                    order_button_edit:"Edit order",
                    order_message_delete:"Are you sure you want to delete this order?",
                    common_button_close:"Close",
                    common_button_complete:"Complete",
                    common_alert: "Alert",
                    commom_add_code: "Add code",
                    common_code_suggestion: "Code suggestion",
                    commom_none: "None",
                    common_button_create_withdrawal: "Create withdrawal",
                    order_move_for_picking: "Move for picking",
                    article_message_delete: "Are you sure you want to delete this article?", 
                    unit_name_message_delete: "Are you sure you want to delete this unit name?",
                    delivery_common_supplier:"Supplier",
                    delivery_common_site:"Site",
                    delivery_common_reference:"Suppliers delivery number",
                    delivery_common_registrationdate:"Registration date",
                    common_deliveries: "Deliveries",
                    deposit_common_code:"Code",
                    common_deposits:"Deposits",
					common_download_pdf:"Download Pdf",
					delivery_message_nodata: "No data",
					delivery_button_edit: "Edit delivery",
                    orderrow_warning:"Requested credits are not in stock",
                    orderrow_acc_warning:"Accumulated request is not in stock",
					common_other: "Other",
					graph_title_site: "Sites",
					graph_title_stock: "Claims",
					dashboard_quick_options: "Quick options",
					dashboard_no_quick_options: "You have now available quick options",
					common_save_successful: "Save successful",
					common_save_failed: "Saving resource failed",
					login_failed: "Login failed",
					common_get_resource_failed: "Fetching resource failed",
					common_delete_failed: "Deletion failed",
					common_delete_successful: "Deletion success",
                    common_get_report: "Download report",
                    order_common_invoicenumber: "Our invoice number",
                    order_common_clientnumber:"Client number",
                    delivery_deliverynumber_taken: "Delivery number is taken",
                    credit_common_available: "available",
                    order_ordernumber_taken: "Order number is taken",
                    order_status_forpicking: "For picking",
                    order_status_completed: "Completed",
                    order_status_draft: "Draft",
                    common_button_edit: "Edit",
                    orderrow_common_edit:"Edit orderrow",
                    articlegroup_common_typeofwood:"Type of wood",
                    supplier_common_certificatenumber:"Certificate number PEFC",
                    supplierverification_common_expirydate: "Expiry date",
                    common_upload_successful: "Upload successful",
                    common_upload_failed: "Upload failed",
                    supplier_common_expirydate: "Supplier will expire within 3 weeks",
                    delivery_edit_reference_explanation: "Information in order to connect the delivery to the suppliers ordernumber/reference",
                    order_edit_reference_explanation: "Information in order to connect the order to our invoice",
                    supplier_common_secondarycertificatenumber: "Certificate number FSC",
                    deposit_common_articlegroup: "Article group",
                    autocomplete_placeholder: "Start typing",
                    deposit_common_articles: "articles selectable",
                    deposit_articles_no_selection: "You must select an article",
                    common_blocklist: "Blocklist",
                    blocklist_common_remoteip: "IP address",
                    blocklist_common_date: "Date",
                    blocklist_common_expirydate: "Blocked until",
                    blocklist_message_delete: "This will give this ip-adress access to the system again. Do you wish to continue?",
                    blocklist_message_nodata: "No addresses are blocked",
                    site_common_certificatenumber: "Certificate Number",
                    common_search_clientnumber: "Client Number",
                    common_search_registrationdate: "Registration Date",
                    advancedsearch_warning: "An advanced search is active.",
                    stock_search: "Showing stock {{from}} to {{to}}",
                    stock_search_all: "Showing complete stock",
                    stock_default_search: "Showing stock 3 months back",
                    delivery_common_claim: "Claim",
                    order_common_claim: "Claim",
                    order_common_site: "Site",
                    stock_search_label_all: "Show complete stock (this might take awhile)",
                    common_import:"Import",
                    common_upload_report_import:"Upload report",
                    import_common_filename:" File name",
                    import_common_status:"Status",
                    import_common_timestamp:"Timestamp",
                    import_common_archive_duplicates:"Archive duplicates",
                    import_common_replace_duplicates:"Replace duplicates",
                    import_common_for_partner:"Import for partner",
                    import_partner_description: "For manual import, you must choose a partner. For automatic import, the partner will be selected based on the supplier code.",
                    common_help_title: "Help",
                    article_archived: "Article is archived since {{archived}}",
                    import_common_template: "Download template",
                    common_message_nodata: "No data was found",

                    import_confirmation: "Import confirmation",
                    deliveries_import_confirmed: "Delivieries were imported and registered into database",
                    articles_import_confirmed: "Articles were imported and registered into database",
                    import_log_link: "Check import log for results",
                    confirm_import_orders: "Confirmation of order import",
                    import_orders_explanation: "Orders were checked and the result is displayed below." ,
                    import_orders_successful: "Import successful and database updated",
                    import_orders_failure: "No orders were imported into the database",
                    import_orders_errors: "Correct the errors indicated below before trying again",
                    check_import_and_confirm: "Please see to that all is ok and choose 'Confirm' or 'Cancel' below.",
                    import_orders_to_create: "Orders to create",
                    order_rows: "Order rows",
                    mix_percent_limit: "Min 70% max 100%",

                    supplier_button_add_partner_supplier_code: "Add a partner supplier code for ",
                    clientnumber_message_nodata: "Client number is missing",
                    import_common_status_failed: "Failed",
                    import_common_status_success: "Success",
                },
            },
            sv: {
                translation: {
                    common_site: "Anläggning",
                    common_partner: "Delägare",
                    login_common_email: "Epost",
                    login_common_password: "Lösenord",
                    login_button_signin: "Logga in",
                    common_dashboard: "Startsida",
                    common_user: "Användare",
                    common_article: "Artikel",
                    common_articlegroup: "Artikelgrupp",
                    common_supplier: "Leverantör",
                    common_supplierverification: "Leverantörskontroll",
                    common_deposit: "Insättning",
                    common_withdrawal: "Uttag",
                    common_certificate: "Certifikat",
                    user_message_delete: "Är du säker på att du vill radera den här användaren?",
                    user_button_add: "Lägg till användare",
                    user_common_givenname: "Förnamn",
                    user_common_surname: "Efternamn",
                    user_common_telephone: "Telefon",
                    user_common_email: "Epost",
                    user_common_role: "Roll",
                    user_common_partner: "Delägare",
                    user_common_site: "Anläggning",
                    common_button_delete: "Radera",
                    common_button_inactivate: "Inaktivera",
                    user_message_nodata: "Ingen användardata funnen",
                    user_title_edit: "Lägg till / Redigera användare",
                    common_button_ok: "OK",
                    common_button_cancel: "AVBRYT",
                    partner_message_delete: "Är du säker på att du vill radera den här delägaren?",
                    partner_button_add: "Lägg till delägare",
                    partner_common_description: "Benämning",
                    partner_common_corporateid: "Organisationsnummer",
                    partner_common_user: "Användare",
                    partner_message_nodata: "Ingen delägadata funnen",
                    partner_title_edit: "Lägg till / Redigera delägare",
                    site_button_add: "Lägg till anläggning",
                    site_message_delete: "Är du säker på att du vill radera det här anläggningen?",
                    site_message_nodata: "Ingen anläggningsdata funnen",
                    site_common_name: "Namn",
                    site_common_partner: "Delägare",
                    site_common_user: "Användare",
                    site_common_street: "Gata",
                    site_common_postcode: "Postnummer",
                    site_common_city: "Stad",
                    site_common_code: "Kod",
                    site_common_country: "Land",
                    site_title_edit: "Lägg till / Redigera anläggning",
                    common_select_user: "Välj en användare",
                    common_select_partner: "Välj en delägare",
                    common_select_site: "Välj en anläggning",
                    common_select_role: "Välj en roll",
                    user_common_password: "Lösenord",
                    article_message_nodata: "Ingen artikeldata funnen",
                    article_message_delete: "Är du säker på att du vill radera den här artikeln?",
                    unit_name_message_delete: "Är du säker på att du vill radera den här enhetsbeteckningen?",
                    article_common_number: "Artikelnummer",
                    article_common_description: "Artikelbenämning",
                    article_common_category: "Produktkategori",
                    article_common_unit: "Enhet",
                    article_handle_unit_names: "Hantera enhetsbeteckningar",
                    article_common_unit_name: "Enhetsbeteckning",
                    article_common_width: "Bredd (mm)",
                    article_common_height: "Höjd / tjocklek (mm)",
                    article_common_depth: "Längd (mm)",
                    article_common_articlegroup: "Artikelgrupp",
                    article_common_articlegroups: "Artikelgrupper",
                    common_select_unit: "Välj enhet",
                    article_button_add: "Lägg till artikel",
                    unit_button_add: "Lägg till enhetsbeteckning",
                    article_title_edit: "Lägg till / Redigera artikel",
                    common_select_articlegroup: "Välj en artikelgrupp",
                    articlegroup_message_delete: "Är du säker på att du vill radera den här artikelgruppen?",
                    common_confirm: "Bekräfta",
                    articlegroup_button_add: "Lägg till artikelgrupp",
                    articlegroup_common_description: "Benämning",
                    articlegroup_common_name: "Namn",
                    articlegroup_message_nodata: "Ingen artikelgruppdata funnen",
                    articlegroup_title_edit: "Lägg till / Redigera artikelgrupp",
                    supplier_message_nodata: "Ingen leverantörsdata funnen",
                    supplier_button_add: "Lägg till leverantör",
                    supplier_message_delete: "Är du säker på att du vill radera den här leverantören?",
                    supplier_common_name: "Namn",
                    supplier_common_code: "Kod",
                    supplier_common_street: "Gata",
                    supplier_common_postcode: "Postnummer",
                    supplier_common_city: "Stad",
                    supplier_common_country: "Land",
                    supplier_common_verificationinterval: "Kontrollinterval",
                    supplier_common_partner: "Delägare",
                    supplier_common_partner_supplier_codes: "Delägarnas leverantörskoder",
                    supplier_common_user: "Användare",
                    supplier_common_certificate:"Certifikat",
                    supplier_title_edit: "Lägg till / Redigera leverantör",
                    supplierverification_message_nodata: "Ingen kontrolleringsdata funnen",
                    supplierverification_message_delete: "Är du säker på att du vill radera den här kontrollen?",
                    supplierverification_button_add: "Lägg till leverantörskontroll",
                    supplierverification_common_supplier: "Leverantör",
                    supplierverification_common_user: "Användare",
                    supplierverification_common_verificationdate: "Kontrolldatum",
                    supplierverification_title_edit: "Lägg till / Redigera leverantörskontroll",
                    common_select_supplier: "Välj en leverantör",
                    deposit_button_add: "Lägg till insättning",
                    deposit_message_delete: "Är du säker på att du vill radera den här insättningen?",
                    deposit_message_nodata: "Ingen insättningsdata funnen",
                    deposit_common_amount: "Krediter",
                    deposit_common_mixpercent: "Mix-procent",
                    deposit_common_reference: "Referens",
                    deposit_common_location: "Placering",
                    deposit_common_site: "Anläggning",
                    deposit_common_supplier: "Leverantör",
                    deposit_common_article: "Artikel",
                    deposit_common_user: "Användare",
                    deposit_common_certificate: "Certifikat",
                    deposit_common_registrationdate: "Inregistreringsdatum",
                    deposit_common_expirydate: "Utgångsdatum",
                    deposit_common_claim: "Claim",
                    common_select_certificate: "Välj ett certifikat",
                    common_select_article: "Välj en artikel",
                    withdrawal_message_nodata: "Ingen uttagsdata funnen",
                    withdrawal_message_delete: "Är du säker på att du vill radera det här uttaget?",
                    withdrawal_common_type: "Typ av uttag",
                    withdrawal_common_amount: "Krediter",
                    withdrawal_common_reference: "Referens",
                    withdrawal_common_user: "Användare",
                    withdrawal_common_deposit: "Insättning",
                    withdrawal_common_certificate: "Certifikat",
                    withdrawal_common_registrationdate: "Registreringsdatum",
                    withdrawal_button_add: "Lägg till uttag",
                    common_select_deposit: "Välj en insättning",
                    withdrawal_title_edit: "Lägg till / Redigera uttag",
                    common_select_withdrawaltype: "Välj en uttagstyp",
                    certificate_message_delete: "Är du säker på att du vill radera det här certifikatet?",
                    certificate_message_nodata: "Ingen certifikatdata funnen",
                    certificate_button_add: "Lägg till certifikat",
                    common_search: "Sök",
                    common_multiple_search_article: 'Sök på artikelnummer, benämning, kategori, artikelgrupp',
                    common_search_advanced: "Avancerad Sök",
                    common_search_freetext: "Fritext",
                    common_search_articlenumber: "Artikelnummer",
                    common_search_claimname: "Claim",
                    common_search_status: "Status",
                    common_search_results_count: "Antal sökresultat:",
                    certificate_common_name: "Namn",
                    certificate_common_description: "Benämning",
                    certificate_title_edit: "Lägg till / Redigera certifikat",
                    common_stock: "Kreditkonto",
                    certificate_common_expirymonths: "Går ut efter månader",
                    stock_common_deposit: "Insättning",
                    stock_common_withdrawal: "Uttag",
                    stock_common_total: "Totalt",
                    stock_common_date: "Datum",
                    stock_common_before: "Ingående balans",
                    stock_search_fromdate: "Från datum",
                    stock_search_todate: "Till datum",
                    stock_common_certificate: "Certifikat",
                    stock_common_today: "Idag",
                    common_reset_search: "Återställ filter",
                    stock_common_after: "Utgående balans",
                    stock_common_article: "Artikel",
                    stock_common_supplier: "Leverantör",
                    stock_common_reference: "Referens",
                    common_logout: "Logga ut",
                    common_information: "Grundläggande information",
                    articlegroup_button_edit:"Redigera artikelgrupp",
                    common_article_dimension:"Dimension",
                    article_button_edit:"Redigera artikel",
                    common_address: "Adress",
                    site_button_edit:"Redigera anläggning",
                    certificate_button_edit:"Redigera certifikat",
                    supplier_button_edit:"Redigera leverantör",
                    partner_button_edit:"Redigera delägare",
                    user_button_edit:"Redigera användare",
                    user_title_detail:"Användarinformation",
                    common_users:"Användare",
                    common_partners:"Delägare",
                    common_sites:"Anläggningar",
                    common_articles:"Artiklar",
                    common_articlegroups:"Artikelgrupper",
                    common_suppliers:"Leverantörer",
                    common_certificates:"Certifikat",
                    common_supplierverifications:"Leverantörsverifieringar",
                    supplier_common_articlegroup: "Artikelgrupp",
                    deposit_common_cubicmeter: "Kubikmeter",
                    deposit_common_noofarticle: "Antal artiklar",
                    deposit_common_calcreditby: "Beräkna kredit genom",
                    deposit_common_article_alert:"Krediter kan inte beräknas med den här artikeln eftersom den inte har någon dimension.",
                    deposit_select_direct:"Direkt krediter",
                    deposit_select_article:"Artikelmängd",
                    certificate_button_add_claim:"Lägg till claim",
                    claim_common_name:"Namn",
                    claim_common_code: "Kod",
                    claim_common_transfer:"Transfer",
                    common_claim: "Claim",
                    claim_message_nodata: "Ingen claimdata funnen",
                    claim_message_delete: "Är du säker på att du vill radera det här anläggningen?",
                    common_select_claim:"Välj en claim",
                    common_button_add:"Lägg till",
                    delivery_button_add:"Lägg till inleverans",
                    common_delivery:"Inleverans",
                    delivery_common_deliverynumber: "Vårt beställningsnummer / ordernummer",
                    common_order: "Utleverans",
                    order_message_nodata:"Ingen utleverans funnen",
                    common_orders:"Utleveranser",
                    order_common_ordernumber:"Vårt beställningsnummer / ordernummer",
                    order_common_status:"Status",
                    order_common_registrationdate:"Registreringsdatum",
                    order_button_add:"Lägg till utleverans",
                    order_title_edit:"Lägg till / Redigera utleverans",
                    order_common_amount: "Krediter",
                    orderrow_button_add:"Lägg till orderrader",
                    orderrow_message_nodata:"Ingen orderradsdata funnen",
                    common_orderrows:"Orderrader",
                    order_button_edit:"Redigera utleverans",
                    order_message_delete:"Är du säker på att du vill radera den här utleveransen?",
                    common_button_close:"Stäng",
                    common_button_complete:"Färdigställ",
                    common_alert: "Alert",
                    commom_add_code: "Lägg till Kod",
                    common_code_suggestion: "Kodförslag",
                    commom_none: "Ingen",
                    common_button_create_withdrawal: "Skapa Uttag",
                    order_move_for_picking: "Flytta för att plocka",
                    delivery_common_supplier:"Leverantör",
                    delivery_common_site:"Varuhus",
                    delivery_common_reference:"Leverantörens ordernummer / fakturanummer",
                    delivery_common_registrationdate:"Registreringsdatum",
                    common_deliveries: "Inleveranser",
                    deposit_common_code:"Kod",
                    common_deposits:"Insättningar",
                    common_download_pdf:"Ladda ner Pdf",
					delivery_message_nodata: "Ingen data",
					delivery_button_edit: "Redigera leverans",
					orderrow_warning:"Begärda krediter finns inte i kreditkontot",
                    orderrow_acc_warning:"Akumulerad förfrågan för stor",
					common_other: "Annan",
					graph_title_site: "Anläggningar",
					graph_title_stock: "Claims",
					dashboard_quick_options: "Snabbval",
					dashboard_no_quick_options: "Du har inga tillgängliga snabbval",
					common_save_successful: "Sparningen lyckades",
					common_save_failed: "Sparningen misslyckades",
					login_failed: "Inloggning misslyckades",
					common_get_resource_failed: "Fetching resource failed",
					common_delete_failed: "Radering misslyckades",
                    common_delete_successful: "Radering lyckades",
                    common_get_report: "Ladda ner rapport",
                    order_common_invoicenumber: "Vårt fakturanummer",
                    order_common_clientnumber:"Kundnummer",
                    delivery_deliverynumber_taken: "Leveransnummret används redan",
                    credit_common_available: "tillgängliga",
                    order_ordernumber_taken: "Utleveransnummret används redan",
                    order_status_forpicking: "För plockning",
                    order_status_completed: "Avslutad",
                    order_status_draft: "Utkast",
                    common_button_edit: "Redigera",
                    orderrow_common_edit:"Redigera orderrad",
                    articlegroup_common_typeofwood:"Träslag",
                    supplier_common_certificatenumber:"Certifikatsnummer PEFC",
                    supplierverification_common_expirydate: "Utgångsdatum",
                    common_upload_successful: "Ladda upp lyckades",
                    common_upload_failed: "Ladda upp misslyckades",
                    supplier_common_expirydate: "Leverantörens certifikatsverifiering upphör inom tre veckor",
                    delivery_edit_reference_explanation: "Upplysningar för att kunna koppla leveransen till leverantörens faktura/leveransdokument",
                    order_edit_reference_explanation: "Upplysningar för att kunna koppla leveransen till vår faktura/leveransdokument",
                    supplier_common_secondarycertificatenumber:"Certifikatsnummer FSC",
                    deposit_common_articlegroup: "Artikelgrupp",
                    autocomplete_placeholder: "Skriv in artikelnummer",
                    deposit_common_articles: "artiklar valbara", 
                    deposit_articles_no_selection: "Du måste välja en artikel",
                    common_blocklist: "Blockerad lista",
                    blocklist_common_remoteip: "IP adress",
                    blocklist_common_date: "Datum",
                    blocklist_common_expirydate: "Blockerad till",
                    blocklist_message_delete: "Detta kommer att åter ge denna ip-adress åtkomst till systemet. Vill du fortsätta?",
                    blocklist_message_nodata: "Inga adresser är blockerade",
                    site_common_certificatenumber: "Certifikatnummer",
                    common_search_clientnumber: "Kundnummer",
                    common_search_registrationdate: "Registreringsdatum",
                    advancedsearch_warning: "En avancerad sökning är gjord.",
                    stock_search: "Visar kredit från {{from}} till {{to}}",
                    stock_search_all: "Visar hela krediten",
                    stock_default_search: "Visar kredit 3 månader tillbaka",
                    delivery_common_claim: "Claim",
                    order_common_claim: "Claim",
                    order_common_site: "Anläggning",
                    stock_search_label_all: "Visa hela krediten (detta kan ta en stund)",
                    common_import:"Import",
                    common_upload_report_import:"Ladda upp",
                    import_common_filename:" Filnamn",
                    import_common_status:"Status",
                    import_common_timestamp:"Utförd",
                    import_common_archive_duplicates:"Arkivera dubbletter",
                    import_common_replace_duplicates:"Ersätt dubbletter",
                    import_common_for_partner:"Importera för delägare",
                    import_partner_description: "Vid manuell import så måste du välja en delägare. Vid automatisk import så kommer delägaren att väljas utifrån leverantörens kod.",
                    common_help_title: "Hjälp",
                    article_archived: "Artikeln är arkiverad sedan {{archived}}",
                    import_common_template: "Ladda ner mall",
                    common_message_nodata: "Ingen data hittades",

                    import_confirmation: "Importbekräftelse",
                    deliveries_import_confirmed: "Utleveranser importerades och registrerades i databasen",
                    articles_import_confirmed: "Artiklar importerades och registrerades i databasen",
                    import_log_link: "Se resultaten i importloggen",
                    confirm_import_orders: "Bekräftelse för import av order",
                    import_orders_explanation: "Order kontrollerades och resultatet visas nedan." ,
                        // + " Om allt var ok blev databasen uppdaterad. Korrigera annars i importfilen",
                    import_orders_successful: "Importen av order gick bra och databasen är uppdaterad",
                    import_orders_failure: "Inga order importerades till databasen",
                    import_orders_errors: "Se igenom felen nedan och korrigera importfilen före nästa försök",
                    check_import_and_confirm: "Kontrollera så att allt är ok och välj sedan 'Bekräfta' eller 'Avbryt' nedan.",
                    import_orders_to_create: "Order att skapa",
                    order_rows: "Orderrader",
                    mix_percent_limit: "Minst 70% högst 100%",

                    supplier_button_add_partner_supplier_code: "Lägg till en delägares kod för ",
                    clientnumber_message_nodata: "Kundnummer saknas",
                    import_common_status_failed: "Misslyckades",
                    import_common_status_success: "Lyckades",
                },
            },
        },
    })

export default i18next
