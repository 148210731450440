import NetworkService from './NetworkService';

let promiseQueue = {};

const HelpService = {

    getByGroup: function(session, group) {

        // Check if promise exists
        if (promiseQueue[group]) {
            return promiseQueue[group];
        }

        promiseQueue[group] = NetworkService.getHelpByGroup(session, group);

        return promiseQueue[group];
    }


};

export default HelpService;