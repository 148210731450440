const certificate = (state = [], action) => {
    switch (action.type) {
        case 'SET_CERTIFICATE':
            return ({
                certificateid: action.certificate.certificateid,
                name: action.certificate.name,
                description: action.certificate.description,   
                expirymonths: action.certificate.expirymonths            
            })
        default:
            return state
    }
}

export default certificate