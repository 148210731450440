const order = (state = [], action) => {
    switch (action.type) {
        case 'SET_ORDER':
            return ({  
                orderid: action.order.orderid,                        
                ordernumber:action.order.ordernumber,
                invoicenumber:action.order.invoicenumber,      
                clientnumber:action.order.clientnumber,      
                status:action.order.status,
                registrationdate:action.order.registrationdate,                
            })
        default:
            return state
    }
}

export default order