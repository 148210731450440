const article = (state = [], action) => {
    switch (action.type) {
        case 'SET_ARTICLE':
            return ({  
                articleid: action.article.articleid,  
                articlenumber: action.article.articlenumber,                
                articledescription: action.article.articledescription,                        
                category:action.article.category,
                width:action.article.width,
                height:action.article.height,
                depth:action.article.depth,
                articlegroupid:action.article.articlegroupid,
                articlegroups:action.article.articlegroups || []
            })
        default:
            return state
    }
}

export default article