import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import Modal from '../../components/Modal';
import helper from '../../services/helper';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCertificate: (certificate) => dispatch({
            type: 'SET_CERTIFICATE',
            certificate: certificate,
        }),
    };
};

class Certificate extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {         
            certificate: {},
            isOpen: false,
            deletecertificateid:"", 
			filter:"",
			certificateLoading:false,
			certificates:[]
        }  
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteCertificate = this._deleteCertificate.bind(this);    
        this._handleChange = this._handleChange.bind(this);
        this._renderCertificateRows = this._renderCertificateRows.bind(this);
    }

    componentDidMount() {  
        this._isMounted = true;
		var that = this;
		this.setState({certificateLoading:true},function(){
			NetworkService.getCertificates(that.props.session, function (certificate) { 
				if (that._isMounted) {                                     
					that.setState({certificates:certificate, certificateLoading:false});
				}
			}, function (err) {
				console.log(err);
				that.setState({certificateLoading:false});
			});
		})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, certificateid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletecertificateid: certificateid
        });
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletecertificateid: null
        });        
    } 

    _deleteCertificate(){               
        var that =this;               
        NetworkService.deleteCertificate(that.state.deletecertificateid, that.props.session, function (certificate) {                         
            that.props.push(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _renderCertificateRows(){         
        var hasAccess =helper.checkAccess(this.props.session.scope, "CERTIFICATE", 1, 1)     
        if(this.state.certificates!==undefined){
            const { filter, certificates } = this.state;    
            const lowercasedFilter = filter.toLowerCase();
            const filteredData = certificates.filter(item => {
                let result = Object.keys(item).some(key => item[key].toString().toLowerCase().includes(lowercasedFilter));
                console.log(Object.keys(item), 'whats item?', result, 'OF KEY')
                return result
            });            
            let rows = [];
            if (filteredData && filteredData.length > 0) {
                rows =filteredData.map((item, i) => {                                                     
                    return (
                        <tr key={i} className={hasAccess ?"tr-clickable":""} onClick={e => hasAccess ? this._addCertificate(item): ""}>  
                            <td>{item.name}</td>  
                            <td>{item.description}</td>
                            <td>{item.expirymonths}</td>                          
                            {/* [OV] Removed Delete button
                            <td>  
                                {hasAccess ? 
                                    <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.certificateid)}>{i18n.t("common_button_delete")}</button>
                                    : null
                                }         
                                
                            </td>  */}
                        </tr>
                    )}
                )
            } else if(this.state.certificateLoading){
				return <tr><td colSpan="4" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="4">{i18n.t("certificate_message_nodata")}</td></tr>
            }    
            return rows;
        }        
    } 

    _addCertificate(item){      
        if(item!==undefined){
            this.props.setCertificate({
                certificateid: item.certificateid,
                name: item.name,
                description: item.description
            });
            this.props.push(`/certificate/detail/${item.certificateid}`);
        }else{
            this.props.push(`/certificate/add`);
        }
    }  
    
    _handleChange(event) {   
        console.log(event.target.value, 'what is?')        
        this.setState({ filter: event.target.value });         
    }  

    render() {     
        const title = i18n.t("common_certificates");           
        var hasAccess =helper.checkAccess(this.props.session.scope, "CERTIFICATE", 1, 1)     
        return (
            <div id="page-content-wrapper">        
                <Navbar title={title}/>   
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        
                        { hasAccess ?
                            <div className="col-md-4 ">
                                <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addCertificate()}> <i className="fa fa-plus"></i> {i18n.t("certificate_button_add")}</button>
                            </div>
                            : null
                        }
                                                 
                        <div className="col-md-4 input-group ">
                            <input type="text" className="form-control" placeholder={i18n.t("common_search")} onChange={this._handleChange}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">                        
                        <thead>
                        <tr>
                            <th>{i18n.t("certificate_common_name")}</th>
                            <th>{i18n.t("certificate_common_description")}</th>
                            <th>{i18n.t("certificate_common_expirymonths")}</th>     
                                                                            
                        </tr>
                        </thead>
                        <tbody>                   
                        {
                            this._renderCertificateRows()                            
                        }                    
                        </tbody>                
                    </table>          
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteCertificate}>                    
                    {i18n.t("certificate_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
