import React, { useEffect, useState } from 'react';
import '../../App.css';
import Navbar from '../Navbar';
import i18n from '../../services/i18n';
import NetworkService from '../../services/NetworkService';
import helper from '../../services/helper';
import { toast } from 'react-toastify';
import moment from 'moment';
import { connect } from 'react-redux';

import { useNavigate } from "react-router-dom";   // [OV] React 18

const mapStateToProps = function (state) {
  return {
    session: state.session
  };
};

// [OV] Converted from class component
const ImportFile = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [importFiles, setImportFiles] = useState([]);
  const [duplicates, setDuplicates] = useState('archive');
  // const [loaded, setLoaded] = useState(0);
  const [filesLoading, setFilesLoading] = useState(false);
  const [uploadedJSON, setUploadedJSON] = useState([]);
  // [OV] State for partners
  const [partners, setPartners] = useState([]);
  const [partnerid, setPartnerid] = useState(null);
  
  const navigate = useNavigate();

  // Read all partners from the database
  useEffect(() => {
    // [OV] Get partners from backend only if admin
    if (props.session.scope.role === "Administrator") {
      NetworkService.getPartners(props.session,
        function (partners) {
          if (partners) {
            setPartners(partners);
          }
        },
        function (err) {
          console.log(err);
        });
    }
  }, [props]);


  // [OV] Replacing 'componentDidMount' in class component
  useEffect(() => {
    setFilesLoading(true);          // [OV] Start loading
    // [OV] Get previously imported files from backend
    NetworkService.getImportFiles(props.session, 
      function (imp) {
        // [OV] 'imp' is an array of data about previous imports
        if (imp) {
          setImportFiles(imp);      // [OV] To state
          setFilesLoading(false);   // [OV] Finished loading
        }
      }, 
      function (err) {
        console.log(err);
        setFilesLoading(false);
      }
    );
  }, [props]);

  // [OV] Client user has selected a file to import
  const _onupload = (event) => {
    event.preventDefault(event);
    event.stopPropagation();
    setSelectedFile(event.target.files[0]);
    let replace = Boolean(duplicates === 'replace');
    let fileData = new FormData();
    fileData.append('file', event.target.files[0]);

    setUploadedJSON([]);  // Re-initialize (empty from old data
    
    // [OV] Upload the file to backend
    NetworkService.uploadImport(fileData, {replace: replace, partnerid: props.session.scope.partner ? props.session.scope.partner : partnerid}, props.session, 
      function (uploaded) {
        console.log("uploaded: ", uploaded)
        // [OV] The backend can return a response with info about orders to be confirmed
        if(uploaded && uploaded.orderRows) {
          toast.success(i18n.t("common_upload_successful"));
          setUploadedJSON(uploaded);
          // [OV] Redirect to client confirmation page enclosing response from backend
          navigate('/importorders', {state: uploaded});
        } else if (uploaded && uploaded.deliveryRows) {
          // TODO: Show delivery import results
          toast.success(i18n.t("common_upload_successful"));
          setUploadedJSON(uploaded);
          // [OV] Redirect to client confirmation page enclosing response from backend
          // navigate('/importdeliveries', {state: uploaded});
          navigate('/')

        } else if (uploaded && uploaded.r === "ok") {
          toast.success(i18n.t("common_upload_successful"));
          console.log("No json to display")
          window.location.reload();
        } else if(uploaded && uploaded.r === "not ok") {
          toast.error(i18n.t("common_upload_failed"));
          window.location.reload();
        } else {
          window.location.reload();
        }
      }, 
      function (err) {
        toast.error(i18n.t("common_upload_failed"));
        window.location.reload();
        console.log(err);
      }
    );
  }

  const _onRadioChanged = (event) => {
    setDuplicates(event.target.value);
  }

  const _onPartnerChanged = (event) => {
    setPartnerid(event.target.value);
  }

  const _renderImportRows = () => {
    if (importFiles.length > 0) {
      return importFiles.map(file => {
        return (
          <tr key={file.id}>
            <td>{moment(file.timestamp).format('YYYY-MM-DD HH:mm')}</td>
            <td>{file.filename}</td>
            <td style={{color:file.status=="FAILED"?"red":"green"}}>{i18n.t("import_common_status_"+file.status?.toLowerCase())}</td>
          </tr>
        );
      });
    } else if (filesLoading) {
      return <tr><td colSpan="3" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
    } else {
      return <tr><td colSpan="3">{i18n.t("common_message_nodata")}</td></tr>
    }
  }

  const title = i18n.t("common_import");
  var hasAccess = helper.checkAccess(props.session.scope, "IMPORT", 1, 1);

  return (
    <div id="page-content-wrapper">
      <Navbar title={title} />
      <div className="container mt-5">
        {hasAccess ?
          <div className="row align-items-end">
            <div className="col-2">
              <a href="template-import.zip">
                <i className="fa fa-file"></i>
                <span className="ml-1">{i18n.t("import_common_template")}</span>
              </a>
            </div>
            <div className="col-4">
              <button className="btn btn-outline-secondary toolbar-buttons col-md-10">
                <input type="file" onChange={e => _onupload(e)} name="file" accept="application/.xls, .xlsx, .csv, .txt, .xml" style={{ color: '#000' }} />
              </button>
            </div>
            <div className="col-2">
              <input className="ml-2" type="radio" name="duplicates"
                value="archive" checked={duplicates === "archive"}
                onChange={_onRadioChanged} />
              <label className="ml-2">{i18n.t("import_common_archive_duplicates")}</label>

              <input className="ml-2" type="radio" name="duplicates"
                value="replace" checked={duplicates === "replace"}
                onChange={_onRadioChanged} />
              <label className="m-2">{i18n.t("import_common_replace_duplicates")}</label>
            </div>
            {/* Add a select imput visible only for admin for choosing partner for the import */}
            {props.session.scope.role === "Administrator" ?
              <div className="col-4">
                <label className="m-2" style={{color:"red", fontWeight:'bold'}}>{i18n.t("import_common_for_partner")} <i title={i18n.t("import_partner_description")} className="fa fa-question-circle"></i></label>
                <select className="form-control" id="partner" name="partner"
                  onChange={_onPartnerChanged}>
                  <option key="0" value="">{i18n.t("common_select_partner")}</option>
                  {/* Options for all partners in the 'partner' database table */}
                  {partners.map(partner => {
                    return (
                      <option key={partner.partnerid} value={partner.partnerid}>{partner.description}</option>
                    );
                  })}
                </select>
              </div>
              : null
            }
          </div>
          : null
        }
        <table className="table table-striped mt-4 table-responsive-sm">
          <thead>
            <tr>
              <th>{i18n.t("import_common_timestamp")}</th>
              <th>{i18n.t("import_common_filename")}</th>
              <th>{i18n.t("import_common_status")}</th>
            </tr>
          </thead>
          <tbody>
            {
              _renderImportRows()
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ImportFile);
