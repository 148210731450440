import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import NetworkService from '../../services/NetworkService';
import Navbar from '../../components/Navbar';
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';
import moment from 'moment';

const mapStateToProps = function (state) {    
    return {
        session: state.session       
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

class Blocklist extends React.Component {  
    _isMounted = false; 
       constructor(props) {
        super(props)
        this.state = {            
            blocklist: [],
            isOpen: false,
			deleteBlocklistId: '',
			loading: false,
        } 
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteBlocklist = this._deleteBlocklist.bind(this);          
    }
      
    componentDidMount() { 
        this._isMounted = true;              
        var that = this; 

		this.setState({loading:true},function(){
			NetworkService.getBlocklist(that.props.session, function (blocklist) {             
                that.setState({ blocklist: blocklist, loading: false });
			}, function (err) {
				console.log(err);
				that.setState({
					loading:false,	
				});
			});
		});
    } 
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, blocklistid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deleteBlocklistId: blocklistid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deleteBlocklistId: null
        });        
    } 

    _deleteBlocklist() {
        var that = this;
                      
        NetworkService.deleteBlocklist(that.state.deleteBlocklistId, that.props.session, function () {                         
            that.props.history.go(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _renderBlocklistRows(){                        
        let rows = [];
        var hasAccess = helper.checkAccess(this.props.session.scope, "BLOCKLIST", 1, 1)         

        if (this.state.blocklist && this.state.blocklist.length > 0) {
            rows = this.state.blocklist.map((item, i) => {                                                                 
                return (
                    <tr key={i}>                    
                        <td>{item.remoteip}</td>
                        <td>{moment(item.date).format('YYYY-MM-DD HH:ss')}</td>
                        <td>{moment(item.expirydate).format('YYYY-MM-DD HH:ss')}</td>                   
                        <td> 
                        {hasAccess ?             
                            <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.blocklistid)}>{i18n.t("common_button_delete")}</button>
                            : null
                        }
                        </td>                 
                    </tr>
                )})
        } else if(this.state.userLoading){
            return <tr><td colSpan="4" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
        } else {
            return <tr><td colSpan="4">{i18n.t("blocklist_message_nodata")}</td></tr>
        }
            
        return rows;
    }

    render() {               
        const title = i18n.t("common_blocklist");
        return (
                <div id="page-content-wrapper">        
                <Navbar title={title}/>
                <div className="container mt-5">   
                        <table className="table table-striped mt-4 table-responsive-sm">                    
                            <thead>
                            <tr>
                                <th>{i18n.t("blocklist_common_remoteip")}</th>
                                <th>{i18n.t("blocklist_common_date")}</th>
                                <th>{i18n.t("blocklist_common_expirydate")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>                   
                            {
                            this._renderBlocklistRows()                            
                            }                    
                            </tbody>
                        
                        </table>
                    </div>
                    <Modal show={this.state.isOpen}
                        onClose={this.onClose}
                        onOk={this._deleteBlocklist}>                    
                        {i18n.t("blocklist_message_delete")}
                    </Modal>
                </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Blocklist);
