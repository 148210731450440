import React from 'react';
import '../App.css';
import helper from '../services/helper'
import i18n from '../services/i18n';
import HelpIcon from '../components/help/HelpIcon';

class Navbar extends React.Component {

    _toggleSidebar = () => {       
        var wrapper = document.getElementById("wrapper");
        if(wrapper.classList.contains("toggled")){
            wrapper.classList.remove("toggled");
        }else{
            wrapper.classList.add("toggled");
        }        
    }

    _changeLanguage = (lang) => {
        document.cookie = "language="+lang+ "; path=/";
        window.location.reload();
    }    

    _closeSidebar = () => {       
        var wrapper = document.getElementById("wrapper");
        if(wrapper.classList.contains("toggled")){
            wrapper.classList.remove("toggled");
        }      
    }
    _logout = () => {               
        helper.deleteCookie('Session');
        window.location.reload();
    }

    render() { 
        document.getElementById('root').addEventListener('click', this._closeSidebar());       
        return (
            <div>
                <nav id="top-navbar" className="navbar navbar-expand-md navbar-light border-bottom">
                    <div>
                        <button className="navbar-toggler" id="menu-toggle" onClick={e => this._toggleSidebar()}>  <span className="navbar-toggler-icon"></span></button>
                        <span className="navbar-brand mb-0 h1">{this.props.title}</span>
                    </div>
                    <div>
                        <HelpIcon code={ 'page_' + btoa(window.location.pathname)} style={{ color: 'white', wdith: 25, height: 25, fontSize: '1.4em', marginRight: 10, padding: 2 }} />
                        <button className="btn btn-primary btn-sm mr-2 pull-right logout-button" onClick={e => this._logout()}>{i18n.t("common_logout")}</button>
                        <img onClick={e=> {this._changeLanguage("sv")}} className="language-flag pull-right" src={require("../images/sweden-flag-icon-32.png")} alt="swedish language"/>
                        <img onClick={e=> {this._changeLanguage("en")}}className="language-flag pull-right" src={require("../images/united-kingdom-flag-icon-32.png")} alt="english language"/>                                               
                    </div>                    
                </nav>
                <div style={{height:57}}></div>
            </div>
        );
    }
};

export default Navbar;
