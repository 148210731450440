import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import moment from "moment";
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setWithdrawal: (withdrawal) => dispatch({
            type: 'SET_WITHDRAWAL',
            withdrawal: withdrawal,
        }),
    };
};

class Withdrawal extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
        this.state = {            
            withdrawal: {},
            isOpen: false,
			deletewithdrawalid:"",
			withdrawalLoading:false,
        } 
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteWithdrawal = this._deleteWithdrawal.bind(this);              
    }

    componentDidMount() {    
        this._isMounted = true;                 
		var that = this;
		this.setState({withdrawalLoading:true},function(){
			NetworkService.getWithdrawals(that.props.session, function (withdrawal) {     
				if (that._isMounted) {         
					that.setState({withdrawals:withdrawal,withdrawalLoading:false});
				}        
			}, function (err) {
				console.log(err);
				that.setState({withdrawalLoading:false});
			});
		})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, withdrawalid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletewithdrawalid: withdrawalid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletewithdrawalid: null
        });        
    } 

    _deleteWithdrawal(){               
        var that =this;                       
        NetworkService.deleteWithdrawal(that.state.deletewithdrawalid, that.props.session, function (withdrawal) {                         
            that.props.history.go(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _renderWithdrawalRows(){        
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "WITHDRAWAL", 1, 1)   
        if (this.state.withdrawals && this.state.withdrawals.length > 0) {
        rows =this.state.withdrawals.map((item, i) => {                                                     
            return (
                <tr key={i} onClick={e => hasAccess ? this._addWithdrawal(item) : ""}>                  
                    <td>{item.withdrawaltype}</td>    
                    <td>{item.amount}</td>    
                    <td>{item.reference}</td>       
                    <td>{item.user.givenname+' '+item.user.surname}</td>                        
                    <td>{item.certificate.name}</td>                     
                    <td>{moment(item.registrationdate).format('YYYY-MM-DD')}</td>    
                    <td>
                    {hasAccess ?            
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.withdrawalid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>                                        
                </tr>
            )})} else if(this.state.withdrawalLoading){
				return <tr><td colSpan="8" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="8">{i18n.t("withdrawal_message_nodata")}</td></tr>
            }
            
        return rows;
    }

    _addWithdrawal(item){ 
        if(item!==undefined){
            this.props.setWithdrawal({
                withdrawalid: item.withdrawalid,
                withdrawaltype: item.withdrawaltype,
                amount: item.amount,
                reference: item.reference,
                userid: item.userid,               
                certificateid: item.certificateid,
                registrationdate: item.registrationdate
            });
            this.props.push(`/withdrawal/edit/${item.withdrawalid}`);
        }else{     
            this.props.push('/withdrawal/add');
        }
    }
    render() {
        const title = i18n.t("common_withdrawal");    
        var hasAccess =helper.checkAccess(this.props.session.scope, "WITHDRAWAL", 1, 1)        
        return (
            <div id="page-content-wrapper">        
                <Navbar title={title}/>     
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12"  onClick={e => this._addWithdrawal()}> <i className="fa fa-plus"></i> {i18n.t("withdrawal_button_add")}</button>
                        </div> : null
                        }                              
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">                        
                        <thead>
                        <tr>
                            <th>{i18n.t("withdrawal_common_type")}</th>                            
                            <th>{i18n.t("withdrawal_common_amount")}</th>                            
                            <th>{i18n.t("withdrawal_common_reference")}</th>                                    
                            <th>{i18n.t("withdrawal_common_user")}</th>                             
                            <th>{i18n.t("withdrawal_common_certificate")}</th>                     
                            <th>{i18n.t("withdrawal_common_registrationdate")}</th>   
                            <th></th>                                                   
                        </tr>
                        </thead>
                        <tbody>                   
                        {
                            this._renderWithdrawalRows()                            
                        }                    
                        </tbody>                
                    </table>          
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteWithdrawal}>                    
                    {i18n.t("withdrawal_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);