import React from 'react';
import Autosuggest from 'react-autosuggest';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import '../../App.css';
import helper from '../../services/helper';
import i18n from '../../services/i18n';
import NetworkService from '../../services/NetworkService';

const mapStateToProps = function (state) {
	return {
		session: state.session,
	}
}

let amountTimeout;
const getSuggestionValue = suggestion => suggestion.articlenumber + " - " + suggestion.articledescription;

const renderSuggestion = suggestion => (
    <div className="autocomplete-list">
        {suggestion.articlenumber + " - " + suggestion.articledescription}
    </div>
);
class EditOrderRow extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props)
		this.state = {
			articleselection:[],
			value: '',
			suggestions:[],
			orderrow: Object.assign({}, this.props.orderrow),
			availableCredits: 0,
		}
	}

	componentDidMount() {
		this._isMounted = true;
		var that = this;
		NetworkService.getSimpleArticlesList(that.props.session, function (article) {
			if (that._isMounted) {
				that.setState({ articles: article,articleselection:article })
			}
		}, function (err) {
			console.log(err);
		});

		NetworkService.getSimpleClaimsList(that.props.session, function (claim) {
			if (that._isMounted) {
				that.setState({ claims: claim })
			}
		}, function (err) {
			console.log(err);
		});

		this._updateAvailableAmount();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.orderrow.articleid !== this.state.orderrow.articleid || prevState.orderrow.siteid !== this.state.orderrow.siteid || prevState.orderrow.claimid !== this.state.orderrow.claimid) {
			this._updateAvailableAmount();
		}
	}

	_handleChange(type, val) {
		let order = Object.assign({}, this.state.order);
		order[type] = val;
		this.setState({ order })
	}

	_handleOrderRowChange(type, val) {

		if (type === "articleid") {
			var artwarn = document.getElementById("artwarn");
            if(artwarn){
                if(artwarn.classList.contains('err-visible')){                
                    artwarn.classList.remove('err-visible');                
                }
            }
		}

		if (type === "claimid") {
			var claimelem = document.getElementById("claimelem");
			if (claimelem) {
				if (claimelem.classList.contains('error')) {
					claimelem.classList.remove('error');
				}
			}
		}

		if (type === "amount") {
			var amntelem = document.getElementById("amntelem");
			if (amntelem) {
				if (amntelem.classList.contains('error')) {
					amntelem.classList.remove('error');
				}
			}
		}

		let orderrow = Object.assign({}, this.state.orderrow);
		orderrow[type] = val;
		this.setState({ orderrow })
	}

	_createArticleItems() {
		let items = [];
		items.push(<option key="artllistinit" value="">{i18n.t("common_select_article")}</option>);
		if (this.state.articles && this.state.articles.length > 0) {
			for (let i = 0; i < this.state.articles.length; i++) {
				items.push(<option key={i} value={this.state.articles[i].articleid}>{this.state.articles[i].articlenumber + " - " + this.state.articles[i].articledescription}</option>);
			}
		}
		return items;
	}

	_createClaimItems() {
		let items = [];
		items.push(<option key="clmlistinit" value="">{i18n.t("common_select_claim")}</option>);
		if (this.state.claims && this.state.claims.length > 0) {
			for (let i = 0; i < this.state.claims.length; i++) {
				items.push(<option key={i} value={this.state.claims[i].claimid}>{this.state.claims[i].name}</option>);
			}
		}
		return items;
	}

	_renderOrderRows() {
		let rows = [];
		var hasAccess = helper.checkAccess(this.props.session.scope, "ORDERROW", 1, 1)
		if (this.state.order.orderrow && this.state.order.orderrow.length > 0) {
			rows = this.state.order.orderrow.map((item, i) => {
				let article = Object.assign({}, this.state.articles.find(article => article.articleid === parseInt(item.articleid)));
				let claim = Object.assign({}, this.state.claims.find(claim => claim.claimid === parseInt(item.claimid)));
				return (
					<tr key={i}>
						<td>{article.articlenumber + " - " + article.articledescription}</td>
						<td>{claim.name}</td>
						<td>{item.cubicmeter}</td>
						<td>{item.mixpercent}</td>
						<td>
							{item.warning ? <span data-toggle="tooltip" title={i18n.t("orderrow_warning")}> <i className="fa fa-exclamation-triangle" style={{ color: 'orange' }}></i></span> : null}
						</td>
						<td>
							{hasAccess ?
								<button type="button" className="close" onClick={e => this._removeOrderRow("orderrow", item)} >
									<span aria-hidden="true">Ã—</span>
									<span className="sr-only">Close</span>
								</button>
								: null
							}
						</td>
					</tr>
				)
			})
		}
		else {
			return <tr><td colSpan="12">{i18n.t("orderrow_message_nodata")}</td></tr>
		}

		return rows;
	}

	_handleDateChange = date => {
		let order = Object.assign({}, this.state.order);
		order.registrationdate = date;
		this.setState({
			order: order
		});
	};

	_handleMethodChange(type, val) {
		var that = this;
		let orderrow = Object.assign({}, that.state.orderrow);
		orderrow.mixpercent = null;
		orderrow.amount = null;
		orderrow.cubicmeter = null;
		orderrow.numberofarticle = null;
		orderrow.articleid = null;
		orderrow.claimid = null;
		that.setState({
			orderrow: orderrow,
			method: val
		});
	}

	_handleCubicChange(type, val) {
		var that = this;
		let orderrow = Object.assign({}, that.state.orderrow);
		if (val < 0) {
			val = 0;
		}

		var cubicelem = document.getElementById("cubicelem");
		if (cubicelem) {
			if (cubicelem.classList.contains('error')) {
				cubicelem.classList.remove('error');
			}
		}

		orderrow[type] = val;
		that.setState({
			orderrow: orderrow
		}, function () {
			if (that.state.orderrow.cubicmeter && that.state.orderrow.mixpercent) {
				let orderrow = Object.assign({}, that.state.orderrow);
				var cal_amt = (orderrow.mixpercent * orderrow.cubicmeter) / 100;
				orderrow.amount = cal_amt;
				that.setState({ orderrow: orderrow })
			}
		});
	}

	_handleMixPercentChange(type, val) {
		let orderrow = Object.assign({}, this.state.orderrow);
		if (val > 100) {
			val = 100;
		}
		orderrow[type] = val;
		var that = this;
		this.setState({
			orderrow: orderrow
		}, function () {
			let orderrow = Object.assign({}, that.state.orderrow);

			if (orderrow.cubicmeter) {
				var cal_amt = (val * this.state.orderrow.cubicmeter) / 100;
				orderrow.amount = cal_amt;
			}
			if (this.state.volume && orderrow.numberofarticle) {
				var cal_amount = (val * this.state.volume * orderrow.numberofarticle) / 100;
				orderrow.amount = cal_amount;
			}
			this.setState({ orderrow: orderrow })
		});
	}

	_handleArticleChange(type, val) {

		if (val) {
			let articleid = val;
			var artwarn = document.getElementById("artwarn");
            if(artwarn.classList.contains('err-visible')){                
                artwarn.classList.remove('err-visible');                
            }
			var that = this;
			let orderrow = Object.assign({}, that.state.orderrow);

			orderrow[type] = articleid;
			NetworkService.getArticle(articleid, that.props.session, function (article) {
				var vol = (article.width * article.height * article.depth) / 1000000000;
				if (that.state.method === "article") {
					if (that.state.orderrow.numberofarticle) {
						orderrow.cubicmeter = vol * that.state.orderrow.numberofarticle;
					}
				}

				that.setState({
					orderrow: orderrow,
					volume: vol
				}, function () {
					let orderrow = Object.assign({}, that.state.orderrow);
					if (orderrow.mixpercent && that.state.orderrow.numberofarticle && that.state.volume) {
						var cal_amount = (orderrow.mixpercent * that.state.volume * that.state.orderrow.numberofarticle) / 100;
						orderrow.amount = cal_amount;
						that.setState({
							orderrow: orderrow,
						});
					}
				});
			}, function (err) {
				console.log(err);
			});
		}
	}

	_handleNumOfArticleChange(type, val) {
		var that = this;
		let orderrow = Object.assign({}, that.state.orderrow);
		if (val < 0) {
			val = 0;
		}

		var numelem = document.getElementById("numelem");
		if (numelem) {
			if (numelem.classList.contains('error')) {
				numelem.classList.remove('error');
			}
		}

		orderrow[type] = val;
		that.setState({
			orderrow: orderrow
		}, function () {
			let orderrow = Object.assign({}, that.state.orderrow);
			if (orderrow.mixpercent && that.state.orderrow.numberofarticle && that.state.volume) {
				var cal_amount = (orderrow.mixpercent * that.state.volume * that.state.orderrow.numberofarticle) / 100;
				orderrow.amount = cal_amount;
				that.setState({
					orderrow: orderrow,
				});
			}

			if (that.state.volume && that.state.orderrow.numberofarticle) {
				var cal_cubicmeter = (that.state.volume * that.state.orderrow.numberofarticle);
				orderrow.cubicmeter = cal_cubicmeter;
				that.setState({
					orderrow: orderrow,
				});
			}

		});
	}

	_updateAvailableAmount() {

		if (this.state.orderrow.articleid && this.state.orderrow.claimid && this.state.orderrow.siteid) {

			if (amountTimeout) {
				clearInterval(amountTimeout);
			}
			//Timeout to avoid spamming server
			amountTimeout = setTimeout(() => {
				NetworkService.getAvailableAmount(this.state.orderrow.articleid, this.state.orderrow.claimid, this.state.orderrow.siteid, this.props.session).then(total => {
					this.setState({ availableCredits: total.sum });
				}, function (err) {
					console.log(err);
				});
			}, 500);
		} else {
			this.setState({ availableCredits: 0 });
		}
	}

	_cancel(){
		this.props.onCancel();
	}

	_saveOrderRow(e){
		e.preventDefault();                       
		this.props.onSave(this.state.orderrow);
	}

	getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let sugg = inputLength === 0 ? [] : this.state.articleselection.filter(art =>
            (art.articlenumber + " - " + art.articledescription).toLowerCase().includes(inputValue)
        );

        return sugg.slice(0, 30);
      };

    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (ev,val) => {
        this._handleArticleChange("articleid",val.suggestion.articleid);
    };

	render() {
		const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: i18n.t("autocomplete_placeholder"),
            value,
            onChange: this.onChange
		};
		return (
			<div className="panel panel-default">
				<form onSubmit={e => this._saveOrderRow(e)}>
					<div className="panel-body">
						<fieldset className="col-md-6">
							<div className="form-group">
								<label>{i18n.t("deposit_common_calcreditby")}</label>
								<select className="form-control" value={this.state.method} onChange={e => this._handleMethodChange("method", e.target.value)}>
									<option value="cubicmeter">{i18n.t("deposit_common_cubicmeter")}</option>
									<option value="article">{i18n.t("deposit_select_article")}</option>
								</select>
							</div>
							<div className="form-group">
								<label>{i18n.t("common_article")}</label>
								<p>{this.state.articleselection.length} {i18n.t("deposit_common_articles")}</p>
                                    <p id="artwarn" className="artwarn">{i18n.t("deposit_articles_no_selection")}</p>
                                    {this.state.articleselection.length > 25?
                                    <Autosuggest
                                        theme={{input:"form-control"}}
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                    />
                                    :
								<select name="select" id="artlelem" className="form-control" value={this.state.orderrow.articleid || ""} onChange={e => this._handleArticleChange("articleid", e.target.value)} required>
									{this._createArticleItems()}
								</select>}
								{this.state.orderrow.articleid && this.state.volume <= 0 && this.state.method === "article" && <label className="warning badge-warning mt-2 ">{i18n.t("deposit_common_article_alert")}</label>}
							</div>
							<div className="form-group">
								<label>{i18n.t("common_claim")}</label>
								<select name="select" id="claimelem" className="form-control" value={this.state.orderrow.claimid || ""} onChange={e => this._handleOrderRowChange("claimid", e.target.value)} required>
									{this._createClaimItems()}
								</select>
							</div>
							{this.state.method === "article" && this.state.volume > 0 &&
								<div className="form-group">
									<label>{i18n.t("deposit_common_noofarticle")}</label>
									<input className="form-control" id="numelem" type="number" value={this.state.orderrow.numberofarticle || ""} onChange={e => this._handleNumOfArticleChange("numberofarticle", e.target.value)} />
								</div>
							}
							{this.state.method === "article" ?
								<div className="form-group">
									<label>{i18n.t("deposit_common_cubicmeter")}</label>
									<p className="form-control">{this.state.orderrow.cubicmeter || ""} </p>
								</div>
								:
								<div className="form-group">
									<label>{i18n.t("deposit_common_cubicmeter")}</label>
									<input className="form-control" id="cubicelem" type="number" value={this.state.orderrow.cubicmeter || ""} onChange={e => this._handleCubicChange("cubicmeter", e.target.value)} />
								</div>
							}
							<div className="form-group">
								<label>{i18n.t("deposit_common_mixpercent")}</label>
								<input className="form-control" id="mxprelem" type="number" min="70" max="100" value={this.state.orderrow.mixpercent || ""} onChange={e => this._handleMixPercentChange("mixpercent", e.target.value)} required/>
							</div>
							<div className="form-group">
								<label>{i18n.t("deposit_common_amount")}{" (" + this.state.availableCredits.toFixed(5) + " " + i18n.t("credit_common_available") + ")"}</label>
								<p>{this.state.orderrow.amount || ""}</p>
								{/*<input className="form-control" type="number" id ="amntelem" step="any" value={this.state.orderrow.amount || ""} onChange={e => this._handleOrderRowChange("amount", e.target.value)}/>*/}
							</div>
						</fieldset>
					</div>
					<div className="modal-footer mt-5">
						<button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
						<button className="btn btn-warning" type="button" onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
					</div>
				</form>
			</div>
		);
	}
};

export default connect(mapStateToProps)(EditOrderRow);
