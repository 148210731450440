import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import NetworkService from '../../services/NetworkService';
import Navbar from '../../components/Navbar';
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {    
    return {
        session: state.session       
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPartner: (user) => dispatch({
            type: 'SET_USER',
            user: user,
        }),
    };
};

class User extends React.Component {  
    _isMounted = false; 
       constructor(props) {
        super(props)
        this.state = {            
            user: {},
            isOpen: false,
			deleteuserid:"",
			userLoading: false,
        } 
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteUser = this._deleteUser.bind(this);          
    }
      
    componentDidMount() { 
        this._isMounted = true;              
        var that = this;   
		this.setState({userLoading:true},function(){
			NetworkService.getUsers(that.props.session, function (user) {             
                if (that._isMounted) {                     
                    that.setState({
                        users: user,
                        userLoading:false,	
                    });
                }
			}, function (err) {
				that.setState({
					userLoading:false,	
				});
			});
		});
    } 
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, userid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deleteuserid: userid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deleteuserid: null
        });        
    } 

    _deleteUser(){               
        var that =this;                       
        NetworkService.deleteUser(that.state.deleteuserid, that.props.session, function (user) {                         
            that.props.push(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _addUser(item){        
        if(item!==undefined){            
            this.props.push(`/user/detail/${item.userid}`);
        }else{            
            this.props.push('/user/add');
        }
    }
    _renderUserRows(){                        
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "USER", 1, 1)         
        if (this.state.users && this.state.users.length > 0) {
        rows =this.state.users.map((item, i) => {                                                                 
            return (
                <tr key={i} className={hasAccess ?"tr-clickable":""} onClick={e => hasAccess ? this._addUser(item) : ""}>                    
                    <td>{item.givenname}&nbsp;{item.surname}</td>
                    <td>{item.telephone}</td>                    
                    <td>{item.email}</td>
                    <td>{item.rolename}</td>
                    <td>{item.partnerdescription}</td>                   
                    {/* [OV] Removed Delete button
                    <td> 
                    {hasAccess ?             
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.userid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>                  */}
                </tr>
            )})} else if(this.state.userLoading){
				return <tr><td colSpan="8" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="8">{i18n.t("user_message_nodata")}</td></tr>
            }
            
        return rows;
    }

    render() {               
        const title = i18n.t("common_users"); 
        var hasAccess =helper.checkAccess(this.props.session.scope, "USER", 1, 1)                         
        return (
                <div id="page-content-wrapper">        
                <Navbar title={title}/>
                <div className="container mt-5">   
                        <div className="row justify-content-center">
                            
                            { hasAccess ?
                            <div className="col-md-4 ">
                                <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addUser()}> <i className="fa fa-plus"></i> {i18n.t("user_button_add")}</button>
                            </div>       
                            : null
                            }                        
                        </div>
                        <table className="table table-striped mt-4 table-responsive-sm">                    
                            <thead>
                            <tr>
                                <th>{i18n.t("site_common_name")}</th>
                                <th>{i18n.t("user_common_telephone")}</th>                                
                                <th>{i18n.t("user_common_email")}</th>
                                <th>{i18n.t("user_common_role")}</th>
                                <th>{i18n.t("user_common_partner")}</th>                               
                                
                            </tr>
                            </thead>
                            <tbody>                   
                            {
                            this._renderUserRows()                            
                            }                    
                            </tbody>
                        
                        </table>
                    </div>
                    <Modal show={this.state.isOpen}
                        onClose={this.onClose}
                        onOk={this._deleteUser}>                    
                        {i18n.t("user_message_delete")}
                    </Modal>
                </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
