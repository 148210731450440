import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
//import { withRouter } from 'react-router-dom';
import {withRouter } from '../services/withRouter'   // [OV] Relaced by new wrapper

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const mapStateToProps = function (state) {
  return {
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (e) => dispatch(e)
  };
}

let parsed;

class ListPagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPage: 1,
      itemsPerPage: 10
    }
    this.selectPage = this.selectPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.back10Pages = this.back10Pages.bind(this);
    this.forward10Pages = this.forward10Pages.bind(this);
  }

  componentDidMount() {
    this.parseSearch();
    if (parsed.page && parseInt(parsed.page)) {
      this.setState({ selectedPage: parseInt(parsed.page) });
    }
    if (parsed.limit && parseInt(parsed.limit)) {
      this.setState({ itemsPerPage: parseInt(parsed.limit) });
    }
  }

  componentDidUpdate(){
    this.parseSearch();
    if (!parsed.page && this.state.selectedPage !== 1) {
      this.setState({ selectedPage: 1 });
    }
  }

  parseSearch() {
    parsed = queryString.parse(this.props.location.search);
  }

  selectPage(value) {
    this.parseSearch();
    if (value !== this.state.selectedPage) {
      this.setState({ selectedPage: value });
      parsed.page = value;

      // [OV] Switched 'push' from 'connected-react-router' to 'props.push' from component
      this.props.push(this.props.location.pathname + "?" + queryString.stringify(parsed));
    }
  }

  nextPage() {
    let pages = Math.ceil(this.props.itemCount / this.state.itemsPerPage);
    if (this.state.selectedPage < pages) {
      this.selectPage(this.state.selectedPage + 1);
    }
  }

  previousPage() {
    if (this.state.selectedPage > 1) {
      this.selectPage(this.state.selectedPage - 1);
    }
  }

  back10Pages() {
    if (this.state.selectedPage > 10) {
      this.selectPage(this.state.selectedPage - 10);
    } else {
      this.selectPage(1);
    }
  }

  forward10Pages() {
    let pages = Math.ceil(this.props.itemCount / this.state.itemsPerPage);
    if ((this.state.selectedPage) + 10 <= pages) {
      this.selectPage(this.state.selectedPage + 10);
    } else {
      this.selectPage(pages);
    }
  }

  lastPage() {
    let pages = Math.ceil(this.props.itemCount / this.state.itemsPerPage);
    if (this.state.selectedPage < pages) {
      this.selectPage(pages);
    }
  }

  firstPage() {
    if (this.state.selectedPage > 1) {
      this.selectPage(1);
    }
  }

  renderPageItems() {
    let pages = Math.ceil(this.props.itemCount / this.state.itemsPerPage);
    let items = [];
    if (pages < 10) {
      for (var i = 0; i < pages; i++) {
        let j = i + 1;
        items.push(<PaginationItem active={j === (this.state.selectedPage)} key={i}><PaginationLink onClick={() => this.selectPage(j)}>{i + 1}</PaginationLink></PaginationItem>);
      }
    } else {
      let pagesAfter = pages - this.state.selectedPage;

      var startPage = this.state.selectedPage - 3 > 0 ? this.state.selectedPage - 3 : 1;
      var endPage = this.state.selectedPage + 3 <= pages ? this.state.selectedPage + 3 : pages;

      if (this.state.selectedPage > 4) {
        items.push(<PaginationItem key="<.."><PaginationLink onClick={() => this.back10Pages()}>...</PaginationLink></PaginationItem>);
      }

      for (let i = startPage; i <= endPage; i++) {
        items.push(<PaginationItem active={i === (this.state.selectedPage)} key={i}><PaginationLink onClick={() => this.selectPage(i)}>{i}</PaginationLink></PaginationItem>);
      }

      if (pagesAfter > 3) {
        items.push(<PaginationItem key=">.."><PaginationLink onClick={() => this.forward10Pages()}>...</PaginationLink></PaginationItem>);
      }

    }

    return items;
  }

  render() {
    return (
      <Pagination size="sm">
        <PaginationItem >
          <PaginationLink onClick={() => this.firstPage()} >
            First
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink onClick={() => this.previousPage()} previous />
        </PaginationItem>
        {this.renderPageItems()}
        <PaginationItem>
          <PaginationLink onClick={() => this.nextPage()} next />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink onClick={() => this.lastPage()} >
            Last
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListPagination));
