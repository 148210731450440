const supplier = (state = [], action) => {
    switch (action.type) {
        case 'SET_SUPPLIER':
            return ({  
                supplierid: action.supplier.supplierid,                        
                userid:action.supplier.userid,
                partnerid:action.supplier.partnerid,
                name:action.supplier.name,
                street:action.supplier.street,
                postcode:action.supplier.postcode,
                city:action.supplier.city,
                country:action.supplier.country,
                verificationinterval:action.supplier.verificationinterval,
                certificatenumber:action.supplier.certificatenumber
            })
        default:
            return state
    }
}

export default supplier