import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import { Link } from 'react-router-dom'
import moment from "moment";
import { saveAs } from 'file-saver'
import EditDelivery from './EditDelivery';
import ModalEdit from '../../components/ModalEdit';

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        delivery: state.delivery
    }
}

class DeliveryDetails extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)          
        this.state = {     
            delivery: Object.assign({},this.props.delivery)        
        }               
    }
    
    componentDidMount() {     
        this._isMounted = true;         
        this._fetchData();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
	}
	
	_fetchData(){
		var that = this;
        if (that.props.match && typeof that.props.match.params.deliveryid !== 'undefined'){       
            NetworkService.getDelivery(that.props.match.params.deliveryid, that.props.session).then(delivery => {         
                NetworkService.getDepositsByDeliveryId(delivery.deliveryid, that.props.session).then(deposits=>{                                      
                    delivery.deposits = deposits;
                    if (that._isMounted) {                  
                        that.setState({
                            delivery: Object.assign({}, delivery)           
                        })
                    }
                }).catch(reason => {
                    console.log(reason);
                });
            }, function (err) {
                console.log(err);
            })
        }
	}

    _renderDepositRows(){        
        let rows = [];        
        if (this.state.delivery.deposits && this.state.delivery.deposits.length > 0) {
        rows =this.state.delivery.deposits.map((item, i) => {
            return (
                <tr key={i}> 
                    <td>{item.article.articlenumber}</td>    
                    <td>{item.user.givenname+" "+item.user.surname}</td>    
                    <td>{item.amount}</td>                        
                    <td>{item.mixpercent}</td>
                    <td>{item.cubicmeter}</td>
                    <td>{item.claim.name}</td>
                    <td>{item.code}</td>
                </tr>
            )})}
            else{
                return <tr><td colSpan="7">{i18n.t("order_message_nodata")}</td></tr>
            }            
        return rows;
    }
    

    _downloadPdf(deliveryid){
        var that = this;        
        NetworkService.getDeliveryPdf(deliveryid, that.props.session).then(blob => {
            saveAs(blob, 'delivery.pdf');
        }).catch((err) => {
            console.log("Error in downloading pdf",err);
        });    
	}
	
	_editDelivery(){
		this.setState({ deliveryIsOpen: true })
	}

    render() {         
        const title = <span><Link className="link" to='/delivery'> {i18n.t('common_deliveries')}</Link> {this.state.delivery.deliverynumber}</span>;     
        return (
                <div id="page-content-wrapper">        
                <Navbar title={title}/> 
                <div className="container mt-5">           
                    <div className="row justify-content-center">
						<div className="col-md-4">
							<button className="btn btn-outline-secondary col-md-12" onClick={e => this._editDelivery()}><i className="fa fa-edit"></i> {i18n.t("delivery_button_edit")}</button>
						</div>
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._downloadPdf(this.state.delivery.deliveryid)}> <i className="fa fa-download"></i> {i18n.t("common_download_pdf")}</button>
                        </div>                 
                    </div>      
                    <div className="col-md-12 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-info-circle"></i>
                                <span>  <strong>{i18n.t("common_information")}</strong></span>   
                            </div>
                            <div className="card-body">
                                <label><strong>{i18n.t("delivery_common_deliverynumber")}</strong> </label>
                                <p>{this.state.delivery.deliverynumber ? this.state.delivery.deliverynumber: ""}</p>
                                <label><strong>{i18n.t("delivery_common_supplier")}</strong> </label>
                                <p>{this.state.delivery.supplier ? this.state.delivery.supplier.name: ""}</p>
                                <label><strong>{i18n.t("delivery_common_site")}</strong> </label>
                                <p>{this.state.delivery.site ? this.state.delivery.site.name: ""}</p>
                                <label><strong>{i18n.t("delivery_common_reference")}</strong> </label>
                                <p>{this.state.delivery.site ? this.state.delivery.reference: ""}</p>
                                <label><strong>{i18n.t("delivery_common_registrationdate")}</strong> </label>
                                <p>{this.state.delivery.registrationdate ?  moment(this.state.delivery.registrationdate).format('YYYY-MM-DD') : ""}</p>                                                                                      
                            </div>                    
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-first-order"></i>
                                <span>  <strong>{i18n.t("common_deposits")}</strong></span>   
                            </div>
                            <div className="card-body">
                                <table className="table table-striped mt-4 table-responsive-sm">                        
                                    <thead>
                                        <tr>   
                                            <th>{i18n.t("common_article")}</th>
                                            <th>{i18n.t("common_user")}</th>
                                            <th>{i18n.t("deposit_common_amount")}</th>                                    
                                            <th>{i18n.t("deposit_common_mixpercent")}</th>                                                          
                                            <th>{i18n.t("deposit_common_cubicmeter")}</th>
                                            <th>{i18n.t("common_claim")}</th>
                                            <th>{i18n.t("deposit_common_code")}</th>
                                        </tr>
                                        </thead>
                                    <tbody>                   
                                        {
                                            this._renderDepositRows()                            
                                        }                    
                                    </tbody>                
                                </table>                   
                            </div>          
                        </div>
                    </div>
					<ModalEdit show={this.state.deliveryIsOpen}
						title={i18n.t("common_delivery")}
						onClose={()=>{this.setState({ deliveryIsOpen: false })}}>                                        
						{
							<EditDelivery onClose={()=>{this.setState({ deliveryIsOpen: false })}} delivery={this.state.delivery} onSave={()=>{this.setState({ deliveryIsOpen: false }); this._fetchData()}}/>
						}
					</ModalEdit>          
				</div>            
			</div>
        );
    }
};

export default connect(mapStateToProps)(DeliveryDetails);
