import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'
import ModalEdit from '../../components/ModalEdit';
import AddSupplierVerification from '../../components/supplierverification/AddSupplierVerification'
import moment from "moment";
import Modal from '../../components/Modal';
import { saveAs } from 'file-saver'

const mapStateToProps = function (state) {
    return {
        session: state.session,
        supplier: state.supplier
    }
}

class SupplierDetails extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            supplier: Object.assign({},this.props.supplier),
            isModalOpen: false,
            deletesupplierverificationid:"",
            selectedFile: null
        }
        this._closeSV = this._closeSV.bind(this);
        this._saveSV = this._saveSV.bind(this);
        this._closeEditCode = this._closeEditCode.bind(this);
        this._saveEditCode = this._saveEditCode.bind(this);
        this._fetchData = this._fetchData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this._deleteSupplierVerification = this._deleteSupplierVerification.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        var that = this;

        if (that.props.match && typeof that.props.match.params.supplierid !== 'undefined'){
            this._fetchData();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _editSupplier(item){
        if(item!==undefined){
            this.props.push(`/supplier/edit/${item.supplierid}`);
        }
    }

    // [OV] Close modal without saving
    _closeEditCode() {
        this.setState({ isPartnerSupplierCodeModalOpen: false });
    }

    // [OV] Save edited partner supplier code (and close modal)
    _saveEditCode() {
        var that = this;
        NetworkService.updatePartnerSupplierCode(
            that.state.partnerSupplierCode,
            that.props.session,
            function (response) {
                // [OV] Refresh partner supplier codes
                that._fetchData();
            }, function (err) {
                console.log(err);
            }
        );
        this.setState({ isPartnerSupplierCodeModalOpen: false });
    }

    _closeSV() {
        this.setState({ svIsOpen: false });
    }

    _saveSV() {
        this.setState({ svIsOpen: false },function(){
			this._fetchData();
		});
    }

    _fetchData(){
		var that = this;
		NetworkService.getSupplier(that.props.match.params.supplierid, that.props.session, function (supplier) {
            if (that._isMounted) {
                that.setState({supplier: Object.assign({}, supplier)})
            }
        }, function (err) {
            console.log(err);
        });
		NetworkService.getSupplierVerificationsBySupplierid(that.props.match.params.supplierid, that.props.session, function (supplierverification) {
			if (that._isMounted) {
				that.setState({supplierverifications:supplierverification})
			}
		}, function (err) {
			console.log("error ",err);
		});
        // [OV] Get partner supplier codes for this supplier
        NetworkService.getPartnerSupplierCodesBySupplierId(
            that.props.match.params.supplierid, 
            that.props.session, 
            function (partnerSupplierCodes) {
                if (that._isMounted) {
                    that.setState({partnerSupplierCodes: partnerSupplierCodes});
                }
            }, function (err) {
                console.log("error ",err);
            }
        );
        // [OV] Get all partners to be able to select partner for new partner supplier code
        if(helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1)) {
            NetworkService.getPartners(
                that.props.session,
                function (partners) {
                    if (that._isMounted) {
                        that.setState({partners: partners});
                    }
                },
                (e)=>{

                }
        )};
    }

    _deleteSupplierVerification(){
		var that =this;
		this.setState({ isModalOpen: false },function(){
			NetworkService.deleteSupplierVerification(that.state.deletesupplierverificationid, that.props.session, function (supplierverification) {
				that._fetchData();
			}, function (err) {
				console.log(err);
			});
		});
    }

    onupload(event, id){
        event.preventDefault(event);
        event.stopPropagation();
        var that= this;
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        },function(){
            const data = new FormData()
            data.append('file', this.state.selectedFile)
            NetworkService.uploadCertificate(id, data, that.props.session, function (uploaded) {
                window.location.reload();
			}, function (err) {
				console.log(err);
			});
        })
    }

    _downloadCertificate(e, id){
        e.preventDefault();
        e.stopPropagation();
        NetworkService.downloadCertificate(id, this.props.session).then(blob => {
            saveAs(blob, 'certificate.pdf');
        }, function (err) {
            console.log("Error in downloading certificate",err);
        });
    }

    _renderSVRows(){
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "SUPPLIERVERIFICATION", 1, 1)
        if (this.state.supplierverifications && this.state.supplierverifications.length > 0) {
        rows =this.state.supplierverifications.map((item, i) => {
            return (
                <tr key={i}>
                    <td onClick={e => hasAccess ?  this.setState({ svIsOpen: true, svitem: item }) : ""}>{item.user.givenname +' '+item.user.surname}</td>
                    <td onClick={e => hasAccess ?  this.setState({ svIsOpen: true, svitem: item }) : ""}>{moment(item.verificationdate).format('YYYY-MM-DD')}</td>
                    <td onClick={e => hasAccess ?  this.setState({ svIsOpen: true, svitem: item }) : ""}>{moment(item.expirydate).format('YYYY-MM-DD')}</td>
                    <td>{item.supplierverificationfile.name ?
                    <i className="fa fa-file-pdf-o" onClick={e => { this._downloadCertificate(e, item.supplierverificationid) }} ></i> :
                    <input type="file" name="file" accept="application/pdf" onChange={e=> this.onupload(e, item.supplierverificationid)}/>}</td>
                    <td>
                    {hasAccess ?
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.supplierverificationid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>
                </tr>
            )})}
            else{
                return <tr><td colSpan="4">{i18n.t("supplierverification_message_nodata")}</td></tr>
            }

        return rows;
    }

    toggleModal(e, supplierverificationid){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            deletesupplierverificationid: supplierverificationid
        });
    }

    onModalClose(){
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            deletesupplierverificationid: null
        });
    }

    // [OV] Delete partner supplier code
    _deletePartnerSupplierCode = (e, suppliercodeid) => {
        e.preventDefault();
        e.stopPropagation();
        var that = this;
        NetworkService.deletePartnerSupplierCode(
            suppliercodeid,
            that.props.session,
            function (response) {
                // [OV] Refresh partner supplier codes
                that._fetchData();
            }, function (err) {
                console.log(err);
            }
        );
    }

    // [OV] Open modal for editing partner supplier code
    _editPartnerSupplierCode = (e, partner_x_suppliercodeid, partnerDescription) => {
        e.preventDefault();
        e.stopPropagation();
        var that = this;
        NetworkService.getPartnerSupplierCode(
            partner_x_suppliercodeid,
            that.props.session,
            function (response) {
                console.log("response: ", response);
                let newCode = Object.assign({}, response);
                // [OV] Open modal for editing partner supplier code
                that.setState({
                    partnerSupplierCode: newCode,
                    partnerDescription: partnerDescription,
                    isPartnerSupplierCodeModalOpen: true
                });
            }, function (err) {
                console.log(err);
            }
        );
    }

    // [OV] When the user types in into the input field for editing partner supplier code
    _changeEditCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var partnerSupplierCode = Object.assign({}, this.state.partnerSupplierCode);
        partnerSupplierCode.suppliercode = e.target.value;
        this.setState({partnerSupplierCode: partnerSupplierCode });
    }

    // [OV] Handle input to select partner for new partner specific supplier code
    _changeAddCodePartner = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let selectedPartner = JSON.parse(e.target.value);
        this.setState({selectedPartner: selectedPartner});
    }

    // [OV] Save new partner specific supplier code on OK
    _saveAddCode = () => {
        var that = this;
        let partnerSupplierCode = Object.assign({}, this.state.partnerSupplierCode);
        partnerSupplierCode.supplierid = this.state.supplier.supplierid;
        partnerSupplierCode.partnerid = this.state.selectedPartner.partnerid;
        NetworkService.createPartnerSupplierCode(
            partnerSupplierCode,
            that.props.session,
            function (response) {
                // [OV] Refresh partner supplier codes
                that._fetchData();
            }, function (err) {
                console.log(err);
            }
        );
        this.setState({
            isAddPartnerSupplierCodeModalOpen: false,   // Close modal
            partnerSupplierCode: null,
            selectedPartner: null,
        });
    }

    // [OV] Close modal without saving
    _closeAddCode = () => {
        this.setState({ 
            isAddPartnerSupplierCodeModalOpen: false,   // Close modal
            selectedPartner: null,
        });
    }

    // [OV] Render card with partner supplier codes for this supplier
    _renderPartnerSupplierCodes = ()=> {
        var hasEditAccess = helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1)
        return (
            <div className="card mt-5">
                <div className="card-header">
                    <i className="fa fa-address-book-o"></i>
                    <span>  <strong>{i18n.t("supplier_common_partner_supplier_codes")}</strong></span>
                </div>
                <div className="card-body">
                    <table className="table table-striped mt-4 table-responsive-sm">
                        <thead>
                            <tr>
                                <th>{i18n.t("common_partner")}</th>
                                <th>{i18n.t("supplier_common_code")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.partnerSupplierCodes
                                // && this.state.partnerSupplierCodes.length > 0
                                && 
                                this.state.partnerSupplierCodes.map((item, index) => (
                                <tr key={"suppliercode"+index}>
                                    <td>{item.description}</td>
                                    <td>{item.suppliercode}</td>
                                    <td>{hasEditAccess?<div>
                                        <i 
                                            className="fa fa-edit pointer" 
                                            onClick={e => this._editPartnerSupplierCode(e, item.partner_x_suppliercodeid, item.description, this.state.supplier.name)}
                                        >
                                        </i>
                                        <span>&nbsp;&nbsp;</span>
                                        <i 
                                            className="fa fa-trash-o red pointer" 
                                            onClick={e => this._deletePartnerSupplierCode(e, item.partner_x_suppliercodeid)}
                                        >
                                    </i></div>:""}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Button to add new partner specific supplier code */}
                    <div className="row justify-content-center">
                        <div className="col-md-12 ">
                            <button 
                                className="btn btn-outline-secondary col-md-12" 
                                onClick={
                                    e => { 
                                        this.setState({ 
                                            isAddPartnerSupplierCodeModalOpen: true, // Open modal
                                            partnerSupplierCode: null, 
                                        }) 
                                    }
                                }
                            > 
                                <i className="fa fa-plus"/> &nbsp;&nbsp;
                                {i18n.t("supplier_button_add_partner_supplier_code")}
                                {this.state.supplier.name}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const title = <p><Link className="link" to='/supplier'> {i18n.t('common_suppliers')}</Link> {this.state.supplier.name}</p>;
        var hasAccess = helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1);
        var isStandard = (this.props.session.scope.role === "Standard");
        var hasSupplierVerificationAccess =helper.checkAccess(this.props.session.scope, "SUPPLIERVERIFICATION", 1, 1);

        return (
            <div id="page-content-wrapper">
                <Navbar title={title}/>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    { hasAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editSupplier(this.state.supplier)}> <i className="fa fa-edit"></i> {i18n.t("supplier_button_edit")}</button>
                    </div>
                    : null
                    }
                    { hasSupplierVerificationAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => { this.setState({ svIsOpen: true, svitem: null  }) }}> <i className="fa fa-plus"></i> {i18n.t("supplierverification_button_add")}</button>
                    </div>
                    : null
                    }
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 ">
                        <nav className="tabs">
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">{i18n.t("common_information")}</a>
                                <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{i18n.t("common_supplierverification")}</a>
                            </div>
                        </nav>
                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="card mt-5">
                                            <div className="card-header">
                                                <i className="fa fa-info-circle"></i>
                                                <span>  <strong>{i18n.t("common_information")}</strong></span>
                                            </div>
                                            <div className="card-body">
                                                <label><strong>{i18n.t("supplier_common_name")}</strong></label>
                                                <p>{this.state.supplier.name || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_user")}</strong></label>
                                                <p>{this.state.supplier.user ? (this.state.supplier.user.givenname +' '+ this.state.supplier.user.surname): ""}</p>
                                                <label><strong>{i18n.t("supplier_common_partner")}</strong></label>
                                                <p>{this.state.supplier.partner ?  this.state.supplier.partner.description : ""}</p>
                                                <label><strong>{i18n.t("supplier_common_verificationinterval")}</strong></label>
                                                <p>{this.state.supplier.verificationinterval || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_certificatenumber")}</strong></label>
                                                <p>{this.state.supplier.certificatenumber || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_secondarycertificatenumber")}</strong></label>
                                                <p>{this.state.supplier.secondarycertificatenumber || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_code")}</strong></label>
                                                <p>{this.state.supplier.code || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_articlegroup")}</strong></label>
                                                {this.state.supplier.articlegroup && this.state.supplier.articlegroup.map((item, index) => (
                                                    <div key={"articlegroup"+index}>{item.name}</div>
                                                ))}
                                            </div>                    
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="card mt-5">
                                            <div className="card-header">
                                                <i className="fa fa-address-book"></i>
                                                <span>  <strong>{i18n.t("common_address")}</strong></span>
                                            </div>
                                            <div className="card-body">
                                                <label><strong>{i18n.t("supplier_common_street")}</strong></label>
                                                <p>{this.state.supplier.street || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_postcode")}</strong></label>
                                                <p>{this.state.supplier.postcode || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_city")}</strong></label>
                                                <p>{this.state.supplier.city || ""}</p>
                                                <label><strong>{i18n.t("supplier_common_country")}</strong></label>
                                                <p>{this.state.supplier.country || ""}</p>
                                            </div>
                                        </div>
                                        {/* Card showing codes that different partners use for this supplier */}
                                        {/* Show only if there are partner supplier codes for this supplier */}
                                        {hasAccess 
                                        //  && this.state.partnerSupplierCodes 
                                        //  && this.state.partnerSupplierCodes.length > 0 
                                         && this._renderPartnerSupplierCodes()
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <table className="table table-striped mt-4 table-responsive-sm">
                                <thead>
                                    <tr>
                                        <th>{i18n.t("supplierverification_common_user")}</th>
                                        <th>{i18n.t("supplierverification_common_verificationdate")}</th>
                                        <th>{i18n.t("supplierverification_common_expirydate")}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{this._renderSVRows()}</tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal for editing the partner specific supplier codes for a supplier */}
            <ModalEdit show={this.state.isPartnerSupplierCodeModalOpen}
                title={i18n.t("common_partner") + " " + i18n.t("common_supplier") + " " + i18n.t("supplier_common_code")}
                onClose={this._closeEditCode}>
                {
                    <div>
                        <label>
                            {i18n.t("common_button_edit") + " " + 
                             i18n.t("common_supplier") + " " + 
                             this.state.supplier.name + " " + 
                             i18n.t("supplier_common_code") + " (" + 
                             i18n.t("common_partner") + " " +
                             this.state.partnerDescription +  ")"
                            }
                        </label>
                        <input 
                            id="partner-supplier-code"
                            className="form-control"
                            placeholder={i18n.t("supplier_common_code")}


                            type="text" 
                            // This is to wait for the state to be updated
                            value={this.state.partnerSupplierCode?this.state.partnerSupplierCode.suppliercode:"" } 
                            onChange={this._changeEditCode} 
                            onClose={this._closeEditCode}
                        />
                        <br/>
                        <button className="btn btn-primary pull-right " onClick={this._saveEditCode}>{i18n.t("common_button_ok")}</button>
                    </div>
                }
            </ModalEdit>
            {/* Modal for adding partner specific supplier code for this supplier */}
            <ModalEdit show={this.state.isAddPartnerSupplierCodeModalOpen}
                title={i18n.t("common_button_add") + " " + i18n.t("common_partner") + " " + i18n.t("common_supplier") + " " + i18n.t("supplier_common_code")}
                onClose={this._closeAddCode}>
                {
                    <div>
                        <label>
                            {i18n.t("common_button_add") + " " +
                                i18n.t("common_supplier") + " " +
                                this.state.supplier.name + " " +
                                i18n.t("supplier_common_code")
                            }
                        </label>
                        {/* Drop-down list of all partners to choose from */}
                        <select
                            id="partner-supplier-code-select"
                            className="form-control "
                            placeholder={i18n.t("common_partner")}
                            value={this.state.partner ? this.state.partner.partnerid : ""}
                            onChange={this._changeAddCodePartner}
                            onClose={this._closeAddCode}
                        >
                            <option value={
                                this.state.selectedPartner?
                                this.state.selectedPartner:
                                ""
                            }>{
                                this.state.selectedPartner?
                                this.state.selectedPartner.description:
                                i18n.t("common_partner")
                            }
                            </option>
                            {this.state.partners && this.state.partners.map((item, index) => (
                                // Trick to pass the whole object as value
                                <option key={"partner"+index} value={JSON.stringify(item)}>{item.description}</option>
                            ))}
                        </select>
                        <br />  
                        {/* Input field for the partner specific supplier code */}
                        <input
                            id="partner-supplier-code-input"
                            className="form-control "
                            placeholder={i18n.t("supplier_common_code")}
                            type="text"
                            // This is to wait for the state to be updated
                            value={this.state.partnerSupplierCode ? this.state.partnerSupplierCode.suppliercode : ""}
                            onChange={this._changeEditCode}
                            onClose={this._closeAddCode}
                        />
                        <br />
                        <button className="btn btn-primary pull-right " onClick={this._saveAddCode}>{i18n.t("common_button_ok")}</button>
                    </div>
                }
            </ModalEdit>
            <ModalEdit show={this.state.svIsOpen}
                title={i18n.t("common_supplierverification")}
                onClose={this._closeSV}>
                {
                   <AddSupplierVerification that={this} item={this.state.svitem} onClose={this._closeSV} onSave={this._saveSV}/>
                }
            </ModalEdit>
            <Modal show={this.state.isModalOpen}
                onClose={this.onModalClose}
                onOk={this._deleteSupplierVerification}>
                {i18n.t("supplierverification_message_delete")}
            </Modal>
        </div>
        );
    }
};

export default connect(mapStateToProps)(SupplierDetails);
