import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'

const mapStateToProps = function (state) {
    return {
        session: state.session,
        partner: state.partner
    }
}

class PartnerDetails extends React.Component {  
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {     
            partner: Object.assign({},this.props.partner)
        }                        
    }

    componentDidMount() {  
        this._isMounted = true;      
        var that = this;           
        if (that.props.match && typeof that.props.match.params.partnerid !== 'undefined'){       
            NetworkService.getPartner(that.props.match.params.partnerid, that.props.session).then(partner => {
				if(partner.userid){
					NetworkService.getUser(partner.userid, that.props.session).then(value=>{
						partner.user = value;
						if (that._isMounted) {                  
							that.setState({
								partner: Object.assign({}, partner)           
							})
						}
					}).catch(reason => {
						console.log(reason);
					});;
				} else {
					that.setState({
						partner: Object.assign({}, partner)           
					});
				}
            }, function (err) {
                console.log(err);
            })
        }       
    }       

    componentWillUnmount() {
        this._isMounted = false;
    }

    _editPartner(item){          
        if(item!==undefined){                                   
            this.props.push(`/partner/edit/${item.partnerid}`);
        }
    }

    render() {                
        const title = <p><Link className="link" to='/partner'> {i18n.t('common_partners')}</Link> {this.state.partner.description}</p>;
        var hasAccess =helper.checkAccess(this.props.session.scope, "PARTNER", 1, 1)            
        return (
            <div id="page-content-wrapper">
                <Navbar title={title} />
            <div className="container mt-5">   
                <div className="row justify-content-center">
                    { hasAccess ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editPartner(this.state.partner)}> <i className="fa fa-edit"></i> {i18n.t("partner_button_edit")}</button>
                    </div>       
                    : null
                    }                              
                </div>                      
                <div>
                    <div className="card mt-5">
                        <div className="card-header">
                            <i className="fa fa-info-circle"></i>
                            <span>  <strong>{i18n.t("common_information")}</strong></span>   
                        </div>
                        <div className="card-body">
                            <label><strong>{i18n.t("partner_common_description")}</strong></label>
                            <p>{this.state.partner.description || ""}</p>
                            <label><strong>{i18n.t("partner_common_corporateid")}</strong></label>
                            <p>{this.state.partner.corporateid || ""}</p>
                            <label><strong>{i18n.t("partner_common_user")}</strong></label>
                            <p>{(this.state.partner.user) ? this.state.partner.user.givenname+' '+this.state.partner.user.surname : ""}</p>
                        </div>                    
                    </div>
                </div>                                    
            </div> 
        </div>
        );
    }
};

export default connect(mapStateToProps)(PartnerDetails);
