import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSite: (site) => dispatch({
            type: 'SET_SITE',
            site: site,
        }),
    };
};

class Site extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            site: {},
            isOpen: false,
			deletesiteid:"",
			siteLoading:false,
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteSite = this._deleteSite.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
		var that = this;
		this.setState({siteLoading:true},function(){
			NetworkService.getSites(that.props.session, function (site) {
				if (that._isMounted) {
					that.setState({sites:site,siteLoading:false});
				}
			}, function (err) {
				console.log(err);
				that.setState({siteLoading:false});
			});
		})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, siteid){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
          isOpen: !this.state.isOpen,
          deletesiteid: siteid
        });
    }

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletesiteid: null
        });
    }

    _deleteSite(){
        var that =this;
        NetworkService.deleteSite(that.state.deletesiteid, that.props.session, function (site) {
            that.props.push(0);
        }, function (err) {
            console.log(err);
        });
    }

    _renderSiteRows(){
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "SITE", 1, 1)
        if (this.state.sites && this.state.sites.length > 0) {
        rows =this.state.sites.map((item, i) => {
            return (
                <tr key={i} className={hasAccess ?"tr-clickable":""} onClick={e => hasAccess ? this._addSite(item) : ""}>       
                    <td>{item.certificatenumber}</td>
                    <td>{item.name}</td>               
                    <td>{item.partner.description}</td>
                    <td>{item.user.givenname+' '+item.user.surname}</td>
                    <td>{item.street}<br/>{item.postcode+' '+item.city}<br/>{item.country}</td>
                    <td>{item.code}</td>
                    
                    <td>
                    {hasAccess ?
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.siteid)}>{i18n.t("common_button_inactivate")}</button>
                        : null
                    }
                    </td>
                </tr>
            )})} else if(this.state.siteLoading){
				return <tr><td colSpan="8" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="8">{i18n.t("site_message_nodata")}</td></tr>
            }

        return rows;
    }

    _addSite(item){
        if(item!==undefined){
            this.props.setSite({
                siteid: item.siteid,
                name: item.name,
                partnerid: item.partnerid,
                userid: item.userid,
                street: item.street,
                postcode: item.postcode,
                city: item.city,
                country: item.country,
                code:item.code
            });
            this.props.push(`/site/detail/${item.siteid}`);
        }else{
            this.props.push('/site/add');
        }
    }

    render() {
        const title = i18n.t("common_sites");
        var hasAccess =helper.checkAccess(this.props.session.scope, "SITE", 1, 1)
        return (
            <div id="page-content-wrapper">
                <Navbar title={title}/>
                <div className="container mt-5">
                    <div className="row justify-content-center">

                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addSite()}> <i className="fa fa-plus"></i> {i18n.t("site_button_add")}</button>
                        </div>
                        : null
                        }
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">
                        <thead>
                        <tr>
                            <th>{i18n.t("site_common_certificatenumber")}</th>
                            <th>{i18n.t("site_common_name")}</th>
                            <th>{i18n.t("site_common_partner")}</th>
                            <th>{i18n.t("site_common_user")}</th>
                            <th>{i18n.t("common_address")}</th>
                            <th>{i18n.t("site_common_code")}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this._renderSiteRows()
                        }
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteSite}>
                    {i18n.t("site_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Site);
