const user = (state = {}, action) => {
    switch (action.type) {
        case 'SET_USER':
            return ({
                userid: action.user.userid,
                partnerid: action.user.partnerid,
                siteid: action.user.siteid,
                roleid: action.user.roleid,
                givenname: action.user.givenname,
                surname: action.user.surname,
                telephone: action.user.telephone,
                email: action.user.email,
            })
        default:
            return state
    }
}

export default user