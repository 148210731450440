import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import i18n from "../../services/i18n";

const mapStateToProps = function (state) {   
    return {
        session: state.session,
        deposit: state.deposit
    }
}

class EditDeposit extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)

        if (typeof this.props.match.params.depositid == 'undefined'){
            this.state = {     
                deposit:{                      
                    amount:"",
                    mixpercent:"",
                    reference:"",
                    location:"",
                    siteid:"",
                    supplierid:"",
                    articleid:"",
                    userid:"",                    
                    certificateid:"",
                    registrationdate: moment().toDate(),
                    expirydate: moment().toDate()
                }
            }      
        }else{
            this.state = {     
                deposit: Object.assign({},this.props.deposit)
            } 
        }                     
    }
    
    componentDidMount() { 
        this._isMounted = true;         
        var that = this;  
        
        if (typeof that.props.match.params.depositid !== 'undefined'){       
            NetworkService.getDeposit(that.props.match.params.depositid, that.props.session, function (deposit) {             
                if (that._isMounted) {                                     
                    that.setState({deposit: Object.assign({}, deposit)})            
                }
            }, function (err) {
                console.log(err);
            });
        }
        
        NetworkService.getSimpleUsersList(that.props.session, function (user) {     
            if (that._isMounted) {             
                that.setState({users:user})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleSuppliersList(that.props.session, function (supplier) {   
            if (that._isMounted) {                
                that.setState({suppliers:supplier})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleSitesList(that.props.session, function (site) {    
            if (that._isMounted) {               
                that.setState({sites:site})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleArticlesList(that.props.session, function (article) {      
            if (that._isMounted) {             
                that.setState({articles:article})            
            }
        }, function (err) {
            console.log(err);
        });
        NetworkService.getSimpleCertificatesList(that.props.session, function (certificate) {    
            if (that._isMounted) {               
                that.setState({certificates:certificate})            
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }    

    _cancel(){                
        this.props.push('/deposit');
    } 

    _handleChange(type, val){        
        let deposit = Object.assign({},this.state.deposit);             
        deposit[type] = val;  
        this.setState({
            deposit: deposit           
        });                   
    }
    
     _handleRegistrationDateChange = date => {
        let deposit = Object.assign({},this.state.deposit);  
        deposit.registrationdate = date;        
         this.setState({
            deposit: deposit           
        });
    };

    _handleExpiryDateChange = date => {
        let deposit = Object.assign({},this.state.deposit);   
        deposit.expirydate = date;        
         this.setState({
            deposit: deposit           
        });
    };

    createSiteItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_site")}</option>);
        if(this.state.sites && this.state.sites.length>0){
            for (let i = 0; i < this.state.sites.length; i++) {
                items.push(<option key={i} value={this.state.sites[i].siteid}>{this.state.sites[i].name}</option>);
            }
        }

        return items;
    }

    createUserItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_user")}</option>);
        if(this.state.users && this.state.users.length>0){
            for (let i = 0; i < this.state.users.length; i++) {
                items.push(<option key={i} value={this.state.users[i].userid}>{this.state.users[i].givenname+' '+this.state.users[i].surname}</option>);
            }
        }
        return items;
    }

    createSupplierItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_supplier")}</option>);
        if(this.state.suppliers && this.state.suppliers.length>0){
            for (let i = 0; i < this.state.suppliers.length; i++) {
                items.push(<option key={i} value={this.state.suppliers[i].supplierid}>{this.state.suppliers[i].name}</option>);
            }
        }

        return items;
    }
    createArticleItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_article")}</option>);
        if(this.state.articles && this.state.articles.length>0){
            for (let i = 0; i < this.state.articles.length; i++) {
                items.push(<option key={i} value={this.state.articles[i].articleid}>{this.state.articles[i].articlenumber}</option>);
            }
        }

        return items;
    }

    createCertificateItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_certificate")}</option>);
        if(this.state.certificates && this.state.certificates.length>0){
            for (let i = 0; i < this.state.certificates.length; i++) {
                items.push(<option key={i} value={this.state.certificates[i].certificateid}>{this.state.certificates[i].name}</option>);
            }
        }

        return items;
    }

    _saveDeposit(e){  
        e.preventDefault();                   
        var that =this;       
        let deposit = Object.assign({},that.state.deposit);            
        deposit.registrationdate = moment(deposit.registrationdate).format('YYYY-MM-DD');          
        deposit.expirydate = moment(deposit.expirydate).format('YYYY-MM-DD'); 

        if(deposit.depositid!=null || deposit.depositid!==undefined){
            NetworkService.updateDeposit(deposit, that.props.session, function (deposit) {            
                that.props.push('/deposit');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createDeposit(deposit, that.props.session, function (deposit) {                         
                that.props.push('/deposit');                       
            }, function (err) {
                console.log(err);
            });
        } 
    } 

    render() {  
        const title ="Add/Edit Deposit";         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">      
                <form onSubmit={e => this._saveDeposit(e)}>        
                    <div className="panel-body">                   
                        <fieldset className="col-md-6 mt-5"> 
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_amount")}</label>
                                <input className="form-control" type="number" step="any" value={this.state.deposit.amount || ""} onChange={e => this._handleChange("amount", e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_mixpercent")}</label>
                                <input className="form-control" type="number" min="70" max="100" value={this.state.deposit.mixpercent || ""} onChange={e => this._handleChange("mixpercent", e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_reference")}</label>
                                <input className="form-control" type="text" value={this.state.deposit.reference || ""} onChange={e => this._handleChange("reference", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_location")}</label>
                                <input className="form-control" type="text" value={this.state.deposit.location || ""} onChange={e => this._handleChange("location", e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_site")}</label>                               
                                 <select name="select" className="form-control" value={this.state.deposit.siteid || ""} onChange={e => this._handleChange("siteid", e.target.value)} required>
                                    {this.createSiteItems()}
                                </select>                               
                            </div>       
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_supplier")}</label>                               
                                 <select name="select" className="form-control" value={this.state.deposit.supplierid || ""} onChange={e => this._handleChange("supplierid", e.target.value)} required>
                                    {this.createSupplierItems()}
                                </select>                               
                            </div>   
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_article")}</label>                               
                                 <select name="select" className="form-control" value={this.state.deposit.articleid || ""} onChange={e => this._handleChange("articleid", e.target.value)} required>
                                    {this.createArticleItems()}
                                </select>                               
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_user")}</label>                               
                                 <select name="select" className="form-control" value={this.state.deposit.userid || ""} onChange={e => this._handleChange("userid", e.target.value)} required>
                                    {this.createUserItems()}
                                </select>                               
                            </div>  
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_certificate")}</label>                               
                                 <select name="select" className="form-control" value={this.state.deposit.certificateid || ""} onChange={e => this._handleChange("certificateid", e.target.value)} required>
                                    {this.createCertificateItems()}
                                </select>                               
                            </div>                       
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_registrationdate")}</label>
                                <div>
                                    <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.deposit.registrationdate ? moment(this.state.deposit.registrationdate).toDate() : null} value={moment(this.state.deposit.registrationdate).toDate()}  onChange={this._handleRegistrationDateChange} required/>
                                </div>                                
                            </div> 
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_expirydate")}</label>
                                <div>
                                    <DatePicker className={"form-control"} dateFormat="yyyy-MM-dd" selected={this.state.deposit.expirydate ? moment(this.state.deposit.expirydate).toDate() : null} value={moment(this.state.deposit.expirydate).toDate()}  onChange={this._handleExpiryDateChange} />
                                </div>                                
                            </div>  
                            
                        </fieldset>                                  
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div> 
                </form>  
                </div>                             
            </div>
       
        );
    }
};

export default connect(mapStateToProps)(EditDeposit);
