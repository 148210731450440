import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import moment from "moment";
import Modal from '../../components/Modal';
import helper from '../../services/helper'
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSupplierVerification: (supplierverification) => dispatch({
            type: 'SET_SUPPLIERVERIFICATION',
            supplierverification: supplierverification,
        }),
    };
};

class SupplierVerification extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)
        this.state = {            
            supplierverification: {},
            isOpen: false,
			deletesupplierverificationid:"",
			supplierVerificationLoading:false,
        } 
        this.toggleModal = this.toggleModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this._deleteSupplierVerification = this._deleteSupplierVerification.bind(this);           
    }

    componentDidMount() {    
        this._isMounted = true;       
		var that = this;
		this.setState({supplierVerificationLoading:true},function(){
			NetworkService.getSupplierVerifications(that.props.session, function (supplierverification) {    
				if (that._isMounted) {                    
					that.setState({supplierverifications:supplierverification, supplierVerificationLoading:false});
				}
			}, function (err) {
				console.log(err);
				that.setState({supplierVerificationLoading:false});
			});
		})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    toggleModal(e, supplierverificationid){
        e.preventDefault();        
        e.stopPropagation();               
        this.setState({
          isOpen: !this.state.isOpen,
          deletesupplierverificationid: supplierverificationid
        });        
    }     

    onClose(){
        this.setState({
          isOpen: !this.state.isOpen,
          deletesupplierverificationid: null
        });        
    } 

    _deleteSupplierVerification(){               
        var that =this;                       
        NetworkService.deleteSupplierVerification(that.state.deletesupplierverificationid, that.props.session, function (supplierverification) {                         
            that.props.history.go(0);     
        }, function (err) {
            console.log(err);
        });       
    }

    _renderSupplierVerificationRows(){        
        let rows = [];
        var hasAccess =helper.checkAccess(this.props.session.scope, "SUPPLIERVERIFICATION", 1, 1)     
        if (this.state.supplierverifications && this.state.supplierverifications.length > 0) {
        rows =this.state.supplierverifications.map((item, i) => {                                                     
            return (
                <tr key={i} onClick={e => hasAccess ? this._addSupplierVerification(item) : ""}>                    
                    <td>{item.supplier.name}</td>        
                    <td>{item.user.givenname+' '+item.user.surname}</td>    
                    <td>{moment(item.verificationdate).format('YYYY-MM-DD')}</td>  
                    <td>          
                    {hasAccess ?  
                        <button className="pull-right btn btn-danger" onClick={e => this.toggleModal(e, item.supplierverificationid)}>{i18n.t("common_button_delete")}</button>
                        : null
                    }
                    </td>                     
                </tr>
            )})} else if(this.state.supplierVerificationLoading){
				return <tr><td colSpan="4" className="loading-td"><i className="fa fa-spin fa-spinner"></i></td></tr>
			} else {
                return <tr><td colSpan="4">{i18n.t("supplierverification_message_nodata")}</td></tr>
            }
            
        return rows;
    } 
    
    _addSupplierVerification(item){              
        if(item!==undefined){
            this.props.setSupplierVerification({
                supplierverificationid: item.supplierverificationid,    
                supplierid:item.supplierid,                      
                userid: item.userid,                
                verificationdate: item.verificationdate                
            });
            this.props.push(`/supplierverification/edit/${item.supplierverificationid}`);
        }else{     
            this.props.push('/supplierverification/add');
        }

    }

    render() { 
        const title =i18n.t("common_supplierverifications");    
        var hasAccess =helper.checkAccess(this.props.session.scope, "SUPPLIERVERIFICATION", 1, 1)                       
        return (
            <div id="page-content-wrapper">        
                <Navbar title={title}/>    
                <div className="container mt-5">
                    <div className="row justify-content-center">
                        
                        { hasAccess ?
                        <div className="col-md-4 ">
                            <button className="btn btn-outline-secondary col-md-12" onClick={e => this._addSupplierVerification()}> <i className="fa fa-plus"></i> {i18n.t("supplierverification_button_add")}</button>
                        </div>       
                        : null
                        }                              
                    </div>
                    <table className="table table-striped mt-4 table-responsive-sm">                        
                        <thead>
                        <tr>
                            <th>{i18n.t("supplierverification_common_supplier")}</th>
                            <th>{i18n.t("supplierverification_common_user")}</th>
                            <th>{i18n.t("supplierverification_common_verificationdate")}</th>     
                            <th></th>                       
                        </tr>
                        </thead>
                        <tbody>                   
                        {
                            this._renderSupplierVerificationRows()                            
                        }                    
                        </tbody>                
                    </table>          
                </div>
                <Modal show={this.state.isOpen}
                    onClose={this.onClose}
                    onOk={this._deleteSupplierVerification}>                    
                    {i18n.t("supplierverification_message_delete")}
                </Modal>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierVerification);
