import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {    
    return {
        session: state.session,
        user: state.user
    }
}

class EditUser extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)                      
            this.state = {     
                user: (this.props.match && typeof this.props.match.params.userid == 'undefined')?{
                    partnerid:"",
                    siteid:"",
                    roleid:"",
                    givenname:"",
                    surname:"",
                    telephone:"",
                    email:"",
                    password:"",                   
                    site:[],
				}: Object.assign({},this.props.user),
            }
    }
    
    componentDidMount() {  
        this._isMounted = true;          
        var that = this;                       
        if (this.props.match && typeof that.props.match.params.userid !== 'undefined'){       
            NetworkService.getUser(that.props.match.params.userid, that.props.session).then(user =>{
                if (that._isMounted) {                                     
                    that.setState({user: Object.assign({}, user)})            
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getSimpleSitesList(that.props.session, function (site) {    
            if (that._isMounted) {                 
                that.setState({sites:site}) 
            }           
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimplePartnersList(that.props.session, function (partner) {  
            if (that._isMounted) {                    
                that.setState({partners:partner})            
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimpleRolesList(that.props.session, function (role) {  
            if (that._isMounted) {                    
                that.setState({roles:role})            
            }
        }, function (err) {
            console.log(err);
        });
    }    

    componentWillUnmount() {
        this._isMounted = false;
    }

    _cancel(){             
        if(this.props.user.userid){
            this.props.push('/user/detail/'+this.state.user.userid);
        } else {
            this.props.push('/user');
        }
    } 

    _handleChange(type, val){     
        let user = Object.assign({},this.state.user);                 
        user[type] = val;            
        this.setState({
            user: user           
        });          
    }

    _addSite(type, val){      
		let site = Object.assign({}, this.state.sites.find( site => site.siteid === parseInt(val)));        
        this.setState({
            siteitem: site           
        }); 
	}

	_add(){
        let siteitem = Object.assign({}, this.state.siteitem);
        let user = Object.assign({},this.state.user);
		const found = user.site.some(el => el.siteid === parseInt(siteitem.siteid));
        if(siteitem.siteid && !found){
			user.site.push(siteitem);
			this.setState({
				user: user
			});
		}
    }

    _removeSite(type, item){      
        var arr = this.state.user.site || [];
        for( var i = 0; i < arr.length; i++){ 
            if ( arr[i] === item) {
              arr.splice(i, 1); 
            }
        }      
        let user = Object.assign({},this.state.user);                
        user[type] = arr;
        this.setState({
            user: user           
        });     
    }
    
    createSiteItems() {        
        let items = [];
        items.push(<option key="sitelist" value="">{i18n.t("common_select_site")}</option>);
        if(this.state.sites && this.state.sites.length>0){
            for (let i = 0; i < this.state.sites.length; i++) {                
                items.push(<option key={i} value={this.state.sites[i].siteid}>{this.state.sites[i].name}</option>);
            }
        }
        return items;
    }

    createPartnerItems() {        
        let items = [];
        items.push(<option key="partnerlist" value="">{i18n.t("common_select_partner")}</option>);
        if(this.state.partners && this.state.partners.length>0){
            for (let i = 0; i < this.state.partners.length; i++) {
                items.push(<option key={i} value={this.state.partners[i].partnerid}>{this.state.partners[i].description}</option>);
            }
        }
        return items;
    }

    createRoleItems() {        
        let items = [];
        items.push(<option key="rolelist" value="">{i18n.t("common_select_role")}</option>);
        if(this.state.roles && this.state.roles.length>0){
            for (let i = 0; i < this.state.roles.length; i++) {
                items.push(<option key={i} value={this.state.roles[i].roleid}>{this.state.roles[i].name}</option>);
            }
        }
        return items;
    }

    _saveUser(e){      
        e.preventDefault();         
        var that =this;      
        let user = Object.assign({},this.state.user);         
        if(user.userid!=null || user.userid!==undefined){
            NetworkService.updateUser(user, that.props.session, function (user) {                            
                that.props.push('/user');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createUser(user, that.props.session, function (user) {                              
                that.props.push('/user');                       
            }, function (err) {
                console.log(err);
            });
        }        
    } 

    render() {          
        var that = this;       
        const title =i18n.t("user_title_edit");       
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">   
                <form onSubmit={e => this._saveUser(e)}>                                                               
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">  
                            <div className="form-group">
                                <label>{i18n.t("user_common_givenname")}</label>                                        
                                <input className="form-control" type="text" value={that.state.user.givenname || ""} onChange={event => this._handleChange("givenname", event.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("user_common_surname")}</label>                                        
                                <input className="form-control" type="text" value={this.state.user.surname || ""} onChange={event => this._handleChange("surname", event.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("user_common_telephone")}</label>                                        
                                <input className="form-control" type="text" value={this.state.user.telephone || ""} onChange={event => this._handleChange("telephone", event.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("user_common_email")}</label>                                        
                                <input className="form-control" type="text" value={this.state.user.email || ""} onChange={event => this._handleChange("email", event.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("user_common_password")}</label>                                        
                                <input className="form-control" type="text" value={this.state.user.password || ""} onChange={event => this._handleChange("password", event.target.value)} required={this.state.user.userid ? false : true}/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("user_common_role")}</label>                                        
                                <select className="form-control" value={this.state.user.roleid || ""} onChange={e => this._handleChange("roleid", e.target.value)} required>
                                {this.createRoleItems()}                                       
                                </select>
                            </div>                            
                            <div className="form-group">
                                <label>{i18n.t("user_common_partner")}</label>                               
                                 <select name="select" className="form-control" value={this.state.user.partnerid || ""} onChange={e => this._handleChange("partnerid", e.target.value)} >
                                    {this.createPartnerItems()}
                                </select>                               
                            </div>                      
                            <div className="form-group">
								<label>{i18n.t("user_common_site")}</label>
								<div className="row">
                                    <div className="col-md-9 col-sm-12">
                                        <select className="form-control" onChange={e => this._addSite("site", e.target.value)} >
                                            {this.createSiteItems()}
                                        </select> 
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group">
                                            <button className="btn btn-primary col-md-12" type="button" onClick={e => this._add()}>{i18n.t("common_button_add")}</button>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                            <div>
                                {that.state.user.site && that.state.user.site.map(item => (
                                    <div className="alert alert-info alert ng-isolate-scope alert-alert-info alert-dismissible" key={item.siteid}>{item.name}
                                    <button type="button" className="close" onClick={e => this._removeSite("site", item)} >
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                    
                                ))}
                            </div> 
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>  
                </form>                              
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditUser);
