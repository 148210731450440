import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import i18n from '../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

class SidebarMenuStandard extends React.Component {
    render() {
        console.log("SidebarMenuStandard, partnerid: ", this.props.session && this.props.session.scope && this.props.session.scope.partner);
        if (this.props.session.guid) {
            return (
                    <div className="border-right" id="sidebar-wrapper">
                        <div className="sidebar-heading">
                            <Link className="sidebar-logo-link" to="/"><img className="sidebar-logo" src={require("../images/woody_bygghandel_white.png")} alt="woody logo"/></Link>
                        </div>
                        <div className="list-group list-group-flush">
                            <Link to="/" className="list-group-item list-group-item-action"> {i18n.t("common_dashboard").toUpperCase()} </Link>
                            <Link to="/site" className="list-group-item list-group-item-action"> {i18n.t("common_site").toUpperCase()} </Link>
                            <Link to="/article" className="list-group-item list-group-item-action"> {i18n.t("common_article").toUpperCase()} </Link>
                            <Link to="/articlegroup" className="list-group-item list-group-item-action"> {i18n.t("common_articlegroup").toUpperCase()} </Link>
                            <Link to="/supplier" className="list-group-item list-group-item-action"> {i18n.t("common_supplier").toUpperCase()} </Link>
                            {/*<Link to="/supplierverification" className="list-group-item list-group-item-action"> {i18n.t("common_supplierverification").toUpperCase()} </Link>
                            <Link to="/deposit" className="list-group-item list-group-item-action"> {i18n.t("common_deposit").toUpperCase()} </Link>
                            <Link to="/withdrawal" className="list-group-item list-group-item-action"> {i18n.t("common_withdrawal").toUpperCase()} </Link>*/}
                            <Link to="/certificate" className="list-group-item list-group-item-action"> {i18n.t("common_certificate").toUpperCase()} </Link>
                            <Link to="/delivery" className="list-group-item list-group-item-action"> {i18n.t("common_delivery").toUpperCase()} </Link>
                            <Link to="/order" className="list-group-item list-group-item-action"> {i18n.t("common_order").toUpperCase()} </Link>
                            <Link to="/stock" className="list-group-item list-group-item-action"> {i18n.t("common_stock").toUpperCase()} </Link>
                            {/* Show this menu item only if user is a partner (has a partnerid) */}
                            {this.props.session && this.props.session.scope && this.props.session.scope.partner
                            ? <Link to="/importFile" className="list-group-item list-group-item-action"> {i18n.t("common_import").toUpperCase()} </Link>
                            : null}
                        </div>
                    </div>
            );
        }else{
            return null
        }
    }
};

export default connect(mapStateToProps)(SidebarMenuStandard);
