import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import i18n from "../../services/i18n";
import NetworkService from '../../services/NetworkService';
import Alert from '../Alert';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

class CompleteOrderRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {
            },
            ordercode: "",
            message: "",
            orderrow: Object.assign({}, this.props.orderrow)
        }
        this._closeAlert = this._closeAlert.bind(this);
    }

    _cancel() {
        this.props.onClose();
    }

    componentDidMount() {
        this._copyPropsToState();

    }

    _copyPropsToState() {
        var that = this;

        let orderrow = Object.assign({}, this.state.orderrow);
        NetworkService.getDepositCodes(orderrow.articleid, orderrow.claimid, orderrow.siteid, that.props.session).then(value => {
            orderrow.suggestion = value;
            this.setState({ orderrow: orderrow });
        }).catch(err => {
            console.log(err);
        });
    }

    _complete(i, item) {
        var that = this;
        let withdrawal = {};
        withdrawal.amount = item.amount;
        withdrawal.claimid = item.claimid;
        withdrawal.code = item.code || "";
        withdrawal.orderrowid = item.orderrowid;
        withdrawal.articleid = item.articleid;

        NetworkService.completeOrderrow(this.state.order.orderid, withdrawal, this.props.session, function (withdrawal) {
            if (withdrawal.msg) {
                that.setState({
                    showAlert: true,
                    message: withdrawal.msg
                })
            }

            if (withdrawal.success) {
                let order = Object.assign({}, that.state.order);
                var index = order.orderrow.findIndex(x => x.orderrowid === item.orderrowid);
                var orderrow = order.orderrow[index];
                orderrow.completed = 1;
                order.orderrow[index] = orderrow;
                that.setState({
                    order: order
                });
            }

        }, function (err) {
            console.log(err);
        });
    }

    _handleChange(index, type, val) {
        let order = Object.assign({}, this.state.order);
        var row = order.orderrow[index];
        row[type] = val;
        order.orderrow[index] = row
        this.setState({
            order: order
        });
    }

    _handleCodeChange(type, val) {
        this.setState({
            ordercode: val
        });
    }

    _add() {

        let orderrow = Object.assign({}, this.state.orderrow);

        if (!orderrow.code) {
            orderrow.code = []
        }

        var item = orderrow.code.find(item => item === this.state.ordercode);
        if (!item) {
            orderrow.code.push(this.state.ordercode);
        }

        this.setState({
            orderrow: orderrow
        });
    }

    _addCode(code) {
        let orderrow = Object.assign({}, this.state.orderrow);

        if (!orderrow.code) {
            orderrow.code = []
        }

        var item = orderrow.code.find(item => item === code);
        if (!item) {
            orderrow.code.push(code);
        }

        this.setState({
            orderrow: orderrow
        });
    }

    _removeCode(type, val) {
        var arr = this.state.orderrow.code || [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                arr.splice(i, 1);
            }
        }

        let orderrow = Object.assign({}, this.state.orderrow);
        orderrow[type] = arr;
        this.setState({
            orderrow: orderrow
        });
    }

    _save(){
        this.props.onComplete(this.state.orderrow);
    }

    _renderOrderRows() {
        let item = this.state.orderrow;
        return (
            <>
                <div className="row mt-3">
                        <div className="col-md-4">
                            <h4>{i18n.t("common_article")}</h4>
                            <p>{item.article.articlenumber}</p>
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t("common_claim")}</label>
                            <p>{item.claim.name}</p>
                        </div>
                        <div className="col-md-4">
                            <label>{i18n.t("order_common_amount")}</label>
                            <p>{item.amount}</p>
                        </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-7 col-md-8 col-lg-8">

                        <h4 >{i18n.t("common_order")}</h4>

                        {item.completed ? <div><i className="fa fa-check-square-o" style={{ fontSize: "1.5em", color: "green" }}></i></div> :
                            (item.claim.transfer === 0 ? <div><button className="btn btn-success" type="button" onClick={e => this._complete(e, item)}>{i18n.t("common_button_complete")}</button></div>
                                : <div>
                                    <div className="row">
                                        <div className="col-sm-9 col-md-8" style={{ marginBottom: "1rem" }}>
                                            <input className="form-control" type="text" onChange={e => this._handleCodeChange("code", e.target.value)} />
                                        </div>
                                        <div className="col-sm-3 col-md-4" style={{ marginBottom: "1rem" }}>
                                            <button className="btn btn-primary" type="button" onClick={e => this._add()}>{i18n.t("common_button_add")}</button>
                                        </div>
                                    </div>
                                    {item.code && item.code.map((val, index) => (
                                        <div className="row assign" key={"code_" + index}>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="alert alert-info alert ng-isolate-scope alert-alert-info alert-dismissible" >{val}
                                                    <button type="button" className="close" onClick={e => this._removeCode("code", val)} >
                                                        <span aria-hidden="true">×</span>
                                                        <span className="sr-only">Close</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/*<div className="form-group">
                                    <button className="btn btn-success" type="button" disabled={item.code === undefined || (item.code && item.code.length <= 0)} onClick={e => this._complete(item)}>{i18n.t("common_button_complete")}</button>
                                </div>*/}
                                </div>)}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">

                        <h4>{i18n.t("common_code_suggestion")}</h4>
                        {item.completed || item.claim.transfer === 0 ? <p className="card-text">{i18n.t("commom_none")}</p> :
                            <div className="card-text">{item.suggestion && item.suggestion.map((deposit, index) => (
                                <div className="row" key={"code_suggst_" + index}>
                                    <div className="col-12 col-sm-6 col-md-5 col-lg-6">
                                        <label>{deposit.code}</label>
                                    </div>
                                    <div className="col-10 col-sm-4 col-md-4 col-lg-4">
                                        <label>{deposit.amount}</label>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-3 col-lg-2">
                                        <button type="button" className="close" onClick={e => this._addCode(deposit.code)} >
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    _closeAlert() {
        this.setState({
            showAlert: false,
            message: ""
        });
    }

    render() {
        return (
            <div id="page-content-modal-wrapper">
                <div className="panel panel-default">
                    <form onSubmit={e => this._saveDeposit(e)}>
                        <div className="panel-body">
                            <div className="container-fluid">
                                {
                                    this._renderOrderRows()
                                }
                            </div>
                        </div>
                        <div className="modal-footer mt-5">
                            <button className="btn btn-success" type="button" onClick={e => this._save()}>{i18n.t("common_button_complete")}</button>
                            <button className="btn btn-danger" type="button" onClick={e => this._cancel()}>{i18n.t("common_button_close")}</button>
                        </div>
                    </form>
                </div>
                <Alert show={this.state.showAlert}
                    title={i18n.t("common_alert")}
                    onClose={this._closeAlert}>
                    {
                        this.state.message
                    }
                </Alert>
            </div>
        );
    }
};

export default connect(mapStateToProps)(CompleteOrderRow);
