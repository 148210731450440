import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSession: (session) => dispatch({
            type: 'SET_SESSION',
            session: session
        }),
        dispatch: (e) => dispatch(e)
    };
}

// [OV] Converted to functional component from class
const Login = (props) => {
    const params = useParams();         // Replaces props
    const location = useLocation();     // Replaces history
    const navigate = useNavigate();     // Replaces push etc.
    const session = useSelector((state) => state.session);
    const dispatch = useDispatch();

    // State set to user
    const [user, setUser] = useState({
        email: "",
        password: ""
    });

    useEffect(() => {
    // Check if the base URL is different from the desired one
        if (window.location.hostname === 'woody.newseed.se') {
            // Redirect to the desired base URL
            window.location.href = process.env.REACT_APP_SERVER_ADDRESS;
        }
    }, []);

    // Handle form input changes
    const _handleChange = (event) => {
        let tempUser = user;
        tempUser[event.target.name] = event.target.value;
        setUser(tempUser);
    }

    // Attempt to sign in user
    const _signIn = (e) => {
        e.preventDefault();
        if (user.email && user.password) {
            NetworkService.login(user).then(data => {
                data.email = user.email;
                dispatch({
                    type: 'SET_SESSION',
                    session: data,
                })
                // props.setSession(data);
                document.cookie = "Session=" + data.guid + "; path=/";
                // [OV] Send user to '/'
                navigate('/', { replace: true });    // [OV] Replace: No backspace to '/login'

            }).catch(e => {
                console.log(e);
            })
        } else {
            console.log("No email and password provided");
        }
    }

    return (
        <div className="container col-md-6 mt-5 vertical-center">
            <div className="page-header text-center">
                <img className="login-logo" src={require("../../images/woody_bygghandel_blue.png")} alt="woody logo" />
            </div>

            <div className="jumbotron">
                <div className="jumbotron-content">
                    <form onSubmit={e => _signIn(e)}>
                        <div className="form-group">
                            <label name="email">{i18n.t('login_common_email')}:</label>
                            <input
                                type="email"
                                name="email"
                                value={user.username}
                                className="form-control"
                                placeholder={i18n.t('login_common_email')}
                                onChange={_handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label name="password">{i18n.t('login_common_password')}:</label>
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder={i18n.t('login_common_password')}
                                onChange={_handleChange}
                                required
                            />
                        </div>
                        <div className="form-group text-center">
                            <button className="btn btn-secondary" type="submit" color="primary">{i18n.t('login_button_signin')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
