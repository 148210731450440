import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import helper from '../../services/helper'
import { Link } from 'react-router-dom'
import moment from "moment";
import ModalEdit from '../../components/ModalEdit';
import CompleteOrderRow from './CompleteOrderRow';
import { saveAs } from 'file-saver'
import Alert from '../../components/Alert';
import EditOrderRow from './EditOrderRow';


const mapStateToProps = function (state) {    
    return {
        session: state.session,
        order: state.order
    }
}

class OrderDetails extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)          
        this.state = {     
            order: Object.assign({site:{}},this.props.order),
            orderRowModalOpen: false,
            orderRowTransferModalOpen:false,
        }
        this._closeOrder = this._closeOrder.bind(this);         
        this._saveOrder = this._saveOrder.bind(this);
        this._cancelOrderRowEdit = this._cancelOrderRowEdit.bind(this);
        this._saveOrderRow = this._saveOrderRow.bind(this);
        this._completeOrderRow = this._completeOrderRow.bind(this);
    }
    
    componentDidMount() {     
        this._isMounted = true;
        this._fetchOrder();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    _fetchOrder(){
        var that = this;     
        
        if (typeof that.props.match.params.orderid !== 'undefined'){       
            NetworkService.getOrder(that.props.match.params.orderid, that.props.session, function (order) {
				NetworkService.getSite(order.siteid,that.props.session,function(site){
                    order.site = site;
                    that.setState({order: Object.assign({}, order)});
				},function(err){
					console.log(err);
				})
            }, function (err) {
                console.log(err);
            });
        }
    }

    _editOrder(item){            
        if(item!==undefined){                                   
            this.props.push(`/order/edit/${item.orderid}`);
        }
    }

    _readyOrder(){        
        let order = Object.assign({},this.state.order);         
        order.status = "forpicking";   
        order.registrationdate = moment(order.registrationdate).format('YYYY-MM-DD');     
        let that = this;    
        NetworkService.updateOrder(order, this.props.session, function (order) {            
            that._fetchOrder();
        }, function (err) {
            console.log(err);
        });
    }

    _orderComplete(){        
        let order = Object.assign({},this.state.order);         
        order.status = "completed";   
        order.registrationdate = moment(order.registrationdate).format('YYYY-MM-DD');         
        NetworkService.updateOrder(order, this.props.session, function (order) {            
            window.location.reload();       
        }, function (err) {
            console.log(err);
        });
    }

    _renderOrderRows(hasAccess){        
        let rows = [];        
        if (this.state.order.orderrow && this.state.order.orderrow.length > 0) {
        rows =this.state.order.orderrow.map((item, i) => {                                                     
            return (
                <tr key={i}> 
                    <td>{item.article.articlenumber + " - " + item.article.articledescription}</td>    
                    <td>{item.claim.name}</td>
                    <td>{item.cubicmeter || "-"}</td>   
                    <td>{item.mixpercent || "-"}</td>
                    <td>{hasAccess&&!item.completed?<button className="pull-right btn btn-primary" onClick={e => this._editOrderRow(item)}>{i18n.t("common_button_edit")}</button>:null}</td>
                    <td>{hasAccess&&!item.completed?this._renderOrderRowOption(item):null}{item.completed ? <i className="fa fa-check-square-o" style={{fontSize:"1.5em",color:"green"}}/>:null}</td>
                </tr>
            )})}
            else{
                return <tr><td colSpan="6">{i18n.t("order_message_nodata")}</td></tr>
            }            
        return rows;
    }

    _renderOrderRowOption(item){
        if(Number(item.amount) > Number(item.availableamount)){
            return(
                <span data-toggle="tooltip" title={i18n.t("orderrow_warning")}> <i className="fa fa-exclamation-triangle" style={{color: 'orange'}}></i></span>
            );
        } else {
            return(
                this.state.order.status==="forpicking"?<button className="pull-right btn btn-success" onClick={e => item.claim.transfer?this._completeTransferOrderRow(item):this._completeOrderRow(item)}>{i18n.t("common_button_complete")}</button>:null
            )
        }
    }

    _editOrderRow(item){
        item.siteid = this.state.order.siteid;
        this.setState({selectedOrderRow: item, orderRowModalOpen:true})
    }

    _closeOrder() {       
        this.setState({ orderIsOpen: false });        
    }

    _saveOrder() {
        this.setState({ orderIsOpen: false });   
        this.props.history.go(0);          
    }

    _downloadPdf(orderid){
        var that = this;        
        NetworkService.getOrderPdf(orderid, that.props.session).then(blob => {
            saveAs(blob, 'order.pdf');
        }, function (err) {
            console.log("Error in downloading pdf",err);
        });    
    }
    
    _completeOrderRow(item){
       
            var that=this;         
            let withdrawal = {};        
            withdrawal.amount = item.amount;   
            withdrawal.claimid = item.claimid;   
            withdrawal.code = item.code || "";   
            withdrawal.orderrowid = item.orderrowid;
            withdrawal.cubicmeter = item.cubicmeter;
            withdrawal.mixpercent = item.mixpercent;
            withdrawal.articleid = item.articleid;

            NetworkService.completeOrderrow(this.state.order.orderid, withdrawal, this.props.session, function (withdrawal) {           
                if(withdrawal.msg){
                    that.setState({ showAlert: true,
                    message: withdrawal.msg }) 
                }
    
                if(withdrawal.success){                       
                   that._fetchOrder();
                   that.setState({orderRowTransferModalOpen:false, selectedOrderRow:null});
                }
                      
            }, function (err) {
                console.log(err);
            });
        
    }

    _completeTransferOrderRow(item){
        item.siteid = this.state.order.siteid;
        this.setState({orderRowTransferModalOpen:true, selectedOrderRow:item});
    }

    _cancelOrderRowEdit(){
        this.setState({orderRowModalOpen:false, selectedOrderRow:null});
    }

    _saveOrderRow(orderrow){
        NetworkService.updateOrderRow(orderrow, this.props.session).then(e=>{
            this.setState({orderRowModalOpen:false, selectedOrderRow:null});
            this._fetchOrder();
        }).catch(err=>{
            this.setState({orderRowModalOpen:false, selectedOrderRow:null});
            console.log(err);
        })
    }

    render() {          
        var incomplete = false;       
        const title = <span><Link className="link" to='/order'> {i18n.t('common_orders')}</Link> {this.state.order.ordernumber}</span>;
        var hasAccess =helper.checkAccess(this.props.session.scope, "ORDER", 1, 1)   
        var orderRowsArray = this.state.order.orderrow && this.state.order.orderrow.map((item, i) => {                                                     
            if(item.completed===1){
                return true;
            }
            return false;
        })

        if(orderRowsArray){
            incomplete = orderRowsArray.some(x => x===false)        
        }

        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="container mt-5">   
                <div className="row justify-content-center">
                    { hasAccess && this.state.order.status==="draft" ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._editOrder(this.state.order)}> <i className="fa fa-edit"></i> {i18n.t("order_button_edit")}</button>
                    </div>       
                    : null
                    }     
                    { hasAccess && this.state.order.status==="draft" ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._readyOrder(this.state.order)}> <i className="fa fa-list"></i> {i18n.t("order_move_for_picking")}</button>
                    </div>       
                    : null
                    }     
                    { hasAccess && this.state.order.status==="forpicking" && !incomplete ?
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._orderComplete(this.state.order)}> <i className="fa fa-list"></i> {i18n.t("common_button_complete")}</button>
                    </div>       
                    : null
                    } 
                    { hasAccess && this.state.order.status==="completed" ?                   
                    <div className="col-md-4 ">
                        <button className="btn btn-outline-secondary col-md-12" onClick={e => this._downloadPdf(this.state.order.orderid)}> <i className="fa fa-download"></i> {i18n.t("common_download_pdf")}</button>
                    </div>         
                    : null
                    }                       
                </div>  
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-info-circle"></i>
                                <span>  <strong>{i18n.t("common_information")}</strong></span>   
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <label><strong>{i18n.t("order_common_ordernumber")}</strong></label>
                                        <p>{this.state.order.ordernumber ? this.state.order.ordernumber: ""}</p>
                                        <label><strong>{i18n.t("order_common_invoicenumber")}</strong></label>
                                        <p>{this.state.order.ordernumber ? this.state.order.invoicenumber: ""}</p>
                                        <label><strong>{i18n.t("order_common_clientnumber")}</strong></label>
                                        <p>{this.state.order.ordernumber ? this.state.order.clientnumber: ""}</p>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <label><strong>{i18n.t("order_common_status")}</strong></label>
                                        <p>{this.state.order.status ? i18n.t("order_status_"+this.state.order.status): ""}</p>
                                        <label><strong>{i18n.t("order_common_registrationdate")}</strong></label>
                                        <p>{this.state.order.registrationdate ?  moment(this.state.order.registrationdate).format('YYYY-MM-DD') : ""}</p>
                                        <label><strong>{i18n.t("common_site")}</strong></label>
                                        <p>{this.state.order.site.name}</p>
                                    </div>
                                </div>
                            </div>                    
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="card mt-5">
                            <div className="card-header">
                                <i className="fa fa-list"></i>
                                <span>  <strong>{i18n.t("common_orderrows")}</strong></span>   
                            </div>
                            <div className="card-body"> 
                                <table className="table table-striped mt-4 table-responsive-sm">                        
                                    <thead>
                                        <tr>   
                                            <th>{i18n.t("common_article")}</th>
                                            <th>{i18n.t("common_certificate")}</th>
                                            <th>{i18n.t("deposit_common_cubicmeter")}</th>                                    
                                            <th>{i18n.t("deposit_common_mixpercent")}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                    <tbody>                   
                                        {
                                            this._renderOrderRows(hasAccess)                            
                                        }                    
                                    </tbody>                
                                </table>                   
                            </div>                    
                        </div>
                    </div>
                </div>
                <Alert show={this.state.showAlert}
                    title={i18n.t("common_alert")}
                    onClose={e=>{this.setState({showAlert:false})}}>
                    {
                        this.state.message
                    }
                </Alert>
            </div>
            <ModalEdit show={this.state.orderRowTransferModalOpen}
                    title={i18n.t("common_order")}
                    onClose={e=>{this.setState({ orderRowTransferModalOpen: false, selectedOrderRow:null })}}>                                        
                    {
                        <CompleteOrderRow onClose={e=>{this.setState({ orderRowTransferModalOpen: false, selectedOrderRow:null })}} onComplete={this._completeOrderRow} orderrow={this.state.selectedOrderRow} />
                    }
            </ModalEdit>
            <ModalEdit show={this.state.orderRowModalOpen}
                    title={i18n.t("orderrow_common_edit")}
                    onClose={this._cancelOrderRowEdit}>                                        
                    {
                        <EditOrderRow onSave={this._saveOrderRow} onCancel={this._cancelOrderRowEdit} orderrow={this.state.selectedOrderRow} />
                    }
            </ModalEdit>
        </div>
        );
    }
};

export default connect(mapStateToProps)(OrderDetails);
