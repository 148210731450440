import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        articlegroup: state.articlegroup
    }
}

class EditArticleGroup extends React.Component {  
    _isMounted = false; 
    constructor(props) {
        super(props)       
        if (this.props.match && typeof this.props.match.params.articlegroupid == 'undefined'){
            this.state = {     
                articlegroup:{                                 
                    name:"",
                    description:"",
                    typeofwood:""                 
                }
            }      
        }else{
            this.state = {     
                articlegroup: Object.assign({},this.props.articlegroup)
            } 
        }     
    }

    componentDidMount() {     
        this._isMounted = true;         
        var that = this; 
        if (that.props.match && typeof that.props.match.params.articlegroupid !== 'undefined'){       
            NetworkService.getArticleGroup(that.props.match.params.articlegroupid, that.props.session, function (articlegroup) {             
                if (that._isMounted) {                                     
                    that.setState({articlegroup: Object.assign({}, articlegroup)})            
                }
            }, function (err) {
                console.log(err);
            });
        }   

        NetworkService.getSimpleCertificatesList(that.props.session, function (certificate) {    
            if (that._isMounted) {               
                that.setState({certificates:certificate})            
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }  

    _saveAricleGroup(e){  
        e.preventDefault();                  
        var that =this;      
        let articlegroup = Object.assign({},that.state.articlegroup);         
        if(articlegroup.articlegroupid!=null || articlegroup.articlegroupid!==undefined){
            NetworkService.updateArticleGroup(articlegroup, that.props.session, function (articlegroup) {            
                that.props.push('/articlegroup');                       
            }, function (err) {
                console.log(err);
            });

        }else{
            NetworkService.createArticleGroup(articlegroup, that.props.session, function (articlegroup) {              
                that.props.push('/articlegroup');                       
            }, function (err) {
                console.log(err);
            });
        }
    } 

    _cancel(){             
        if(this.state.articlegroup.articlegroupid){
            this.props.push('/articlegroup/detail/'+this.state.articlegroup.articlegroupid);
        }else {
            this.props.push('/articlegroup');
        }
    } 

    _handleChange(type, val){
        let articlegroup = Object.assign({},this.state.articlegroup);             
        articlegroup[type] = val;    
        this.setState({
            articlegroup: articlegroup           
        });     
    }

    createCertificateItems() {        
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_certificate")}</option>);
        if(this.state.certificates && this.state.certificates.length>0){
            for (let i = 0; i < this.state.certificates.length; i++) {
                items.push(<option key={i} value={this.state.certificates[i].certificateid}>{this.state.certificates[i].name}</option>);
            }
        }

        return items;
    }

    render() {  
        const title = i18n.t("articlegroup_title_edit");         
        return (
            <div id="page-content-wrapper">        
            <Navbar title={title}/> 
            <div className="panel panel-default">        
                <form onSubmit={e => this._saveAricleGroup(e)}>                            
                    <div className="panel-body">
                        <fieldset className="col-md-6 mt-5">                                
                            <div className="form-group">
                                <label>{i18n.t("articlegroup_common_name")}</label>
                                <input className="form-control" type="text" value={this.state.articlegroup.name || ""} onChange={e => this._handleChange("name", e.target.value)} required/>
                            </div>                           
                            <div className="form-group">
                                <label>{i18n.t("articlegroup_common_description")}</label>
                                <textarea className="form-control" type="text" value={this.state.articlegroup.description || ""} onChange={e => this._handleChange("description", e.target.value)} rows="4"/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("articlegroup_common_typeofwood")}</label>
                                <input className="form-control" type="text" value={this.state.articlegroup.typeofwood || ""} onChange={e => this._handleChange("typeofwood", e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("deposit_common_certificate")}</label>                               
                                 <select name="select" className="form-control" value={this.state.articlegroup.certificateid || ""} onChange={e => this._handleChange("certificateid", e.target.value)} required>
                                    {this.createCertificateItems()}
                                </select>                               
                            </div>  
                        </fieldset>            
                    </div>		
                    <div className="modal-footer mt-5">
                        <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                        <button className="btn btn-warning" type="button"  onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                    </div>
                </form>                                
            </div>
        </div>
        );
    }
};

export default connect(mapStateToProps)(EditArticleGroup);
