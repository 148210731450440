import React from 'react';
import Navbar from '../../components/Navbar';
import { connect } from 'react-redux';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';
import { Link, useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";

import '../../App.css';
import TableHeader from '../TableHeader';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setSession: (session) => dispatch({
//             type: 'SET_SESSION',
//             session: session
//         }),
//         dispatch: (e) => dispatch(e)
//     };
// }

// Save all imported orders to DB
const _saveOrders = (e, state, props) => {
    e.preventDefault();

    // Confirm button clicked:
    // 1. Create DB order-orderrow structures from workbook sheet structures
    // 2. Save each one of them to database (with _saveOrder)

    // In workbook sheet many rows may have the same ordernumber
    // 1.1 Create list of unique orders to create
    const ordersToCreate = [];
    state.orders.forEach((order) => {
        var unique = true;
        order.orderrow = [];        // [OV] DB 'order.orderrow' is (actually) an array of order rows
        ordersToCreate.forEach((orderToCreate) => {
            if(orderToCreate.ordernumber === order.ordernumber) unique = false;
        });
        if(unique) ordersToCreate.push(order);
    });

    // 1.2 Push order row into the correct order array of orderrows
    state.orderRows.forEach((orderRow, i) => {
        var ordernumber = state.orders[i].ordernumber;
        ordersToCreate.forEach(orderToCreate => {
            if(orderToCreate.ordernumber === ordernumber) orderToCreate.orderrow.push(orderRow);
        });
    });

    // 2.1 For each order in the list of unique orders - save it to database
    ordersToCreate.forEach(order => {
        _saveOrder(order, props.session);
    });

    // 2.2 Redirect to orders page (sorted with the new orders on top)
    props.push('/order');
}

const _saveOrder = (orderToSave, session) => {
    let order = Object.assign({},orderToSave);
    NetworkService.createOrder(order, session, function (order) {
    }, function (err) {
        console.log(err);
    });
}

// Future prospect: Click a row to see details of the order
// const _orderDetails = (order) => {
//     // TODO
//     console.log("_orderDetails: ", order);
//     // const navigate = useNavigate();
//     Navigate(`/order/detail/${order.orderid}`);
// }

// Main import confirm table header
const Headers = () => {
    let headers = [
        { header: i18n.t("order_common_ordernumber"), column: 'ordernumber', order: false },
        { header: i18n.t("order_common_invoicenumber"), column: 'invoicenumber', order: false },
        { header: i18n.t("order_common_clientnumber"), column: 'site', order: false },
        { header: i18n.t("order_common_site"), order: false },
        { header: i18n.t("common_search_articlenumber"), column: 'ordernumber', order: false },
        { header: i18n.t("deposit_common_cubicmeter"), column: 'invoicenumber', order: false },
        { header: i18n.t("deposit_select_article"), column: 'status', order: false },
        { header: i18n.t("deposit_common_mixpercent"), order: false },
        { header: i18n.t("order_common_claim"), order: false },
        { header: i18n.t("order_common_registrationdate"), column: 'registrationdate', order: false },
        { header: i18n.t("order_common_status"), column: 'status', order: false },
    ];

    return headers.map((r, i) => {
        return (
            <TableHeader 
                key={i} headerdata={r} 
                location={"x"} 
            />
        );
    });
}

// Main import confirm table row
const Row = (props) => {
    let orderRow = props.orderRow;      // Order row data
    let order = props.order;            // Order data (one order can have many rows)
    let response = props.response;      // Backend response on imported file, to be confirmed

    return(
        <tr className={"reveal"} // tr-clickable
            // onClick={()=>navigate(`/order/detail/${order.ordernumber}`)}
        >
            <td>{order.ordernumber}
                {(!response.ok && response.reasons.includes("Existing order")
                    ?(<div className="reason">{i18n.t("order_ordernumber_taken")}</div>)
                    :"")}
            </td>
            <td>{order.invoicenumber}</td>
            <td>{order.clientnumber}
            {(!response.ok && response.reasons.includes("Missing: ORDER.clientnumber")
                    ?(<div className="reason">{i18n.t("clientnumber_message_nodata")}</div>)
                    :"")}</td>
            <td>{order.code}
                {(!response.ok && response.reasons.includes("No such site")
                    ?(<div className="reason">{i18n.t("site_message_nodata")}</div>)
                    :"")}
            </td>
            <td>
                {orderRow.articlenumber}
                {(!response.ok && response.reasons.includes("No such article")
                    ?(<div className="reason">{i18n.t("article_message_nodata")}</div>)
                    :"")}
            </td>
            <td>
                {orderRow.cubicmeter}
                {(!response.ok && (response.reasons.includes("Amount not available") || response.reasons.includes("Amount N/A"))
                    ?(<div className="reason">{i18n.t("orderrow_warning")}</div>)
                    :"")}
            </td>
            <td>{orderRow.numberofarticle}</td>
            <td>{orderRow.mixpercent}
                {(!response.ok && response.reasons.includes("Mix percent wrong")
                    ?(<div className="reason">{i18n.t("mix_percent_limit")}</div>)
                    :"")}
            </td>
            <td>
                {orderRow.claimcode}
                {(!response.ok && response.reasons.includes("No such claim")
                    ?(<div className="reason">{i18n.t("claim_message_nodata")}</div>)
                    :"")}
            </td>
            <td>{moment(order.registrationdate).format('YYYY-MM-DD')}</td>
            <td>{response.ok
                    ?<i className="fa fa-check" style={{fontSize:"1.5em",color:"green"}}/>
                    :<i className="fa fa-times" style={{fontSize:"1.5em",color:"red"}}/>
                } 
                {(!response.ok && response.reasons.includes("Accumulated amount reached limit")
                    ?(<div className="reason">{i18n.t("orderrow_acc_warning")}</div>)
                    :"")
                }
            </td>
        </tr>);
}

const ImportOrderFile = (props) => {
    const {state} = useLocation();
    return (
    <div id="page-content-wrapper">
        <Navbar title={i18n.t("import_confirmation")} />
        <div className="container mt-5 mb-5">
            {/* <div className="row justify-content-center">
                <div className="col-md-6 ">
                    <h1>{i18n.t("import_confirmation")}</h1>
                    <ul>
                        <li>{i18n.t("deliveries_import_confirmed")}&nbsp;&nbsp;&nbsp; <i className="fa fa-check" style={{fontSize:"1.5em",color:"green"}}/></li>
                        <li>{i18n.t("articles_import_confirmed")}&nbsp;&nbsp;&nbsp; <i className="fa fa-check" style={{fontSize:"1.5em",color:"green"}}/></li>
                    </ul>
                    <p><Link to='/importFile'>{i18n.t("import_log_link")}</Link></p>
                </div>
            </div> */}
            <h1>{i18n.t("confirm_import_orders")}</h1>
            <p>{i18n.t("import_orders_explanation")}&nbsp;&nbsp;&nbsp; <Link to='/importFile'>{i18n.t("import_log_link")}</Link></p>
            {state.responses?.every((response)=>response.ok)
                ?   <p>
                        {i18n.t("import_orders_successful")} 
                        <i className="fa fa-check ml-3" style={{fontSize:"1.5em",color:"green"}}/>
                        <button onClick={(e) => props.push('/order')} className='btn btn-info ml-4'>{i18n.t("common_button_ok")}</button>
                    </p>
                :   <div>
                        <p>
                            {i18n.t("import_orders_failure")} 
                            <i className="fa fa-times ml-3" style={{fontSize:"1.5em",color:"red"}}/>
                            
                        </p>
                        <p>
                            {i18n.t("import_orders_errors")} 
                            <button onClick={(e) => props.push('/order')} className='btn btn-info ml-4'>{i18n.t("common_button_ok")}</button>
                        </p>
                    </div>
            }
            
            <table className="text-center table table-striped w-100">
                <thead>
                    <tr>
                        <Headers />
                    </tr>
                </thead>
                <tbody>
                    {state.orderRows?.map((item, i)=>{return(<Row orderRow={item} order={state.orders[i]} response={state.responses[i]} key={i} />)})}
                </tbody>
            </table>
            <button onClick={(e) => props.push('/order')} className='btn btn-info m-1'>{i18n.t("common_button_ok")}</button>
        </div>
    </div>
    );
}

export default connect(mapStateToProps)(ImportOrderFile);