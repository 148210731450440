import React from "react";
import { connect } from "react-redux";
import i18n from "../../services/i18n";

import { Collapse } from "reactstrap";
import NetworkService from "../../services/NetworkService";

const mapStateToProps = function (state) {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

class AdvancedSearchArticle extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedSearch: false,
      claims: [],
      partners: [],
    };

    this._toggleCollapse = this._toggleCollapse.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchClaims();
    // If the user is admin, fetch all partners
    if(this.props.session.scope.role === "Administrator")
      this._fetchPartners();
  }

  _toggleCollapse() {
    this.setState({ showAdvancedSearch: !this.state.showAdvancedSearch });
  }

  _fetchClaims() {
    var that = this;

    NetworkService.getSimpleClaimsList(
      that.props.session,
      function (claim) {
        if (that._isMounted) {
          that.setState({ claims: claim });
        }
      },
      function (err) {
        console.log(err);
      }
    );
  }

  _fetchPartners() {
    var that = this;
    
    NetworkService.getSimplePartnersList(
      that.props.session,
      function (partner) {
        if (that._isMounted) {
          that.setState({ partners: partner });
        }
      },
      function (err) {
        console.log(err);
      }
    );
  }

  _createClaimItems() {
    let items = [];
    items.push(
      <option key="clmlistinit" value="">
        {i18n.t("common_select_claim")}
      </option>
    );
    if (this.state.claims && this.state.claims.length > 0) {
      for (let i = 0; i < this.state.claims.length; i++) {
        items.push(
          <option key={i} value={this.state.claims[i].name}>
            {this.state.claims[i].name}
          </option>
        );
      }
    }
    return items;
  }

  _createPartnerItems() {
    let items = [];
    items.push(
      <option key="partnerlistinit" value="">
        {i18n.t("common_select_partner")}
      </option>
    );
    if (this.state.partners && this.state.partners.length > 0) {
      for (let i = 0; i < this.state.partners.length; i++) {
        items.push(
          <option key={i} value={this.state.partners[i].partnerid}>
            {this.state.partners[i].description}
          </option>
        );
      }
    }
    return items;
  }

  _renderSearchInput() {
    return (
      <div className="row">
        <div className="col-md-4">
          <Collapse isOpen={this.state.showAdvancedSearch}>
            <div className="form-group" style={{ marginTop: "1em" }}>
              <label>{i18n.t("common_supplier")}</label>
              <input
                type="text"
                id="order-search-supplier"
                value={this.props.search.supplier}
                onChange={this.props._searchChange}
                onKeyDown={this.props._searchKeyDown}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label>{i18n.t("common_search_claimname")}</label>
              <select
                name="select"
                id="order-search-claimname"
                className="form-control"
                value={this.props.search.claimName}
                onChange={this.props._searchChange}
              >
                {this._createClaimItems()}
              </select>
            </div>
            {/* If the user is admin, show the partner search input */}
            {this.props.session.scope.role === "Administrator" && (
              <div className="form-group">
                <label>{i18n.t("common_select_partner")}</label>
                <select
                  name="select"
                  id="article-search-partner"
                  className="form-control"
                  value={this.props.search.partnerid}
                  onChange={this.props._searchChange}
                >
                  {this._createPartnerItems()}
                </select>
              </div>
            )}
          </Collapse>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <input
              type="text"
              id="order-search-freetext"
              className="form-control"
              value={this.props.search.freeText}
              onChange={this.props._searchChange}
              onKeyDown={this.props._searchKeyDown}
              placeholder={i18n.t("common_search_freetext")}
            />
          </div>

          <div className="col-md-2">
            <button
              className="btn btn-outline-secondary"
              onClick={this.props._searchSubmit}
              type="submit"
            >
              {i18n.t("common_search")}
            </button>
          </div>

          <div className="col-md-5">
            <button className="btn btn-link" onClick={this._toggleCollapse}>
              {i18n.t("common_search_advanced")}
            </button>
          </div>
        </div>
        {this._renderSearchInput()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearchArticle);
