import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../../components/Navbar';
import i18n from '../../services/i18n';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut, Bar } from 'react-chartjs-2';
import NetworkService from '../../services/NetworkService';
import ModalEdit from '../ModalEdit';
import EditDelivery from '../delivery/EditDelivery';
import helper from '../../services/helper'
import moment from "moment";

// Register chart elements
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

const mapStateToProps = function (state) {
	return {
		session: state.session
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSession: (session) => dispatch({
			type: 'SET_SESSION',
			session: session
		}),
		dispatch: (e) => dispatch(e)
	};
}

class DashBoard extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			stockData: { datasets:[]},
			siteData: { datasets:[]},
			deliveryIsOpen: false,
		}
		this._buildStockData = this._buildStockData.bind(this);

	}

	componentDidMount() {
		this._isMounted = true;

		let that = this;
		let promises = [];
		promises.push(NetworkService.getReport("stock", this.props.session));
		promises.push(NetworkService.getReport("site", this.props.session));
		promises.push(NetworkService.getReport("supplier", this.props.session));

		Promise.all(promises).then((data) => {
			that._buildStockData(data[0]);
			that._buildSiteData(data[1]);
			that._buildSupplierData(data[2]);
		}).catch((err) => {
			console.log(err);
		})
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_buildStockData(data) {

		let stockData = { labels: [], datasets: [] };
		stockData.datasets.push({
			label: 'Stock',
			backgroundColor: 'rgba(99,99,255,0.2)',
			borderColor: 'rgba(99,99,255,1)',
			borderWidth: 1,
			hoverBackgroundColor: 'rgba(99,99,255,0.4)',
			hoverBorderColor: 'rgba(99,99,255,1)',
			data: [],
		});

		for (var i = 0; i < data.length; i++) {
			stockData.labels.push(data[i].label);
			stockData.datasets[0].label = data[i].label;
			stockData.datasets[0].data.push(data[i].value);
		}

		if (this._isMounted) {
			this.setState({
				stockData: stockData
			})
		}
	}

	_buildSiteData(data) {
		let siteData = { labels: [], datasets: [] };
		siteData.datasets.push({
			data: [],
			backgroundColor: [],
			hoverBackgroundColor: [],
		});

		for (var i = 0; i < data.length; i++) {
			let color = this._getRandomColor();
			siteData.labels.push(data[i].label || i18n.t("common_other"));
			siteData.datasets[0].data.push(data[i].value);
			siteData.datasets[0].backgroundColor.push(color);
			siteData.datasets[0].hoverBackgroundColor.push(color);
		}

		if (this._isMounted) {
			this.setState({
				siteData: siteData
			})
		}
	}

	_buildSupplierData(data) {
		if (this._isMounted) {
			this.setState({
				supplierData: data
			})
		}
	}

	//https://stackoverflow.com/questions/1484506/random-color-generator
	_getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	_goToSupplier(id) {
		this.props.push('/supplier/detail/' + id);
	}

	_renderSupplierRows() {
		let rows = [];
		var hasAccess = helper.checkAccess(this.props.session.scope, "SUPPLIER", 1, 1)
		if (this.state.supplierData && this.state.supplierData.length > 0) {
			rows = this.state.supplierData.map((item, i) => {
				return (
					<tr key={i} className={hasAccess ? "tr-clickable" : ""} onClick={e => hasAccess ? this._goToSupplier(item.supplierid) : ""}>
						<td>{item.name}</td>
						<td>{moment(item.expirydate).format('YYYY-MM-DD')}</td>
					</tr>
				)
			})
		} else {
			return <tr><td colSpan="2">{i18n.t("supplier_message_nodata")}</td></tr>
		}
		return rows;
	}

	render() {
		const title = i18n.t("common_dashboard");
		var hasDeliveryAccess = helper.checkAccess(this.props.session.scope, "DELIVERY", 1, 1);
		var hasOrderAccess = helper.checkAccess(this.props.session.scope, "ORDER", 1, 1);
		var hasPartner = this.props.session.scope.partner
		var isAdmin = this.props.session.scope.role === "Administrator"
		return (
			<div id="page-content-wrapper">
				<Navbar title={title} />
				<div className="container">
					{hasPartner || isAdmin?
					<div className="row dashboard-graphs">
						<fieldset className="col-md-12">
							<legend className="w-auto">{i18n.t("dashboard_quick_options")}</legend>
							<div className="row">
								{hasDeliveryAccess ?
									<div className="col-md-4 ">
										<button 
											className="btn btn-outline-secondary col-md-12 toolbar-buttons" 
											onClick={e => { this.setState({ deliveryIsOpen: true }) }}> 
											<i className="fa fa-plus"></i> 
											{" " + i18n.t("delivery_button_add")}
										</button>
									</div> : null
								}
								{hasOrderAccess ?
									<div className="col-md-4 ">
										<button 
											className="btn btn-outline-secondary col-md-12 toolbar-buttons" 
											onClick={e => this.props.push('/order/add')}> 
											<i className="fa fa-plus"></i> 
											{" " + i18n.t("order_button_add")}
										</button>
									</div>
									: null
								}
								{!hasDeliveryAccess && !hasOrderAccess ? <div className="col-md-4 "><p>{i18n.t("dashboard_no_quick_options")}</p></div> : null}
							</div>
						</fieldset>
					</div>
					:<span style={{position: "absolute", top: "200px"}}>
						<h1>Welcome to Woody!</h1>
					</span>
					}
					{hasPartner || isAdmin?
					<div className="row dashboard-graphs">
						<fieldset className="col-md-6">
							<legend className="w-auto">{i18n.t("graph_title_site")}</legend>
							<Doughnut data={this.state.siteData} />
						</fieldset>
						<fieldset className="col-md-6">
							<legend className="w-auto">{i18n.t("graph_title_stock")}</legend>
							<Bar data={this.state.stockData}  />
						</fieldset>
					</div>:""}
					{hasPartner || isAdmin?
					<div className="col-md-12 mt-5 mb-5">
						<legend className="w-auto">{i18n.t("supplier_common_expirydate")}</legend>
						<table className="table table-striped mt-4 table-responsive-sm">
							<thead>
								<tr>
									<th>{i18n.t("supplier_common_name")}</th>
									<th>{i18n.t("supplierverification_common_expirydate")}</th>
								</tr>
							</thead>
							<tbody>
								{
									this._renderSupplierRows()
								}
							</tbody>
						</table>
					</div>:""}
				</div>
				<ModalEdit show={this.state.deliveryIsOpen}
					title={i18n.t("common_delivery")}
					onClose={() => { this.setState({ deliveryIsOpen: false }); }}>
					{
						<EditDelivery that={this} onClose={() => { this.setState({ deliveryIsOpen: false }); }} onSave={() => { this.setState({ deliveryIsOpen: false }); this.props.push('/delivery'); }} />
					}
				</ModalEdit>
			</div>
		);
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
