import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import Navbar from '../Navbar';
import NetworkService from '../../services/NetworkService';
import i18n from '../../services/i18n';

const mapStateToProps = function (state) {
    return {
        session: state.session,
        article: state.article
    }
}

class EditArticle extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        console.log("EditArticle constructor: ", this.props)
        if(this.props.article?.articleid) {
            this.state = {
                article: Object.assign({}, this.props.article)
            }
        } else {
            this.state = {
                article: {
                    articlegroupid: "",
                    articlegroups: [],
                    category: "",
                    unitid: "",
                    articlenumber: "",
                    articledescription: "",
                    width: "",
                    height: "",
                    depth: "",
                    partnerid:
                        this.props.session &&
                            this.props.session.scope &&
                            this.props.session.scope.partner
                            ? this.props.session.scope.partner
                            : ""
                }
            }
        }
    }

    

    componentDidMount() {
        this._isMounted = true;
        var that = this;
        if (
             (that.props.match && typeof that.props.match.params.articleid !== 'undefined') // Always load data from DB (not props) when editing
             //!this.props.article?.articleid && (that.props.match && typeof that.props.match.params.articleid !== 'undefined')
            ) {
            NetworkService.getArticle(that.props.match.params.articleid, that.props.session, function (article) {
                if (that._isMounted) {
                    console.log("componentDidMount article: ", article)
                    that.setState({ article: Object.assign({}, article) })
                }
            }, function (err) {
                console.log(err);
            });
        }

        NetworkService.getCertificates(that.props.session, (certificates) => {
            if (that._isMounted) {
                that.setState({ certificates: certificates })
            }
        }, (err) => {
            console.log(err);
        })

        NetworkService.getArticleGroups(that.props.session, function (articlegroups) {
            if (that._isMounted) {
                // console.log("getArticleGroups: ", articlegroups)
                that.setState({ articlegroups: articlegroups })
            }
        }, function (err) {
            console.log(err);
        });

        NetworkService.getSimplePartnersList(that.props.session, function (partner) {
            if (that._isMounted) {
                that.setState({ partners: partner })
            }
        }, function (err) {
            console.log(err);
        }
        );

        NetworkService.getSimpleUnitsList(that.props.session, function (unit) {
            if (that._isMounted) {
                that.setState({ units: unit })
            }
        }, function (err) {
            console.log(err);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    _saveAricle(e) {
        e.preventDefault();
        var that = this;
        let article = Object.assign({}, this.state.article);
        console.log("saveArticle: ", article)
        if (article.articleid != null || article.articleid !== undefined) {
            NetworkService.updateArticle(article, that.props.session, function (article) {
                that.props.push('/article');
            }, function (err) {
                console.log(err);
            });

        } else {
            NetworkService.createArticle(article, that.props.session, function (article) {
                that.props.push('/article');
            }, function (err) {
                console.log(err);
            });
        }
    }

    _cancel() {
        if (this.state.article.articleid) {
            this.props.push('/article/detail/' + this.state.article.articleid);
        } else {
            this.props.push('/article');
        }
    }

    _handleChange(type, val) {
        console.log("handleChange: ", type, val);
        let article = Object.assign({}, this.state.article);
        article[type] = val;
        this.setState({
            article: article
        });
    }

    _createArticleGroupItems(certificate) {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_articlegroup")}</option>);
        if (this.state.articlegroups && this.state.articlegroups.length > 0) {
            for (let i = 0; i < this.state.articlegroups.length; i++) {
                if (this.state.articlegroups[i].certificateid === certificate.certificateid)
                    items.push(<option key={i} value={this.state.articlegroups[i].articlegroupid}>[{certificate.name}]&nbsp;{this.state.articlegroups[i].name}</option>);
            }
        }

        return items;
    }

    _createPartnerItems() {
        var isAdmin = this.props.session.scope.role === "Administrator";
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_partner")}</option>);
        if (this.state.partners && this.state.partners.length > 0) {
            for (let i = 0; i < this.state.partners.length; i++) {
                // Non admins can only use their own partner id
                if(isAdmin || (this.state.partners[i].partnerid === this.state.article.partnerid))
                    items.push(<option key={i} value={this.state.partners[i].partnerid}>{this.state.partners[i].description}</option>);
            }
        }
        return items;
    }

    _createUnitItems() {
        let items = [];
        items.push(<option key="userlistinit" value="">{i18n.t("common_select_unit")}</option>);
        if (this.state.units && this.state.units.length > 0) {
            for (let i = 0; i < this.state.units.length; i++) {
                items.push(<option key={i} value={this.state.units[i].unitid}>{this.state.units[i].unit}</option>);
            }
        }
        return items;
    }

    render() {
        console.log("Edit Article Render - this.state.article: ", this.state.article);
        const title = i18n.t("article_title_edit");
        return (
            <div id="page-content-wrapper">
                <Navbar title={title} />
                <div className="panel panel-default">
                    <form onSubmit={e => this._saveAricle(e)}>
                        <div className="panel-body">
                            <fieldset className="col-md-6 mt-5">
                                <div className="form-group">
                                    <label>{i18n.t("article_common_number")}</label>
                                    <input className="form-control" type="text" value={this.state.article.articlenumber || ""} onChange={e => this._handleChange("articlenumber", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_description")}</label>
                                    <textarea className="form-control" type="text" value={this.state.article.articledescription || ""} onChange={e => this._handleChange("articledescription", e.target.value)} rows="4" />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_category")}</label>
                                    <input className="form-control" type="text" value={this.state.article.category || ""} onChange={e => this._handleChange("category", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_unit")}</label>
                                    <select
                                        name="select"
                                        className="form-control"
                                        value={this.state.article.unitid || ""}
                                        onChange={
                                            e => this._handleChange("unitid", e.target.value)
                                        }>
                                        {this._createUnitItems()}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_height")}</label>
                                    <input className="form-control" type="text" value={this.state.article.height || ""} onChange={e => this._handleChange("height", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_width")}</label>
                                    <input className="form-control" type="text" value={this.state.article.width || ""} onChange={e => this._handleChange("width", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("article_common_depth")}</label>
                                    <input className="form-control" type="text" value={this.state.article.depth || ""} onChange={e => this._handleChange("depth", e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col-2'>{i18n.t("common_certificate")}</div>
                                        <div className='col-10'>
                                            {i18n.t("article_common_articlegroup")}
                                        </div>
                                    </div>

                                    {this.state.certificates?.map((certificate) => {
                                        // For each certificate (currently only FSC or PEFC)
                                        // Copy this articles list of articlegroups
                                        console.log("View state article article groups: ", this.state.article.articlegroups)
                                        let articlegroups = [...this.state.article.articlegroups];
                                        articlegroups?.map((matchingGroup) => {
                                            // Match the certificate of the article group
                                            if(certificate.certificateid === matchingGroup.certificateid) {
                                                certificate.matchingGroup = matchingGroup
                                            }
                                        })
                                        return (<div className='row' key={certificate.certificateid}>
                                            {/* First on line is the certificate name */}
                                            <div className='col-2'>{certificate.name}</div>
                                            <div className='col-10'>
                                                <select
                                                    style={{ display: "inline" }}
                                                    // required
                                                    name="select"
                                                    className="form-control"
                                                    value={certificate.matchingGroup?.articlegroupid /* Current article group */}
                                                    onChange={e => {
                                                        let newGroupListing = [];
                                                        // Push all other article groups than this from the current list to the new
                                                        articlegroups?.map((gr) => {
                                                            if(gr.certificateid !== certificate.certificateid) {
                                                                newGroupListing.push(gr)
                                                            }
                                                        })
                                                        // Match the selected group to the total list of groups
                                                        this.state.articlegroups?.map((groupOfAllGroups) => {
                                                            if(groupOfAllGroups.articlegroupid == e.target.value)
                                                                // Push the selected group to the new article group list
                                                                newGroupListing.push(groupOfAllGroups)
                                                        })
                                                        // Use the standard handle change method
                                                        this._handleChange("articlegroups", newGroupListing)
                                                    }}
                                                >
                                                    {this._createArticleGroupItems(certificate)}
                                                </select>
                                            </div>
                                        </div>
                                    )})}

                                </div>
                                {/* Only selectable if Admin */}
                                    <div className="form-group">
                                        <label>{i18n.t("common_partner")}</label>
                                        <select name="select" className="form-control" value={this.state.article.partnerid || ""} onChange={e => this._handleChange("partnerid", e.target.value)} >
                                            {this._createPartnerItems()}
                                        </select>
                                    </div>
                                
                            </fieldset>
                        </div>
                        <div className="modal-footer mt-5">
                            <button className="btn btn-primary" type="submit">{i18n.t("common_button_ok")}</button>
                            <button className="btn btn-warning" type="button" onClick={e => this._cancel()}>{i18n.t("common_button_cancel")}</button>
                        </div>
                    </form>
                </div >
            </div >
        );
    }
};

export default connect(mapStateToProps)(EditArticle);
